import { createSlice, current } from "@reduxjs/toolkit";
import {
  IFamily,
  IField,
  IQueryFilter,
  IQueryTextSearch,
  ITypeChoiceFilterFields,
  IWorker,
} from "../../types/filterFields";
import {
  editSelected,
  getFamilies,
  getWorkers,
  searchFamilies,
  searchWorkers,
} from "../thunks/databaseTable";
import { isJson } from "../../utils/jsonUtils";

export interface IState {
  count: number;
  currentPage: number;
  availableFilters: ITypeChoiceFilterFields | {};
  selectedFilters: ITypeChoiceFilterFields | {};
  modalOpened: boolean;
  data: (IWorker | IFamily)[];
  filteredData: (IWorker | IFamily)[];
  fieldKeys: { [key: string]: IField };
  isEditorOpened: boolean;
  selected?: IWorker | IFamily | undefined;
  queryFilters?: (IQueryFilter | IQueryTextSearch)[];
  isLoading: boolean;
  isSearching: boolean;
  isLoadingEditing: boolean;
}

const initialState: IState = {
  isLoading: false,
  count: 0,
  currentPage: 1,
  fieldKeys: {},
  availableFilters: {},
  selectedFilters: {},
  modalOpened: false,
  data: [],
  filteredData: [],
  isEditorOpened: false,
  selected: undefined,
  queryFilters: [],
  isSearching: false,
  isLoadingEditing: false,
};

const databaseTableSlice = createSlice({
  name: "databaseTable",
  initialState,
  reducers: {
    setFieldKeys: (state, action) => {
      state.fieldKeys = action.payload;
    },
    setAvailableFilters: (state, action) => {
      state.availableFilters = action.payload;
    },
    addFilter: (state, action) => {
      const filter = action.payload;
      (state.selectedFilters as ITypeChoiceFilterFields)[
        filter.key.replace(".", "_")
      ] = filter;
    },
    removeFilter: (state, action) => {
      const filter = action.payload;
      delete (state.selectedFilters as ITypeChoiceFilterFields)[
        filter.key.replace(".", "_")
      ];
    },
    setModalOpened: (state, action) => {
      state.modalOpened = action.payload;
    },
    resetFilters: (state) => {
      state.selectedFilters = {};
      state.filteredData = state.data;
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.filteredData = action.payload;
    },
    setFilter: (state, action) => {
      const { filter, value } = action.payload;
      (state.selectedFilters as ITypeChoiceFilterFields)[
        filter.key as keyof ITypeChoiceFilterFields
      ].filterValue = value;
    },
    filterData: (state) => {
      const selectedFilters = state.selectedFilters as ITypeChoiceFilterFields;

      state.filteredData = state.data.filter((item) => {
        for (let key in selectedFilters) {
          if ((item as any)[key] !== selectedFilters[key].filterValue) {
            return false;
          }
        }
        return true;
      });
    },
    setIsEditorOpened: (state, action) => {
      state.isEditorOpened = action.payload.opened;
      state.selected = action.payload.object;
    },
    addQueryFilter: (state, action) => {
      const filter = action.payload;
      const filterKey = Object.keys(filter)[0] as keyof typeof filter;
      const existingFilterIndex = state.queryFilters?.findIndex(
        (f) => Object.keys(f as IQueryFilter)[0] === filterKey
      );
      if (
        existingFilterIndex !== -1 &&
        state.queryFilters &&
        typeof existingFilterIndex !== "undefined"
      ) {
        (state.queryFilters[existingFilterIndex] as any)[filterKey] =
          filter[filterKey];
      } else {
        state.queryFilters?.push(filter);
      }
    },
    removeQueryFilter: (state, action) => {
      const filter = action.payload;
      const filterKey = Object.keys(filter)[0];
      state.queryFilters = state.queryFilters?.filter(
        (item) => Object.keys(item as IQueryFilter)[0] !== filterKey
      );
    },
    resetQueryFilters: (state) => {
      state.isSearching = false;
      state.queryFilters = [];
      state.filteredData = state.data;
    },
    addTextSearch: (state, action) => {
      const i = state.queryFilters?.findIndex(
        (filter) => "text_search" in filter
      );
      if (typeof i !== "undefined" && i !== -1 && state.queryFilters) {
        state.queryFilters[i] = { text_search: action.payload };
      } else {
        state.queryFilters?.push({ text_search: action.payload });
      }
    },
    editSelectedValues: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;

      function setNestedObjectValue(obj: any, path: string, value: any) {
        const pathParts = path.split(".");
        let current = obj;
        for (let i = 0; i < pathParts.length - 1; i++) {
          if (!current[pathParts[i]]) {
            current[pathParts[i]] = {};
          }
          current = current[pathParts[i]];
        }
        current[pathParts[pathParts.length - 1]] = value;
      }

      if (state.selected) {
        setNestedObjectValue(state.selected, key, value);
      }
    },
    setPage: (state, action) => {
      if (action.payload === "left") {
        state.currentPage -= 1;
      } else if (action.payload === "right") {
        state.currentPage += 1;
      }
    },
    setPageTo: (state, action) => {
      state.currentPage = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoadingEditing: (state, action) => {
      state.isLoadingEditing = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(searchFamilies.fulfilled, (state, action) => {
      state.filteredData = action.payload;
      state.isLoading = false;
      state.isSearching = true;
    });
    builder.addCase(searchFamilies.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(searchWorkers.fulfilled, (state, action) => {
      state.count = action.payload.count;
      state.isLoading = false;

      const parsedRows = action.payload.rows.map(
        (row: { [key: string]: any }) => {
          const parsedObject: IWorker = {} as IWorker;

          Object.keys(row).forEach((key) => {
            if (key === "experiences") {
              parsedObject.experiences = {
                count: row[key].count,
                experiences: row[key].experiences.map((value: any) => {
                  return JSON.parse(value);
                }),
              };
            } else {
              parsedObject[key] = row[key];
            }
          });
          return parsedObject;
        }
      ) as IWorker[];

      state.data = parsedRows;
      state.filteredData = parsedRows;
    });
    builder.addCase(searchWorkers.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editSelected.fulfilled, (state, action) => {
      state.isLoadingEditing = true;

      const i = state.data.findIndex(
        (item) =>
          (item as IWorker).codice_fiscale ===
          (action.payload as any).updatedData.codice_fiscale
      );
      // state.filteredData[i] = (action.payload as any).updatedData;
      // state.data[i] = (action.payload as any).updatedData;

      state.filteredData[i] = JSON.parse(JSON.stringify(state.selected));
      state.data[i] = JSON.parse(JSON.stringify(state.selected));
      state.isLoading = false;
    });
    builder.addCase(editSelected.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getWorkers.fulfilled, (state, action) => {
      state.count = action.payload.count;
      state.isLoading = false;

      const parsedRows = action.payload.rows.map(
        (row: { [key: string]: any }) => {
          const parsedObject: IWorker = {} as IWorker;

          Object.keys(row).forEach((key) => {
            if (key === "experiences") {
              parsedObject.experiences = {
                count: row[key].count,
                experiences: row[key].experiences.map((value: any) => {
                  return JSON.parse(value);
                }),
              };
            } else {
              parsedObject[key] = row[key];
            }
          });
          return parsedObject;
        }
      ) as IWorker[];

      state.data = parsedRows;
      state.filteredData = parsedRows;
    });
    builder.addCase(getWorkers.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getFamilies.fulfilled, (state, action) => {
      state.data = action.payload.rows;
      state.filteredData = action.payload.rows;
      state.count = action.payload.count;
      state.isLoading = false;
    });
    builder.addCase(getFamilies.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export default databaseTableSlice.reducer;
export const {
  setFieldKeys,
  setAvailableFilters,
  addFilter,
  removeFilter,
  setModalOpened,
  resetFilters,
  setData,
  setFilter,
  filterData,
  setIsEditorOpened,
  addQueryFilter,
  removeQueryFilter,
  resetQueryFilters,
  addTextSearch,
  setPage,
  editSelectedValues,
  setPageTo,
  setLoadingEditing,
  setIsLoading,
} = databaseTableSlice.actions;
