import React from "react";
import { StyledSuccess } from "./StyledSuccess";
import Logo from "../../../components/Logo/Logo";

const Success = () => {
  return (
    <StyledSuccess>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="success-wrapper">
          <h2>Grazie!</h2>
          <p>Un operatore Assindatcolf ti contatterà a breve.</p>
        </div>
      </div>
    </StyledSuccess>
  );
};

export default Success;
