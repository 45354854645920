// Custom component to render Genres 
export const YesNoTag = ({ value }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <>
       <span className={value==="No"?"badge-no":"badge-si"}>
              {value}
       </span>
      </>
    );
  };

export default YesNoTag;
    