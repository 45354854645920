import React from "react";
import { StyledWorkerCardHeader } from "./StyledWorkerCardHeader";
import { WorkerHomeWorkType } from "../../../../../../types/vetrina";
import { IWorker } from "../../../../../../types/filterFields";
import WorkerTag from "../WorkerTag";
import Propic from "./components/Propic";
import { useLocation } from "react-router-dom";
import LocationBox from "../LocationBox";

interface IWorkerCardHeader {
  worker: IWorker;
}

const WorkerCardHeader = ({ worker }: IWorkerCardHeader) => {
  let homeWorkType: string[] = [];
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // if (worker.tipoLavoroDomestico === undefined) return <></>;

  // if (typeof worker.tipoLavoroDomestico === "string") {
  //   homeWorkType.concat((worker.tipoLavoroDomestico as string).split(","));
  // } else {
  //   homeWorkType = homeWorkType.concat(worker.tipoLavoroDomestico);
  // }

  // homeWorkType = homeWorkType.concat(worker.tipoDiRapportoLavorativo);

  return (
    <StyledWorkerCardHeader>
      <div className="card-header">
        {/* <Propic worker={worker} /> */}
        <div className="card-header-content">
          <h3 className="name">
            {worker.nome.charAt(0).toUpperCase() +
              worker.nome.slice(1).toLowerCase()}
            {worker.genere === "Donna"
              ? "👩"
              : worker.genere === "Uomo"
              ? "👨"
              : null}
          </h3>
          <div className="tags-wrapper">
            {worker.tipo_lavoro.map((tag, i) => {
              return <WorkerTag tag={tag as WorkerHomeWorkType} key={i} />;
            })}
          </div>
          {worker.have_referenze ? <p>Referenze Verificabili ✅</p> : null}
          <p className="info-text">⏱️ {worker.tipo_rapporto.join(", ")}</p>
          <p className="description-text">
            {worker.descrizione_personale_testo_libero}
          </p>
        </div>
      </div>
      {/* {worker.tipoLavoroDomestico ? (
        <div className="card-tags">
          {homeWorkType.map((tag, i) => {
            if (!Object.values(queryParams).includes(tag)) return null;
            return <WorkerTag tag={tag as WorkerHomeWorkType} key={i} />;
          })}
        </div>
      ) : null} */}
      <LocationBox worker={worker} />
    </StyledWorkerCardHeader>
  );
};

export default WorkerCardHeader;
