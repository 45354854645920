import styled from "styled-components";

export const StyledLoader = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffa0;
  backdrop-filter: blur(5px) brightness(0.8);
  z-index: 1000;
  top: 0;
  left: 0;

  .load-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .line {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      margin-inline: 4px;
      background-color: var(--blue);
    }
  }

  .load-2 .line:nth-last-child(1) {
    animation: loadingB 1.5s 1s infinite;
  }
  .load-2 .line:nth-last-child(2) {
    animation: loadingB 1.5s 0.5s infinite;
  }
  .load-2 .line:nth-last-child(3) {
    animation: loadingB 1.5s 0s infinite;
  }

  @keyframes loadingB {
    0% {
      width: 32px;
    }
    50% {
      width: 70px;
    }
    100% {
      width: 32px;
    }
  }
`;
