import { PayloadAction, Slice, createSlice, current } from "@reduxjs/toolkit";
import { IOnboardingOptions } from "../../types/onboarding";

export interface IState {
  formOptions: IOnboardingOptions;
  tipo_lavoro: string[];
}

const initialState: IState = {
  formOptions: {
    experiences: {
      badante: [],
      colf: [],
      baby_sitter: [],
    },
    describeSelfChoices: [],
    workAvailabilities: {
      badante: [],
      colf: [],
      baby_sitter: [],
    },
    workModalities: [],
  },
  tipo_lavoro: [],
};

interface IReducers {
  setWorkType: (state: IState, action: PayloadAction<string[]>) => void;
  setOnboardingOptions: (
    state: IState,
    action: PayloadAction<IOnboardingOptions>
  ) => void;
  [key: string]: (state: IState, action: PayloadAction<any>) => void;
}

const databaseTableSlice: Slice<IState, IReducers> = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setWorkType: (state, action) => {
      // const i = state.tipo_lavoro.indexOf(action.payload);

      // switch (i !== -1) {
      //   case false:
      //     state.tipo_lavoro.push(action.payload);
      //     break;
      //   case true:
      //     state.tipo_lavoro.splice(i, 1);
      //     break;
      // }

      state.tipo_lavoro = action.payload;
    },
    setOnboardingOptions: (state, action) => {
      state.formOptions = action.payload;
    },
  },
  extraReducers(builder) {},
});

export default databaseTableSlice.reducer;
export const { setWorkType, setOnboardingOptions } = databaseTableSlice.actions;
