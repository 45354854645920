import React from "react";
import PropTypes from "prop-types";
import { IconInterfacesUser } from "../IconInterfacesUser";
import calendarIcon from "../../img/static/calendarIcon.svg";
import "./style.css";

const DateForm = ({
  label = "",
  showIcon = true,
  className,
  value,
  onChange,
  type="date",
  override = <IconInterfacesUser className="icon-interfaces-user-instance" combinedShape={calendarIcon} />,
}) => {
  return (
    <div className={`date-form ${className}`}>
      
      {showIcon && (
        <div className="icon">
          {override}
          <div className="divider" />
        </div>
      )}
  
      <input
        label={label}
        type={type}
        className="campo-input"
        value={value}
        onChange={onChange} 
      />
    </div>
  );
};

DateForm.propTypes = {
  label: PropTypes.string,
  showIcon: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateForm;