import styled from "styled-components";

export const StyledNotify = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffffaf;
    box-shadow: 0 0 8px 8px #00000002;
    padding: 48px;
    border-radius: 16px;
    border: 1px solid #e0e0e0;
    max-width: 600px;

    h2 {
      font-size: 3rem;
      color: var(--orange);
      font-family: "Inter", sans-serif;
      margin-bottom: 8px;
      margin-top: 0;
      text-align: center;
    }

    p {
      color: var(--dark-grey);
      margin-top: 8px;
      text-align: center;
    }
  }
`;
