import React from "react";
import { StyledSelezione } from "./StyledSelezione";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { getWorkers } from "../../../store/thunks/vetrina";
import WorkerCard from "../components/WorkerCard";
import WorkerDetail from "../components/WorkerDetail/WorkerDetail";
import { useLocation } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import { MAX_VETRINA_SELECT } from "../../../constants/vetrina/Other";
import Notification from "../components/Notification";
import { ProvinciaItalia } from "../../../constants/provincie";

const Selezione = () => {
  const dispatchRedux = useDispatch<any>();
  const location = useLocation();
  const pagination = useSelector(
    (state: RootState) => state.vetrina.paginatedWorkers
  );
  const { detailedWorker, totalWorkersCount, selectedWorkers } = useSelector(
    (state: RootState) => state.vetrina
  );

  const queryParams = new URLSearchParams(location.search);

  const tipo_lavoro_domestico = queryParams.get("tipo_lavoro_domestico");
  const tipo_di_rapporto = queryParams.get("tipo_di_rapporto");
  const provincia = queryParams.get("provincia");

  useEffect(() => {
    const curWorkersCount = selectedWorkers.length;
    const lastDocId = selectedWorkers[curWorkersCount - 1]?.codice_fiscale;

    if (tipo_di_rapporto || tipo_lavoro_domestico) {
      if (!lastDocId) return;

      dispatchRedux(
        getWorkers({
          lastDocId,
          count: pagination,
          tipoLavoroDomestico: tipo_lavoro_domestico?.split(",") ?? [],
          tipoDiRapporto: tipo_di_rapporto?.split(",") ?? [],
          provincia: provincia as ProvinciaItalia,
        })
      );
    }
  }, [
    dispatchRedux,
    location,
    pagination,
    tipo_di_rapporto,
    tipo_lavoro_domestico,
    selectedWorkers,
    provincia,
  ]);

  return (
    <>
      <NavigationBar />
      <StyledSelezione>
        <div className="inner-vetrina">
          <div className="inner-content">
            <div className="list-header">
              <h2 className="heading">
                Questa è la tua selezione ({selectedWorkers.length}/
                {MAX_VETRINA_SELECT}) di profili
              </h2>
              <p className="subheading">Procedi per effettuare lo sblocco</p>
            </div>
            <ul className="workers-list">
              {selectedWorkers.map((worker, i) => {
                return (
                  <li key={i}>
                    <WorkerCard worker={worker} />
                  </li>
                );
              })}
            </ul>
          </div>
          {detailedWorker ? <WorkerDetail /> : null}
        </div>
        <Notification />
      </StyledSelezione>
    </>
  );
};

export default Selezione;
