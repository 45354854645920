import React, { useEffect, useState } from "react";
import { StyledMobileWorkerDetail } from "./StyledMobileWorkerDetail";
import WorkerCardHeader from "../../../WorkerCard/components/WorkerCardHeader";
import WorkerDescription from "../../../WorkerCard/components/WorkerDescription";
import closeIcon from "../../../../../../assets/close-x.svg";
import chevronDown from "../../../../../../assets/chevron-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { setDetailedWorker } from "../../../../../../store/slices/vetrina";
import { workerDetails } from "../../../WorkerCard/helpers/workerDetails";
import { cosaSoFare } from "../../helpers/propertiesHandlers";
import { Spacer } from "../../../Spacer";
import DropdownListElement from "../DropdownListElement";
import {
  IEsperienzaLavorativa,
  ITestPsicoAttitudinale,
} from "../../../../../../types/vetrina";
import { getWorkers } from "../../../../../../store/thunks/vetrina";
import { useLocation } from "react-router-dom";
import { parseJson } from "../../../../../../utils/jsonUtils";

const MobileWorkerDetail = () => {
  const [compressedSkillsList, setCompressedSkillsList] = useState<{
    badante: boolean;
    colf: boolean;
    baby_sitter: boolean;
    condizioni_accettabili: boolean;
  }>({
    badante: true,
    colf: true,
    baby_sitter: true,
    condizioni_accettabili: true,
  });
  const [skillsList, setSkillsList] = useState<{
    badante: string[];
    colf: string[];
    baby_sitter: string[];
  }>({
    badante: [],
    colf: [],
    baby_sitter: [],
  });
  const [condizioniAccettabili, setCondizioniAccettabili] = useState<string[]>(
    []
  );
  const [expLav, setExpLav] = useState<IEsperienzaLavorativa[]>([]);
  const [testPA, setTestPA] = useState<ITestPsicoAttitudinale[]>([]);
  const [testDropOpened, setTestDropOpened] = useState(true);
  const [esDropOpened, setEsDropOpened] = useState(true);
  const dispatchRedux = useDispatch<any>();
  const detailedWorker = useSelector(
    (state: RootState) => state.vetrina.detailedWorker
  );

  const handleClose = () => {
    dispatchRedux(setDetailedWorker(null));
  };

  const handleSkillsList = (type: string) => {
    setCompressedSkillsList((p) => ({
      ...p,
      [type]: !p[type as keyof typeof p],
    }));
  };

  useEffect(() => {
    setSkillsList((p) => {
      if (!detailedWorker) return p;

      const serializedObject = { ...detailedWorker?.hard_skills };

      if (compressedSkillsList.badante) {
        serializedObject.badante = serializedObject.badante.slice(0, 2);
      }
      if (compressedSkillsList.colf) {
        serializedObject.colf = serializedObject.colf.slice(0, 2);
      }
      if (compressedSkillsList.baby_sitter) {
        serializedObject.baby_sitter = serializedObject.baby_sitter.slice(0, 2);
      }
      if (compressedSkillsList.condizioni_accettabili) {
        setCondizioniAccettabili((p) => p?.slice(0, 2));
      }
      return serializedObject;
    });
  }, [detailedWorker, compressedSkillsList]);

  useEffect(() => {
    setCondizioniAccettabili(() => {
      const caList = [];
      const serializedObject = detailedWorker?.condizioni_accettabili;

      for (const key in serializedObject) {
        if ((serializedObject as any)[key as any]) {
          caList.push((serializedObject as any)[key as any]);
        }
      }

      return caList.flat(Infinity);
    });
  }, [detailedWorker?.condizioni_accettabili, compressedSkillsList]);

  useEffect(() => {
    setExpLav([]);
    detailedWorker?.experiences?.experiences.forEach((exp) => {
      const experience = parseJson(exp as unknown as string);
      const pExp = parseJson(experience);
      setExpLav((p) => [...p, pExp.values]);
    });
  }, [detailedWorker]);

  if (!detailedWorker) return <></>;

  return (
    <StyledMobileWorkerDetail className="worker-detail">
      <div className="sheet-header">
        <img onClick={handleClose} src={closeIcon} alt={""} />
      </div>
      <WorkerCardHeader worker={detailedWorker} />
      <WorkerDescription worker={detailedWorker} />

      <div className="details">
        {detailedWorker.qualifica_professionale ? (
          <p className="field">{detailedWorker.qualifica_professionale}</p>
        ) : null}
        {detailedWorker.data_di_nascita ? (
          <p className="field">🎂 {detailedWorker.data_di_nascita}</p>
        ) : null}
        {/* {detailedWorker?.automunito ? (
          <p className="field">🚗 Ho la macchina</p>
        ) : detailedWorker?.patente ? (
          <p className="field">🪪 Ho la patente ma non l'auto</p>
        ) : null} */}
        {(Array.isArray(detailedWorker?.patente) &&
          detailedWorker?.patente[0] === "Ho la patente") ||
        detailedWorker?.patente === "Ho la patente" ? (
          <p className="field">
            🪪 <b>Patente: </b>Ho la patente
          </p>
        ) : (
          <p className="field">
            🪪 <b>Patente: </b> Non ho la patente
          </p>
        )}
        {detailedWorker.nazionalita ? (
          <p className="field">🌍 <b>Nazionalità:</b> {detailedWorker.nazionalita}</p>
        ) : null}
        {detailedWorker.cittadinanza ? (
          <p className="field">🌍 <b>Cittadinanza:</b> {detailedWorker.cittadinanza}</p>
        ) : null}
        {workerDetails(detailedWorker).map((detail, i) => {
          return <p key={i}>{detail}</p>;
        })}
        {detailedWorker.lingue_parlate?.length ? (
          <p className="field">
            🎂 <b>Data di nascita:</b> {detailedWorker.data_di_nascita}
          </p>
        ) : null}
        {detailedWorker.conoscenza_italiano ? (
          <p className="field">
            🇮🇹 <b>Il mio livello di italiano è:</b>{" "}
            {detailedWorker.conoscenza_italiano}
          </p>
        ) : null}
        {detailedWorker.lingue_parlate?.length ? (
          <p className="field">
            🗣️ <b>Parlo:</b> {detailedWorker.lingue_parlate.join(", ")}
          </p>
        ) : null}
      </div>
      {skillsList.badante.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">I miei punti forti come Badante</h3>
          <ul>
            {skillsList.badante.map((skill, i) => {
              return (
                <li key={i}>
                  <p>· {skill}</p>
                </li>
              );
            })}
          </ul>
          {skillsList.badante.length > 2 ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("badante")}
            >
              {compressedSkillsList.badante
                ? "mostra tutte le mie competenze"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {skillsList.colf.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">I miei punti forti come Colf</h3>
          <ul>
            {skillsList.colf.map((skill, i) => {
              return (
                <li key={i}>
                  <p>· {skill}</p>
                </li>
              );
            })}
          </ul>
          {skillsList.colf.length > 2 ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("colf")}
            >
              {compressedSkillsList.colf
                ? "mostra tutte le mie competenze"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {skillsList.baby_sitter.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">
            I miei punti forti come Baby Sitter
          </h3>
          <ul>
            {skillsList.baby_sitter.map((skill, i) => {
              return (
                <li key={i}>
                  <p>· {skill}</p>
                </li>
              );
            })}
          </ul>
          {skillsList.baby_sitter.length > 2 &&
          compressedSkillsList.baby_sitter ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("baby_sitter")}
            >
              {compressedSkillsList.baby_sitter
                ? "mostra tutte le mie competenze"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {condizioniAccettabili.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">Cosa posso fare?</h3>
          <ul>
            {condizioniAccettabili.map((ca, i) => {
              return (
                <li key={i}>
                  <p>· {ca}</p>
                </li>
              );
            })}
          </ul>
          {Object.values(detailedWorker?.condizioni_accettabili).flat(Infinity)
            .length > 2 ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("condizioni_accettabili")}
            >
              {compressedSkillsList.condizioni_accettabili
                ? "mostra tutto quello che posso fare"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {expLav.length > 0 ? (
        <div>
          <h3
            className="secondary-title"
            onClick={() => setEsDropOpened((p) => !p)}
          >
            Le mie esperienze <img src={chevronDown} alt="" />
          </h3>
          <ul
            className={`dropdown dropdown-esperienze ${
              esDropOpened ? "opened" : ""
            }`}
          >
            {expLav.map((exp, i) => {
              return (
                <div>
                  <h3>Esperienza come {exp?.esperienza_come}</h3>
                  <p>Inizio: {exp?.inizio_esperienza}</p>
                  <p>Fine: {exp?.fine_esperienza}</p>
                  <p>{exp?.modalita_lavoro_esperienza}</p>
                  <p>{exp?.mansioni}</p>
                </div>
              );
            })}
          </ul>
        </div>
      ) : null}
      {testPA.length > 0 ? (
        <div>
          <h3
            className="secondary-title"
            onClick={() => setTestDropOpened((p) => !p)}
          >
            Test psico-attitudinale <img src={chevronDown} alt="" />
          </h3>
          <ul
            className={`dropdown dropdown-test ${
              testDropOpened ? "opened" : ""
            }`}
          >
            {testPA.map((ta, i) => {
              const title = `${ta.question}`;

              return (
                <DropdownListElement
                  isLast={i === testPA.length - 1}
                  title={title}
                  description={ta.answer}
                  key={i}
                />
              );
            })}
          </ul>
        </div>
      ) : null}
    </StyledMobileWorkerDetail>
  );
};

export default MobileWorkerDetail;
