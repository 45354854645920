import React from "react";
import { StyledBottomButtons } from "./StyledBottomButtons";
import Button from "../../../Button";
import { IWorker } from "../../../../../../types/filterFields";
import rightArrow from "../../../../../../assets/right-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { encryptEmail } from "../../../../../../utils/encryptString";
import {
  addSelectedWorker,
  removeSelectedWorker,
  setDetailedWorkerToNext,
  setDetailedWorkerToPrev,
} from "../../../../../../store/slices/vetrina";
import { getWorkers } from "../../../../../../store/thunks/vetrina";
import { useLocation, useNavigate } from "react-router-dom";
import { MAX_VETRINA_SELECT } from "../../../../../../constants/vetrina/Other";
import { ProvinciaItalia } from "../../../../../../constants/provincie";

const BottomButtons = () => {
  const worker = useSelector(
    (state: RootState) => state.vetrina.detailedWorker
  );
  const workers = useSelector((state: RootState) => state.vetrina.workers);
  const pagination = useSelector(
    (state: RootState) => state.vetrina.paginatedWorkers
  );
  const navigator = useNavigate();
  const location = useLocation();
  const dispatchRedux = useDispatch<any>();

  const queryParams = new URLSearchParams(location.search);

  const tipo_lavoro_domestico = queryParams.get("tipo_lavoro_domestico");
  const tipo_di_rapporto = queryParams.get("tipo_di_rapporto");

  const selectedWorkers = useSelector(
    (state: RootState) => state.vetrina.selectedWorkers
  );

  const handleAddSelected = (worker: IWorker) => {
    if (selectedWorkers.includes(worker)) {
      dispatchRedux(removeSelectedWorker({ worker }));
    } else {
      dispatchRedux(addSelectedWorker({ worker }));
    }
  };

  const currentWorkerIndex = workers.findIndex(
    (w) => w.codice_fiscale === worker?.codice_fiscale
  );

  const handleShowPrev = async () => {
    dispatchRedux(setDetailedWorkerToPrev());
  };

  const handleShowNext = async () => {
    if (currentWorkerIndex === workers.length - 1) {
      await handleShowMore();
      await handleFetchMore();
    } else {
      document
        .getElementById(`worker-card-${worker?.codice_fiscale}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }
    dispatchRedux(setDetailedWorkerToNext());
  };

  const handleShowMore = async () => {
    await dispatchRedux(
      getWorkers({
        lastDocId: workers[workers.length - 1].codice_fiscale!,
        count: pagination,
        tipoLavoroDomestico: tipo_lavoro_domestico?.split(",") ?? [],
        tipoDiRapporto: tipo_di_rapporto?.split(",") ?? [],
        provincia: queryParams.get("provincia") as ProvinciaItalia,
      })
    );
  };

  const handleFetchMore = async () => {
    if (!workers[workers.length - 1].codice_fiscale) return;
    if (Object.keys(queryParams).length > 0) {
      await dispatchRedux(
        getWorkers({
          lastDocId: workers[workers.length - 1].codice_fiscale!,
          count: pagination,
          tipoLavoroDomestico: tipo_lavoro_domestico?.split(",") ?? [],
          tipoDiRapporto: tipo_di_rapporto?.split(",") ?? [],
          provincia: queryParams.get("provincia") as ProvinciaItalia,
        })
      );
    }
  };

  if (!worker) return <></>;

  return (
    <StyledBottomButtons>
      <Button
        disabled={currentWorkerIndex === 0}
        onClick={() => handleShowPrev()}
        className="prox-btn"
        role="secondary"
      >
        Vedi precedente
      </Button>
      <Button
        disabled={currentWorkerIndex === workers.length - 1}
        onClick={() => handleShowNext()}
        className="prox-btn"
        role="secondary"
      >
        Vedi prossimo
      </Button>
      <Button
        onClick={() => handleAddSelected(worker)}
        className={[
          "disp-btn",
          selectedWorkers.includes(worker) ? "selected" : "",
          selectedWorkers.length === MAX_VETRINA_SELECT &&
          !selectedWorkers.includes(worker)
            ? "disabled"
            : "",
        ].join(" ")}
      >
        {selectedWorkers.includes(worker) ? "Rimuovi dalla " : "Aggiungi alla "}
        tua Selezione
      </Button>
    </StyledBottomButtons>
  );
};

export default BottomButtons;
