import PropTypes from "prop-types";
import React from "react";
import "./style.css";

const MenuItemContainer = ({
  property1,
  className,
  text,
  icon,
  onItemClick,
}) => {
  const handleClick = () => {
    onItemClick(text); // Chiamiamo la funzione passata come prop con il testo dell'elemento
  };

  return (
    <div className={`menu-item-container ${className}`} onClick={handleClick}>
      {property1 === "default" && (
        <div className="menu-item-content">
          <div className="icon-interfaces-home">
            <img className="combined-shape" alt="Combined shape" src={icon} />
          </div>
          <p className="menu-item-text">{text}</p>
        </div>
      )}

      {property1 === "selected" && (
        <>
          <div className="div">
            <div className="icon-interfaces-home">
              <img className="combined-shape" alt="Combined shape" src={icon} />
            </div>
            <p className="text-wrapper">{text}</p>
          </div>
          <div className="selection-border" />
        </>
      )}
    </div>
  );
};

MenuItemContainer.propTypes = {
  property1: PropTypes.oneOf(["selected", "default"]),
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default MenuItemContainer;
