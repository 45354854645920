import { createSlice, current } from "@reduxjs/toolkit";
import { MultiFactorError } from "firebase/auth";

interface IMainState {
  isLoading?: boolean;
  multifactorError?: MultiFactorError;
}

const initialState: IMainState = {
  isLoading: false,
  multifactorError: undefined,
};

const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMultifactorError(state, action) {
      state.multifactorError = action.payload;
    },
  },
  extraReducers(builder) {},
});

export const { setIsLoading, setMultifactorError } = mainSlice.actions;
export default mainSlice.reducer;
