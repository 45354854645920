// PrivacyPage.js

import React from 'react';

function PrivacyWorkerPage() {
  return (
    <div style={{marginLeft:"5%",marginRight:"5%"}}>
      <h1>INFORMATIVA AI SENSI DEGLI ARTT. 13 E 14 REGOLAMENTO UE 679/2016 DEL 27 APRILE 2016</h1>
      Egregio Signore / Gentile Signora,
ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei
dati personali, nonché alla libera circolazione di tali dati, la scrivente, in qualità di titolare del trattamento, informa che i dati personali acquisiti con
riferimento ai rapporti instaurati saranno oggetto di trattamento nel rispetto dei diritti delle libertà fondamentali, nonché nella dignità dell’interessato, con
particolare riferimento alla riservatezza e all’identità personale e al diritto alla protezione dei dati personali. Le forniamo pertanto le seguenti informazioni:

<h2> Finalità del trattamento – Natura del conferimento e dei dati trattati </h2>
I dati da Lei forniti, o da noi raccolti presso il Suo datore di lavoro, saranno trattati per:
a) Esigenze contrattuali per l’esecuzione dei servizi richiesti in suo favore dal Suo Datore di lavoro, iscritto alla ns. Associazione, e per gli
adempimenti di legge conseguenti o connessi, tra cui (elenco esemplificativo ma non esaustivo): elaborazione mensile della busta paga;
conteggio TFR (liquidazione) e competenze di fine rapporto, istruttoria pratiche di rimborso spese mediche di Cas.sa Colf del lavoratore etc.;
b) Comunicazione istituzionale di Assindatcolf (e sue società controllate/partecipate), effettuata tramite strumenti di comunicazione e diffusione
istituzionale (sito Internet, profilo Facebook, newsletter, opuscoli, giornali, riviste, periodici o comunicazioni a mezzo posta ordinaria o posta
elettronica/messaggeria social/telefono) inerenti alle attività promosse da Assindatcolf, come (elenco esemplificativo ma non esaustivo) corsi di
formazione, attività di intermediazione per il collocamento con inserimento in specifiche banche dati, indagini di mercato a fini statistici per il
miglioramento delle condizioni contrattuali;
Il conferimento dei dati per le finalità indicate richiede il consenso dell’interessato, che costituisce pertanto la base giuridica del trattamento (Art. 6 c.1 lett.
a - Art. 9 c. 2 lett. a)).
I dati oggetto del trattamento sono dati comuni, ma possono rientrarvi anche dati “particolari” o sensibili, cioè idonei a rivelare, ad esempio, lo stato di salute
(certificati relativi ad assenze per malattia, maternità, infortunio), o le convinzioni religiose (richiesta di fruizione di festività religiose).

<h2>Modalità e durata del trattamento </h2>
I dati personali sono sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato. Adeguate misure di sicurezza sono osservate per prevenire la
perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
Fatte salve le esigenze di cui all’ art. 17 c. 3 e le necessità del Titolare del trattamento di disporne con finalità probatorie da utilizzare in caso di ispezioni
degli organi di vigilanza e/o in sede di contenzioso, i dati sono trattati, conservati e custoditi fino a quando sussiste la finalità per cui sono stati raccolti.

<h2>Accesso, comunicazione, diffusione e trasferimento dei dati</h2>
<p>Ai dati possono accedere dipendenti e collaboratori del Titolare, nella loro qualità di incaricati e/o delegati del trattamento e/o amministratori di sistema. I
dati possono inoltre essere comunicati, anche nella loro qualità di responsabili del Titolare, a:
1) soggetti che forniscono servizi per la gestione del sistema informativo e delle reti di telecomunicazioni (compresa la posta elettronica);
2) soggetti che svolgono attività di trasmissione, imbustamento, trasporto e smistamento delle comunicazioni con gli associati;
3) soggetti che svolgono attività di assistenza agli associati (es. call center, help desk, ecc.);
4) studi o società nell’ambito di rapporti di assistenza e consulenza;
5) soggetti di adempimenti di controllo, revisione e certificazione delle attività svolte dall’Associazione anche nell’interesse degli associati;
6) Organi competenti in materia di lavoro, Istituti previdenziali ed assicurativi;
7) Pubbliche amministrazioni, Organi di Polizia e autorità Giudiziaria, secondo esigenze valutate insindacabilmente dalla ns. Organizzazione.
I dati non sono soggetti a diffusione.
In considerazione dell&#39;esistenza di collegamenti telematici, informatici o di corrispondenza, i dati possono essere trasferiti anche all&#39;estero, all’interno
dell&#39;Unione Europea. In caso di trasferimento in paesi extra-UE il Titolare assicura sin d’ora che il trasferimento dei dati extra-UE avverrà in conformità alle
disposizioni di legge applicabili stipulando, se necessario, accordi che garantiscano un livello di protezione adeguato e/o adottando le clausole contrattuali
standard previste dalla Commissione Europea.</p>


<h2>Diritti degli interessati (Artt. 15-21 GDPR)</h2>
<p>I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di:
1. ottenere la conferma dell’esistenza di dati che li riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile;
2. ottenere l’indicazione: a) dell’origine dei dati personali; b) delle finalità e modalità del trattamento; c) della logica applicata in caso di trattamento
effettuato con l’ausilio di strumenti elettronici; d) degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell’art.. 3,
comma 1, GDPR; e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in
qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;
3. ottenere: a) l’aggiornamento, la rettificazione ovvero, quando vi hanno interesse, l’integrazione dei dati; b) la cancellazione, la trasformazione in forma
anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati
sono stati raccolti o successivamente trattati; c) l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto
riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta
un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;
4. opporsi, in tutto o in parte: a) per motivi legittimi al trattamento dei dati personali che li riguardano, ancorché pertinenti allo scopo della raccolta; b) al
trattamento di dati personali che li riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di
comunicazione commerciale, mediante l’uso di sistemi automatizzati di chiamata senza l’intervento di un operatore mediante email e/o mediante modalità
di marketing tradizionali mediante telefono e/o posta cartacea. Si fa presente che il diritto di opposizione dell’interessato, esposto al precedente punto b),
per finalità di marketing diretto mediante modalità automatizzate si estende a quelle tradizionali e che comunque resta salva la possibilità per l’interessato
di esercitare il diritto di opposizione anche solo in parte. Pertanto, l’interessato può decidere di ricevere solo comunicazioni mediante modalità tradizionali
ovvero solo comunicazioni automatizzate oppure nessuna delle due tipologie di comunicazione.
Ove applicabili, hanno altresì i diritti di cui agli artt. 16-21 GDPR (Diritto di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità
dei dati, diritto di opposizione, diritto di revoca del consenso che non pregiudica la liceità del trattamento basata sul consenso prestato prima della revoca),
nonché il diritto di reclamo all’Autorità Garante. </p>

<p>Titolare del trattamento dei dati è
Ass. Sindacale Nazionale dei Datori di Lavoro dei Collaboratori Famigliari – ASSINDATCOLF Via Principessa Clotilde, 2 00196 Roma.
Per esercitare i diritti di cui all&#39;Art. 12 e/o per chiarimenti in materia di tutela dati personali, è possibile rivolgersi all’indirizzo: nazionale@assindatcolf.it.
 </p>
    </div>
  );
}

export default PrivacyWorkerPage;
