const MIN_WIDTH_DESKTOP = 1024;
const MIN_WIDTH_TABLET = 600;
const MAX_WIDTH_TABLET = 1023;
const MAX_WIDTH_MOBILE = 599;

export {
  MIN_WIDTH_DESKTOP,
  MIN_WIDTH_TABLET,
  MAX_WIDTH_TABLET,
  MAX_WIDTH_MOBILE
};
