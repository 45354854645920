import React from 'react';
import './style.css';

const OkMessageInForm = ( {
  headline = "",
  message = "Paragraph text",
  paragraphIsVisible = true,
  className="banner-instance",
}  ) => {
  if (message===""){
    return <></>

  }else{
    return (
      <div className={`banner-ok  ${className}`}>
        <div className="div-ok">
          <div className="text-frame">
            <div className="headline-text">{headline}</div>
            {paragraphIsVisible && <div className="paragraph-text">{message}</div>}
          </div>
        </div>
      </div>
    );  
   
  }
  
};

export default OkMessageInForm;
