// Custom component to render Genres 
export const GenereTag = ({ value }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <>
       <span className={value==="Uomo"?"badge-uomo":"badge-donna"}>
              {value}
       </span>
      </>
    );
  };

export default GenereTag;
    