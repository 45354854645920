import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import Button from "../../components/Button/index";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";

import "./style.css";
import Menu from "../../components/Menu/index.js";
import { app } from "../../lib/firebase/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  registerUserToSession,
  setSelection,
} from "../../store/slices/vetrina";

const Home = () => {
  const [isAuth, setIsAuth] = useState(false);
  const auth = getAuth();

  const db = getFirestore(app);

  onAuthStateChanged(auth, (user) => {
    // if (user) {
    //     // User is signed in, see docs for a list of available properties
    //     // https://firebase.google.com/docs/reference/js/auth.user
    //     const uid = user.uid;

    setIsAuth(true);
    // }
    //     // User is signed out

    //     // Change only the path of the URL
    //     window.location.pathname = "/sign-in";
    //   }
  });

  const navigate = useNavigate();
  const dispatchRedux = useDispatch<any>();
  useEffect(() => {
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const db = getFirestore(app);
        const docRef = collection(db, "internal_users");
        const q = query(docRef, where("email", "==", user.email));
        const querySnapshot = (await getDocs(q)) as any;
        if (querySnapshot.docs?.length === 0) {
          navigate(
            `/sign-in-admin?from=${encodeURIComponent(
              window.location.pathname
            )}`
          );
        } else {
          dispatchRedux(registerUserToSession(querySnapshot.docs[0].data()));
          dispatchRedux(
            setSelection(querySnapshot.docs[0].data().selection ?? [])
          );
        }
      } else {
        navigate(
          `/sign-in-admin?from=${encodeURIComponent(window.location.pathname)}`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickLogoututton = () => {
    signOut(auth)
      .then(() => {
        // Logout avvenuto con successo
        setIsAuth(false);
        window.location.pathname = "/sign-in"; // Cambia il percorso dell'URL alla pagina di accesso
      })
      .catch((error) => {
        // Si è verificato un errore durante il logout
        console.log(error);
      });
  };

  const testDB = async () => {
    // Crea una query per cercare l'elemento desiderato
    const q = query(
      collection(db, "external_users"),
      where("codice_fiscale", "==", "VLZRVD76M71Z605T")
    );

    // Esegui la query
    const querySnapshot = await getDocs(q);

    // Itera sui risultati
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });
  };

  if (isAuth) {
    return (
      <div>
        <Menu initialItemSelected="Home"></Menu>
        <div className="home-main">
          <iframe
            width="800"
            height="600"
            src="https://lookerstudio.google.com/embed/reporting/40ebb92f-71ae-479d-9d81-ef028ce56e5a/page/hFmkD"
            title="looker"
          ></iframe>
        </div>
      </div>
    );
  } else {
    return <h1>Loading...</h1>;
  }
};

export default Home;
