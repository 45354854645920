import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import { isAcceptableBirthDate } from "../functionsSteps";
import DateForm from "../../../components/DateForm";
import { transformDateStringToFirestoreDate } from "../../../utils/utils.js";
import { isStepValid } from "../../../utils/onboarding";

const BirthDate = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const { birthDate } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    //console.log(workModalitiesList);
    if (
      isStepValid({
        mandatoryFields: "birthDate",
        formData: {
          birthDate: formData.birthDate,
        },
      })
    ) {
      onNext();
    } else {
      setErrorMessage(
        "Inserisci una data corretta. Devi essere maggiorenne per utilizzare il servizio"
      );
    }
  };

  const handleClickPreviousButton = () => {
    //onInputChange("birthDate",[]);
    // onInputChange("gender", []);

    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons" style={{ overflow: "hidden" }}>
          <div className="title-container">
            <h1 className="title">{title} </h1>
          </div>
          <h2 className="subtitle">{subtitle}</h2>
          <div className="div">
            <DateForm
              value={birthDate}
              onChange={(value) => {
                onInputChange("birthDate", value.target.value);
              }}
            ></DateForm>
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={
                  ("button-instance",
                  !isStepValid({
                    mandatoryFields: "birthDate",
                    formData: {
                      birthDate: formData.birthDate,
                    },
                  })
                    ? "button-disabled"
                    : "")
                }
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthDate;
