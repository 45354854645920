export const isStepValid = ({
  mandatoryFields,
  formData,
}: {
  mandatoryFields: string[] | string;
  formData: object;
}): boolean => {
  try {
    let isValid = true;
    Object.keys(formData).forEach((field) => {
      const mFields: string[] = [];
      if (field === "telephoneNumber") {
        isValid =
          (formData[field as keyof typeof formData] as string).length >= 12;
        // const els = (formData[field as keyof typeof formData] as string).split(
        //   " "
        // );
        // if (els.length === 1 || els[1] === "") {
        //   isValid = false;
        // }
      }

      if (typeof mandatoryFields === "string") {
        mFields.push(mandatoryFields);
      } else if (Array.isArray(mandatoryFields)) {
        mFields.push(...mandatoryFields);
      }

      if (!mFields.includes(field)) return;
      if (
        !formData[field as keyof typeof formData] ||
        (mFields &&
          (formData[field as keyof typeof formData] as any[]).length === 0) ||
        formData[field as keyof typeof formData] === ""
      ) {
        isValid = false;
      }
    });

    return isValid;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const combineWorkTypeChoices = ({
  workType,
}: {
  workType: string[];
}) => {};

export const highlightButtonAndProceed = (
  buttonQuerySelector: string,
  highlightClass: string,
  highlightDuration: number,
  onNext: () => void
) => {
  const buttonElement = document.querySelector(
    buttonQuerySelector
  ) as HTMLElement;

  if (!buttonElement) {
    console.warn("No button found with query selector:", buttonQuerySelector);
    return;
  }

  buttonElement.classList.add(highlightClass);

  setTimeout(() => {
    buttonElement.classList.remove(highlightClass);
    onNext();
  }, highlightDuration);
};
