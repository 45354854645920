import React, { useState } from "react";
import Button from "../../../components/Button/index";
import { TypeForm } from "../../../components/TypeForm/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";

import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import { isAcceptableStepFormText } from "../functionsSteps";
import certificationIcon from "../../../img/static/certificationIcon.svg";
import { IconInterfacesUser } from "../../../components/IconInterfacesUser";
import { stepThreeCertification } from "../stepsVariables";
import { useSelector } from "react-redux";

const StepCertification = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const { nameCertification } = formData;

  const [errorMessage, setErrorMessage] = useState("");
  const [nameOtherCertification, setNameOtherCertification] = useState("");

  const workType = useSelector((state) => state.onboarding.tipo_lavoro);

  const handleClickNextButton = () => {
    //console.log(workModalitiesList);
    //console.log(formData);
    if (nameOtherCertification !== "") {
      onInputChange("nameCertification", [
        ...nameCertification,
        nameOtherCertification,
      ]);
    }
    console.log(formData);
    onNext();
    /*
      if (isAcceptableStepFormText(nameCertification)) {
        onNext();
      } else {
        setErrorMessage("Inserisci almeno una modalità di lavoro che stai cercando");
      }*/
  };

  const handleClickPreviousButton = () => {
    // onInputChange("workModalitiesList", []); // Pulisco i dati dello step
    // onInputChange("nameCertification", []); // Pulisco i dati dello step

    if (
      !["Badante", "Colf", "Babysitter", ""].some((v) =>
        workType.some((w) => v === w)
      )
    ) {
      onPrevious(true);
    } else {
      onPrevious();
    }
  };

  const handleSelectChoiceClick = (isClicked, text) => {
    const updatedNameCertification = [...nameCertification];
    if (isClicked) {
      // Aggiungiamo l'elemento text alla lista se isClicked è true e l'elemento non è già presente
      if (!updatedNameCertification.includes(text)) {
        updatedNameCertification.push(text);
      }
    } else {
      // Rimuoviamo l'elemento text dalla lista se isClicked è false e l'elemento è presente
      const index = updatedNameCertification.indexOf(text);
      if (index !== -1) {
        updatedNameCertification.splice(index, 1);
      }
    }
    if (updatedNameCertification.includes("No, non ce l'ho")) {
      if (text === "No, non ce l'ho") {
        onInputChange("nameCertification", ["No, non ce l'ho"]);
      } else {
        const index = updatedNameCertification.indexOf("No, non ce l'ho");
        if (index !== -1) {
          updatedNameCertification.splice(index, 1);
        }
        onInputChange("nameCertification", [...updatedNameCertification]);
      }
    } else {
      onInputChange("nameCertification", [...updatedNameCertification]);
    }
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons" style={{ overflow: "hidden" }}>
          <div className="title-container">
            <h1 className="title">{title} </h1>
          </div>
          <h2 className="subtitle">{subtitle}</h2>
          <>
            <div className="selection-element-horrizonatal">
              {stepThreeCertification.map((item) => {
                return (
                  <SelectChoice
                    isClickedProp={nameCertification.includes(item)}
                    key={item}
                    text={item}
                    onClick={handleSelectChoiceClick}
                  />
                );
              })}
            </div>
          </>
          <div className="div">
            {nameCertification.includes("Altro") ? (
              <TypeForm
                className="type-form-instance"
                label="Nome certificazione"
                value={nameOtherCertification}
                override={
                  <IconInterfacesUser
                    className="icon-interfaces-user-instance"
                    combinedShape={certificationIcon}
                  />
                }
                onChange={
                  (value) => setNameOtherCertification(value)
                  //onInputChange("nameCertification", value)
                }
              />
            ) : (
              <></>
            )}
            {/*
              <DateForm
                value={dateCertification}
                onChange={(value) =>{
                  onInputChange("dateCertification", value.target.value)
                }}
              ></DateForm>*/}
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className="button-instance"
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepCertification;
