  
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
        <div className="text-field-filter">
            <input 
                className="campo-text-area-filter"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) 
                }}
                placeholder={`Cerca tra ${count} elementi...`}
            />
        </div>
    );
}

export default DefaultColumnFilter;