import { transformDateStringToFirestoreDate } from "../../utils/utils.js";

export function isAcceptableStepMultipleOptions(list) {
  // Controlla se la lunghezza della lista è maggiore  0
  return list.length > 0;
}

export function isAcceptableBirthDate(dateString = "") {
  if (!dateString) return;

  if (dateString === "" || dateString === []) {
    return false;
  }

  if (!dateString || dateString.toLowerCase() === "none") {
    return false;
  }

  const year = parseInt(dateString.split("-")[0], 10);
  if (isNaN(year) || year < 1920) {
    return false;
  }

  const currentDate = new Date();
  const userDate = new Date(dateString);

  // Calcola la data a cui l'utente avrà 18 anni dalla data di nascita
  const referenceDate = new Date(userDate);
  referenceDate.setFullYear(referenceDate.getFullYear() + 18);

  //console.log(referenceDate)
  //console.log(currentDate)
  // Verifica se l'utente è maggiorenne o se la data fornita è nel futuro
  if (currentDate < referenceDate || userDate > currentDate) {
    return false;
  }

  return true;
}

export function isAcceptableStepOnlyOne(inputString) {
  // Controlla se la lunghezza della lista è maggiore  0
  //console.log(inputString)
  return inputString.length <= 1 && inputString.length > 0;
}

export const isAcceptableStep5 = (lista, workType) => {
  return workType?.every((type) => {
    const key = type === "Babysitter" ? "baby_sitter" : type.toLowerCase();

    const baseKeys = ["badante", "colf", "baby_sitter"];

    if (!baseKeys.includes(key)) return true;

    return lista[key]?.length > 0;
  });
  /*
    // Controlla se "Nessuna di queste" è presente nella lista
    const nessunaDiQuestePresente = lista.includes("Nessuna di queste");

    // Controlla se la lunghezza della lista è uguale a 1 e contiene "Nessuna di queste"
    const soloNessunaDiQueste = lista.length === 1 && nessunaDiQuestePresente;

    // Ritorna vero se non è presente "Nessuna di queste" o se è presente solo quello
    return !nessunaDiQuestePresente || soloNessunaDiQueste;
    */
};

export const isAcceptableStepFormText = (inputText) => {
  // Verifica se la stringa è vuota o nulla
  if (inputText === []) {
    return false;
  }

  if (!inputText || inputText.trim().length === 0) {
    return false;
  }
  return true;
};

export const isAcceptableStepWith2TextForm = (inputText1, inputText2) => {
  // Verifica se la stringa è vuota o nulla
  if (!inputText1 || inputText1.trim().length === 0) {
    return false;
  }
  if (!inputText2 || inputText2.trim().length === 0) {
    return false;
  }
  return true;
};

export const isSectionValid = (...args) => {
  return args.every((arg) => arg.trim().length > 0);
};

export const transformDictToDB = (data, workerPersistentData) => {
  return {
    tipo_lavoro: data.workOptionsList,
    tipo_rapporto: data.workModalitiesList,
    qualifica_professionale: data.nameCertification,
    documenti_in_regola: workerPersistentData.documenti_in_regola || null,
    condizioni_accettabili: {
      badante: data.acceptableConditions.badante,
      colf: data.acceptableConditions.colf,
      baby_sitter: data.acceptableConditions.baby_sitter,
    },
    cosa_non_fare: "",
    automunito: data.carAvailability,
    hard_skills: {
      badante: data.hardSkills.badante,
      colf: data.hardSkills.colf,
      baby_sitter: data.hardSkills.baby_sitter,
    },
    patente: data.drivingLicense,
    genere: data.gender,
    data_di_nascita: data.birthDate,
    lingue_parlate: data.spokenLanguages,
    paese_origine: data.countryOrigin,
    conoscenza_italiano: data.italianKnowledge,
    nazionalita: data.nationality,
    experiences: {
      count: data.experiences.length,
      experiences: data.experiences.map((exp, i) => ({
        label: `Esperienza ${i}`,
        key: `experiences.values[${i}]`,
        values: {
          esperienza_come: exp.experienceAs,
          inizio_esperienza: exp.startExperience,
          fine_esperienza: exp.endExperience ?? null,
          opzioni_lavoro_esperienza: exp.workOptionExperience,
          modalita_lavoro_esperienza: exp.workModalityExperience,
          mansioni: exp.mansioni,
        },
      })),
    },
    nome: data.name,
    cognome: data.surname,
    codice_fiscale: data.codiceFiscale,
    email: data.email,
    numero_telefono: data.telephoneNumber,
    consenso_privacy: data.consentPrivacy,
    indirizzo: data.address,
    codice_postale: data.postalCode,
    provincia: data.province,
    citta: data.city,
    descrizione_personale_scelte: [],
    descrizione_personale_testo_libero: data.describeSelfFreeText,
    creation_time: new Date().toISOString(),
    note: workerPersistentData.note || "",
    cittadinanza: data.cittadinanza,
    referenze: workerPersistentData.referenze || "",
    have_referenze: false,
    available: true,
    current_status: "Registrato",
    user_type: "lavoratore",
    edit_date: new Date().toISOString(),
  };
};
