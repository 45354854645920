import styled from "styled-components";
import { MAIN_COLORS } from "../../../../constants/vetrina/Colors";
import { DIMENS } from "../../../../constants/vetrina/Dimens";
import { transitionCubic1 } from "../../../../constants/vetrina/Anims";

interface IStyledButton {
  $disabled: boolean;
  $btnrole: "primary" | "secondary" | "tertiary";
}

export const StyledButton = styled.button<IStyledButton>`
  border: 1px solid
    ${({ $btnrole }) =>
      $btnrole === "secondary" ? MAIN_COLORS.black : "transparent"};
  background-color: ${({ $btnrole }) => {
    switch ($btnrole) {
      case "primary":
        return MAIN_COLORS.blue600;
      case "secondary":
        return "transparent";
      case "tertiary":
        return MAIN_COLORS.yellow500;
    }
  }};
  color: ${({ $btnrole }) => {
    switch ($btnrole) {
      case "primary":
        return MAIN_COLORS.white;
      case "secondary":
        return MAIN_COLORS.black;
      case "tertiary":
        return MAIN_COLORS.black;
    }
  }};
  font-family: "Inter", sans-serif;
  padding: 0.75rem 1rem;
  border-radius: ${DIMENS.borderRadius1};
  cursor: pointer;
  display: flex;
  align-items: center;
  ${transitionCubic1}
  font-size: 1rem;
  font-weight: 500;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};

  &:hover {
    transform: ${({ $disabled }) => ($disabled ? "none" : "scale(1.01)")};
    opacity: ${({ $disabled }) => ($disabled ? "1" : "0.9")};
  }

  filter: ${({ $disabled }) => ($disabled ? "grayscale(1)" : "none")};
`;
