import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import { stepTwelveItalianKnowledge } from "../stepsVariables";
import { isAcceptableStepOnlyOne } from "../functionsSteps";
import { isStepValid } from "../../../utils/onboarding";

const ItalianKnowledge = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const { italianKnowledge } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    if (isAcceptableStepOnlyOne(italianKnowledge)) {
      onNext();
    } else {
      setErrorMessage("Inserisci una sola risposta");
    }
  };

  const handleClickPreviousButton = () => {
    // onInputChange("italianKnowledge", []);

    onPrevious(false, 3);
  };

  const handleSelectChoiceClick = (isClicked, text) => {
    //console.log("isClicked:", isClicked);
    //console.log("text:", text);
    //console.log(spokenLanguages);
    const updatedItalianKnowledge = [...italianKnowledge];
    if (isClicked) {
      // Aggiungiamo l'elemento text alla lista se isClicked è true e l'elemento non è già presente
      if (!updatedItalianKnowledge.includes(text)) {
        updatedItalianKnowledge.push(text);
      }
    } else {
      // Rimuoviamo l'elemento text dalla lista se isClicked è false e l'elemento è presente
      const index = updatedItalianKnowledge.indexOf(text);
      if (index !== -1) {
        updatedItalianKnowledge.splice(index, 1);
      }
    }
    //console.log(updatedWorkModalitiesList)
    onInputChange("italianKnowledge", [...updatedItalianKnowledge]);
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>
          <h2 className="subtitle">{subtitle} </h2>
          <div className="div">
            <div className="selection-element-horrizonatal">
              {stepTwelveItalianKnowledge.map((item) => (
                <SelectChoice
                  key={item}
                  text={item}
                  onClick={handleSelectChoiceClick}
                />
              ))}
            </div>
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={
                  ("button-instance",
                  !isStepValid({
                    mandatoryFields: "italianKnowledge",
                    formData: {
                      italianKnowledge: formData.italianKnowledge,
                    },
                  })
                    ? "button-disabled"
                    : "")
                }
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItalianKnowledge;
