import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import { stepTwoModalityWork } from "../stepsVariables";
import { isAcceptableStepMultipleOptions } from "../functionsSteps";
import { isStepValid } from "../../../utils/onboarding";
import { useSelector } from "react-redux";

const StepWorkModalities = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const { workModalitiesList } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const stepOptions = useSelector(
    (state) => state.onboarding.formOptions.workModalities
  );

  const handleClickNextButton = () => {
    //console.log(workModalitiesList);
    //console.log(formData);
    if (isAcceptableStepMultipleOptions(workModalitiesList)) {
      onNext();
    } else {
      setErrorMessage(
        "Inserisci almeno una modalità di lavoro che stai cercando"
      );
    }
  };

  const handleClickPreviousButton = () => {
    // onInputChange("workOptionsList", []); // Pulisco i dati dello step 1
    // onInputChange("workModalitiesList", []); // Pulisco i dati dello step 2

    onPrevious();
  };

  const handleSelectChoiceClick = (isClicked, text) => {
    //console.log("isClicked:", isClicked);
    //console.log("text:", text);
    //console.log(workOptionsList);

    const updatedWorkModalitiesList = [...workModalitiesList];
    if (isClicked) {
      // Aggiungiamo l'elemento text alla lista se isClicked è true e l'elemento non è già presente
      if (!updatedWorkModalitiesList.includes(text)) {
        updatedWorkModalitiesList.push(text);
      }
    } else {
      // Rimuoviamo l'elemento text dalla lista se isClicked è false e l'elemento è presente
      const index = updatedWorkModalitiesList.indexOf(text);
      if (index !== -1) {
        updatedWorkModalitiesList.splice(index, 1);
      }
    }
    //console.log(updatedWorkModalitiesList)
    onInputChange("workModalitiesList", [...updatedWorkModalitiesList]);
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>
          <h2 className="subtitle">{subtitle} </h2>
          <div className="div">
            <div className="selection-element-horrizonatal">
              {stepOptions.map((item) => (
                <SelectChoice
                  isClickedProp={workModalitiesList.includes(item)}
                  key={item}
                  text={item}
                  onClick={handleSelectChoiceClick}
                />
              ))}
            </div>
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={
                  ("button-instance",
                  !isStepValid({
                    mandatoryFields: "workModalitiesList",
                    formData: {
                      workModalitiesList: formData.workModalitiesList,
                    },
                  })
                    ? "button-disabled"
                    : "")
                }
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepWorkModalities;
