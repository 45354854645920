import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import userIcon from "../../img/static/user-icon.svg";

export const IconInterfacesUser = ({ className, combinedShape = {userIcon} }) => {
  return (
    <div className={`icon-interfaces-user ${className}`}>
      <img className="combined-shape" alt="Combined shape" src={combinedShape} />
    </div>
  );
};

IconInterfacesUser.propTypes = {
  combinedShape: PropTypes.string,
};
