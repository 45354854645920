import React, { useEffect } from "react";
import { StyledNotify } from "./StyledNotify";
import Logo from "../../components/Logo/Logo";

export enum NotifyTypes {
  VERIFY_EMAIL = "verify-email",
  PURCHASE_SUCCESS = "succ",
  PURCHASE_CANCELED = "canc",
}

type NotifyContents = {
  [Key in NotifyTypes]: {
    title: string;
    description: string;
  };
};

const notifyContents: NotifyContents = {
  [NotifyTypes.VERIFY_EMAIL]: {
    title: "Verifica la tua email",
    description:
      "Per favore, verifica la tua email cliccando sul link che ti abbiamo inviato.",
  },
  [NotifyTypes.PURCHASE_SUCCESS]: {
    title: "Pagamento effettuato",
    description: "Riceverai una email con i dettagli del tuo acquisto.",
  },
  [NotifyTypes.PURCHASE_CANCELED]: {
    title: "Pagamento annullato",
    description: "Il pagamento è stato annullato.",
  },
};

const Notify = () => {
  const [notifyType, setNotifyType] = React.useState<NotifyTypes | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("type") as NotifyTypes;
  const psc = urlParams.get("pcs");
  const message = decodeURIComponent(urlParams.get("m") ?? "");

  useEffect(() => {
    if (type) setNotifyType(type);
    if (psc)
      setNotifyType(
        psc === "succ"
          ? NotifyTypes.PURCHASE_SUCCESS
          : NotifyTypes.PURCHASE_CANCELED
      );
  }, [psc, type]);

  if (!type)
    return (
      <StyledNotify>
        <div className="onboarding-interno">
          <Logo></Logo>
          <div className="success-wrapper">
            <h2>{notifyType ? notifyContents[notifyType].title : "Errore"}</h2>
            <p>
              {notifyType
                ? notifyContents[notifyType].description
                : "Il link che hai aperto non è valido."}
            </p>
          </div>
        </div>
      </StyledNotify>
    );

  return (
    <StyledNotify>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="success-wrapper">
          <h2>{notifyContents[type].title}</h2>
          <p>
            {message.length > 0 ? message : notifyContents[type].description}
          </p>
        </div>
      </div>
    </StyledNotify>
  );
};

export default Notify;
