import styled from "styled-components";

export const StyledEditField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 500;
    opacity: 0.6;
  }

  input {
    border: none;
    border: 1px solid var(--dark-grey);
    padding: 16px;
    border-radius: 4px;
    font-size: 16px;
    padding: 6px 8px;
    outline: none;
    font-weight: 500;
    font-family: inherit;
  }

  select {
    border: none;
    border: 1px solid var(--dark-grey);
    padding: 16px;
    border-radius: 4px;
    font-size: 16px;
    padding: 6px 8px;
    outline: none;
    font-weight: 500;
    font-family: inherit;
  }
`;
