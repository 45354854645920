import styled from "styled-components";

interface IStyledEditor {
  $opened: boolean;
  $snackbarShown: boolean;
}

export const StyledEditor = styled.div<IStyledEditor>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 300;
  background-color: #00000080;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.$opened ? 1 : 0)};
  visibility: ${(props) => (props.$opened ? "visible" : "hidden")};
  transition: all 0.3s;

  .dropdown-editor-bar {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 8px;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0.2rem;
      font-weight: bold;
    }

    &.opened {
    }
  }

  .fields-list-wrapper {
    background-color: #fbfbfb;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  .modal {
    position: absolute;
    width: min(450px, 80vw);
    height: 100vh;
    background-color: white;
    right: 0;
    transform: ${(props) =>
      props.$opened ? "translateX(0)" : "translateX(100%)"};
    transition: transform 0.3s;
    padding: 24px;
    overflow-y: scroll;

    .exit-wrapper {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #e6e6e6;
      z-index: 100;
      background-color: white;
      width: 100%;
      position: sticky;
      top: -1.5rem;
      right: -1.5rem;

      &__main {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      p {
        font-size: 18px;
        font-weight: 800;
        margin: 0;
      }

      &__bottom {
        display: flex;
        grid-gap: 1rem;
        padding-top: 1rem;

        p {
          font-size: 0.9rem;
          font-weight: 500;
          color: #666666;
        }
      }

      .buttons-wrapper {
        display: flex;
        grid-gap: 8px;
        align-items: center;

        .save-btn {
          justify-self: flex-end;
        }

        .exit-btn {
          background-color: var(--blue);
          padding: 12px 16px;
          height: fit-content;
        }
      }
    }

    .fields {
      display: grid;
      width: 100%;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      margin-top: 48px;

      label {
        font-size: 12px;
        margin-bottom: 4px;
        font-weight: 500;
        opacity: 0.6;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }

  .snack-notification {
    position: fixed;
    bottom: ${({ $snackbarShown }) => ($snackbarShown ? "1rem" : "-100%")};
    left: 1rem;
    background-color: #55aa55;
    padding: 1rem 1.5rem;
    border-radius: 6px;
    transition: all 0.3s ease;
    width: min(450px, 80vw);

    h3 {
      color: white;
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }
`;
