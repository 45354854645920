import { accordionStyles } from "./../../../databaseAdmin/model-table/components/Accordion/styles";
import styled from "styled-components";

interface IStyledBoard {
  $isOver: boolean;
  $color: string;
}

export const StyledBoard = styled.div<IStyledBoard>`
  background-color: #fafafa80;
  border: 1px solid #e0e0e0;
  border-radius: 12px;

  .board-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
    padding: 12px 16px 12px 16px;
    position: sticky;
    z-index: 100;
    top: 0;

    .board-name {
      color: #3a3a3a;
      font-weight: 800;
      margin: 0;
    }

    .color-dot {
      background-color: ${({ $color }) => $color};
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
  }

  .board-body {
    min-height: 100%;
  }
`;
