import { createAsyncThunk } from "@reduxjs/toolkit";
import { IWorker } from "../../types/filterFields";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../lib/firebase/functions";

export const getKanbanWorkers = createAsyncThunk(
  "kanban/getKanbanWorkers",
  async ({ lastDays }: { lastDays: number }): Promise<{ rows: IWorker[] }> => {
    const getlastkanbanworkers = httpsCallable(
      functions,
      "getlastkanbanworkers"
    );

    const response = await getlastkanbanworkers({ lastDays });

    return response.data as { count: number; rows: IWorker[] };
  }
);

export const updateKanbanBoard = createAsyncThunk(
  "kanban/updateKanbanBoard",
  async ({
    workerId,
    board,
  }: {
    workerId: string;
    board: string;
  }): Promise<{
    success: boolean;
    updated: { codice_fiscale: string; board: number };
  }> => {
    const updatekanbanstateworkers = httpsCallable(
      functions,
      "updatekanbanstateworkers"
    );

    const response = await updatekanbanstateworkers({
      codiceFiscale: workerId,
      board,
    });

    return response.data as {
      success: boolean;
      updated: { codice_fiscale: string; board: number };
    };
  }
);
