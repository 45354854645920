import { IField, IFilterFields, IWorker } from "../types/filterFields";
import { IOnboardingOptions } from "../types/onboarding";

export const getFilterFields = async (type: string): Promise<IFilterFields> => {
  const jsonData = await fetch(
    type === "families"
      ? "filter_fields_families.json"
      : "filter_fields_workers.json"
  );
  const object = await jsonData.json();
  return object;
};

export const getFieldKeys = async (type: string): Promise<{}> => {
  const jsonData = await fetch(
    type === "families"
      ? "filter_fields_families.json"
      : "filter_fields_workers.json"
  );
  const object: IFilterFields = await jsonData.json();

  return object;
};

export const getOnboardingOptions = async (): Promise<IOnboardingOptions> => {
  const jsonData = await fetch("onboarding.json");
  const object = await jsonData.json();
  return object;
};

export function isJson(str: string): boolean {
  try {
    const x = JSON.parse(str);
    if (!x) return false;
    if (typeof x !== "object") return false;
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * @jsdoc parseJson
 * @summary parse broken json until gets an object
 * @param str
 * @returns
 */
export function parseJson(str: string): any {
  try {
    let x = JSON.parse(str);
    if (!x) return str;
    while (typeof x === "string") {
      x = JSON.parse(x);
    }
    return x;
  } catch (e) {
    return str;
  }
}
