import { StyledWorkerCard } from "./StyledWorkerCard";
import { IWorker } from "../../../../types/filterFields";
import WorkerCardHeader from "./components/WorkerCardHeader";
import Button from "../Button";
import { workerDetails } from "./helpers/workerDetails";
import { useDispatch, useSelector } from "react-redux";
import WorkerDescription from "./components/WorkerDescription";
import {
  addSelectedWorker,
  removeSelectedWorker,
  setDetailedWorker,
} from "../../../../store/slices/vetrina";
import { RootState } from "../../../../store/store";
import { MAX_VETRINA_SELECT } from "../../../../constants/vetrina/Other";
import LocationBox from "./components/LocationBox";
import { app } from "../../../../lib/firebase/config";

interface IWorkerCard {
  worker: IWorker;
}

const cardFields = [
  "tipo_lavoro",
  "tipo_rapporto",
  "comune",
  "provincia",
  "codice_postale",
  "genere",
  "data_di_nascita",
  "descrizione_personale_testo_libero",
  "edit_date",
];

const WorkerCard = ({ worker }: IWorkerCard) => {
  const dispatchRedux = useDispatch<any>();

  const user = useSelector((state: RootState) => state.vetrina.user);
  const selectedWorkers = useSelector(
    (state: RootState) => state.vetrina.selectedWorkers
  );

  const handleContact = (worker: IWorker) => {
    if (selectedWorkers?.includes(worker)) {
      dispatchRedux(removeSelectedWorker({ worker, user }));
    } else {
      dispatchRedux(addSelectedWorker({ worker, user }));
    }
  };

  const handleDetailView = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      // !(e.target as HTMLDivElement).className.includes("read-more-btn") &&
      !(e.target as HTMLDivElement).className.includes("disp-btn")
    ) {
      dispatchRedux(setDetailedWorker(worker));
    }
  };

  return (
    <StyledWorkerCard onClick={handleDetailView}>
      <WorkerCardHeader worker={worker} />
      <div className="details" id={`worker-card-${worker.codice_fiscale}`}>
        {workerDetails(worker).map((detail, i) => {
          return <p key={i}>{detail}</p>;
        })}
      </div>
      <Button
        onClick={() => handleContact(worker)}
        className={[
          "disp-btn",
          selectedWorkers?.some(
            (w) => w.codice_fiscale === worker.codice_fiscale
          )
            ? "selected"
            : "",
          selectedWorkers?.length === MAX_VETRINA_SELECT &&
          !selectedWorkers?.some(
            (w) => w.codice_fiscale === worker.codice_fiscale
          )
            ? "disabled"
            : "",
        ].join(" ")}
      >
        {selectedWorkers?.some(
          (w) => w.codice_fiscale === worker.codice_fiscale
        )
          ? "Rimuovi dalla "
          : "Aggiungi alla "}
        tua Selezione
      </Button>
      <Button
        role="secondary"
        className="show-more-btn"
        onClick={handleDetailView}
      >
        Scopri di Più
      </Button>
    </StyledWorkerCard>
  );
};

export default WorkerCard;
