export const accordionStyles = {
  container: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginBottom: "10px",
    overflow: "hidden",
  },
  button: {
    backgroundColor: "#f9f9f9ff",
    color: "#333",
    padding: "10px 15px",
    width: "100%",
    "text-align": "left",
    border: "none",
    borderBottom: "1px solid #ccc",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
  },
  content: {
    padding: "10px 15px",
    backgroundColor: "#fff",
    borderTop: "1px solid #ccc",
    display: "flex",
    "flex-direction": "column",
    gridGap: "8px",
  },
  contentHidden: {
    display: "none",
  },
  chevron: {
    display: "inline-block",
    width: "7px",
    height: "7px",
    marginLeft: "10px",
    borderTop: "2px solid #000",
    borderRight: "2px solid #000",
    transform: "rotate(45deg)",
    transition: "transform 0.3s ease",
    opacity: 0.5,
  },
  chevronWrapper: {
    display: "inline-block",
    marginLeft: "auto",
  },
};
