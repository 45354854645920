import React, { useEffect, useMemo, useState } from "react";
import { StyledSearchBar } from "./StyledSearchBar";
import xIcon from "../../../assets/x-exit.png";
import searchIcon from "../../../assets/search.png";

interface ISearchBar {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  onCancel?: () => void;
}

const SearchBar = ({ onChange, className, onCancel }: ISearchBar) => {
  const [searchText, setSearchText] = useState<string>("");

  const randomId = useMemo(() => Math.random().toString(36).substr(2, 9), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    onChange(e);
  };

  return (
    <StyledSearchBar htmlFor={`search-${randomId}`} className={className}>
      <img src={searchIcon} alt="" className="search-icon" />
      <input
        id={`search-${randomId}`}
        type="text"
        placeholder="Search..."
        onChange={handleChange}
        value={searchText}
      />
      {searchText.length > 0 ? (
        <img
          src={xIcon}
          alt=""
          className="close-search-icon"
          onClick={() => {
            setSearchText("");
            if (onCancel) {
              onCancel();
            }
            onChange({
              target: { value: "" },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
        />
      ) : null}
    </StyledSearchBar>
  );
};

export default SearchBar;
