import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { app } from "../../lib/firebase/config";
import { getAuth } from "firebase/auth";

export const checkSession = async ({
  codiceFiscale,
  email,
}: {
  codiceFiscale?: string;
  email?: string;
}) => {
  const auth = getAuth(app);
  let cf = codiceFiscale;
  const db = getFirestore(app);

  let haveSession = false;

  let user;

  if (!cf && email) {
    const externalUsers = collection(db, "external_users");
    const internalUsers = collection(db, "internal_users");
    const q_ex = query(externalUsers, where("email", "==", email));
    const q_in = query(internalUsers, where("email", "==", email));
    const externalUser = await getDocs(q_ex);
    const internalUser = await getDocs(q_in);

    user = !externalUser.empty
      ? externalUser.docs[0].data()
      : internalUser.docs[0].data();

    cf = user.codice_fiscale;
  }

  if (!cf) return { haveSession: false };

  if (document.cookie.includes("sessionId")) {
    const sessionId = getCookie("sessionId");
    const docRef = doc(db, "sessions", cf);

    const docSnap = await getDoc(docRef);

    // const docSnap = await getDocs(collection(db, "sessions"));

    const sessions = docSnap.data()?.sessions;
    if (!sessions.includes(sessionId)) {
      auth.signOut();
      document.cookie = `sessionId=; path/; expires=${new Date().toUTCString()}`;

      if (docSnap.data()?.sessions.length === 1) {
        await updateDoc(docRef, {
          sessions: [],
        });
      } else {
        await setDoc(docRef, {
          sessions: [],
        });
      }
    } else {
      haveSession = true;
    }
  }

  return haveSession;
};

export const saveSession = async ({
  codiceFiscale,
  email,
}: {
  codiceFiscale?: string;
  email?: string;
}) => {
  try {
    const auth = getAuth(app);
    let cf = codiceFiscale;
    const db = getFirestore(app);
    const { v4: uuidv4 } = require("uuid");
    const sessionId = uuidv4();

    if (!cf && email) {
      const externalUsers = collection(db, "external_users");
      const internalUsers = collection(db, "internal_users");
      const q_ex = query(externalUsers, where("email", "==", email));
      const q_in = query(internalUsers, where("email", "==", email));
      const externalUser = await getDocs(q_ex);
      const internalUser = await getDocs(q_in);

      const user = !externalUser.empty
        ? externalUser.docs[0].data()
        : internalUser.docs[0].data();

      cf = user.codice_fiscale;
    }

    if (!cf) return;

    const docRef = doc(db, "sessions", cf);
    const docSnap = await getDocs(collection(db, "sessions"));

    if (document.cookie.includes("sessionId")) {
      const sessionId = getCookie("sessionId");

      const sessions = docSnap.docs[0].data().sessions;

      if (!sessions.includes(sessionId)) {
        auth.signOut();
        document.cookie = `sessionId=; path/; expires=${new Date().toUTCString()}`;

        if (docSnap.docs[0].data().sessions.length === 1) {
          await updateDoc(docRef, {
            sessions: [],
          });
        } else {
          await setDoc(docRef, {
            sessions: [],
          });
        }
      }
    }

    // if (docSnap.docs[0].data().sessions.length === 1) {
    //   await updateDoc(docRef, {
    //     sessions: arrayUnion(sessionId),
    //   });
    // } else {
    await setDoc(docRef, {
      sessions: arrayUnion(sessionId),
    });
    // }

    document.cookie = `sessionId=; path/; expires=${new Date().toUTCString()}`;
    document.cookie = `sessionId=${sessionId}; path=/`;
  } catch (error: any) {
    console.log(error);
    throw new Error(error);
  }
};

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
}
