import React from "react";
import { StyledWorkerTag } from "./StyledWorkerTag";
import { WorkerHomeWorkType } from "../../../../../../types/vetrina";

interface IWorkerTag {
  tag: WorkerHomeWorkType;
}

const WorkerTag = ({ tag }: IWorkerTag) => {
  return <StyledWorkerTag $tag={tag}>{tag}</StyledWorkerTag>;
};

export default WorkerTag;
