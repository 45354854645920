import React, { useEffect, useState } from "react";
import { StyledDropdownList } from "./StyledDropdownList";

interface IDropdownListElement {
  title: string;
  description: string;
  isLast: boolean;
}

const DropdownListElement = ({
  title,
  description,
  isLast,
}: IDropdownListElement) => {
  return (
    <StyledDropdownList $isLast={isLast}>
      <p className="title">- {title}</p>
      <p className="description">{description ?? "-- Nessuna Risposta --"}</p>
    </StyledDropdownList>
  );
};

export default DropdownListElement;
