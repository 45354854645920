import React from "react";
import { StyledWorkerDetail } from "./StyledWorkerDetail";
import DesktopWorkerDetail from "./components/DesktopWorkerDetail";
import MobileWorkerDetail from "./components/MobileWorkerDetail";
import { useDispatch, useSelector } from "react-redux";
import { setDetailedWorker } from "../../../../store/slices/vetrina";
import BottomButtons from "./components/BottomButtons";
import { useWindowSize } from "../../../../utils/hooks/useResponsive";
import { RootState } from "../../../../store/store";

const sidebar_details = [
  "condizioni_accettabili.badante",
  "condizioni_accettabili.colf",
  "condizioni_accettabili.baby_sitter",
  "hard_skills.badante",
  "hard_skills.colf",
  "hard_skills.baby_sitter",
  "conoscenza_italiano",
  "lingue_parlate",
  "qualifica_professionale",
  "descrizione_personale_testo_libero",
  "experiences.values[$].esperienza_come",
  "experiences.values[$].inizio_esperienza",
  "experiences.values[$].fine_esperienza",
  "experiences.values[$].modalita_lavoro_esperienza",
  "experiences.values[$].mansioni",
  "patente",
  "automunito",
  "data_di_nascita",
  "nazionalita",
  "cittadinanza",
];

const WorkerDetail = () => {
  const { isDesktop } = useWindowSize();
  const dispatchRedux = useDispatch();

  /**
   * Non serve che gli passo nessun worker perché lo prendo dallo store
   */

  const handleClose = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLDivElement) {
      if (!e.target.classList.contains("worker-detail")) {
        dispatchRedux(setDetailedWorker(null));
      }
    }
  };

  return (
    <StyledWorkerDetail onClick={handleClose}>
      {isDesktop ? <DesktopWorkerDetail /> : <MobileWorkerDetail />}
      <BottomButtons />
    </StyledWorkerDetail>
  );
};

export default WorkerDetail;
