import styled from "styled-components";
import { MAIN_COLORS } from "../../../../constants/vetrina/Colors";

export const StyledWorkerDetail = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${MAIN_COLORS.gray900}CC;
  position: fixed;
  z-index: 500;
  top: 0;
  animation: fadeIn 0.2s;
  scrollbar-width: 0;

  ::-webkit-scrollbar {
    display: none;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
