import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import { isAcceptableStepFormText } from "../functionsSteps";

import { TypeForm } from "../../../components/TypeForm/index";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import { NATIONALITIES } from "../../../constants/countries/countriesNationalities";
import { isStepValid } from "../../../utils/onboarding";
import Dropdown from "../../../components/atoms/Dropdown/Dropdown";

const Nationality = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const { cittadinanza } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    if (isAcceptableStepFormText(cittadinanza)) {
      onNext();
    } else {
      setErrorMessage("Inserisci una nazionalità valida");
    }
  };

  const handleClickPreviousButton = () => {
    // onInputChange("cittadinanza", []);

    onPrevious();
  };

  const handleSelectChoiceClick = (text) => {
    onInputChange("cittadinanza", text);
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>

          <div className="div">
            <Dropdown
              defaultValue={formData["cittadinanza"] ?? "Seleziona un opzione"}
              options={NATIONALITIES}
              onClick={handleSelectChoiceClick}
              placeholder="Seleziona una cittadinanza"
            />
            {/* <div className="selection-element-horrizonatal">
              {NATIONALITIES.map((item) => (
                <SelectChoice
                  isClickedProp={item === formData["nationality"]}
                  key={item}
                  text={item}
                  onClick={handleSelectChoiceClick}
                />
              ))}
            </div> */}

            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={`button-instance ${
                  !isStepValid({
                    mandatoryFields: "cittadinanza",
                    formData: {
                      nationality: formData.cittadinanza,
                    },
                  })
                    ? "button-disabled"
                    : ""
                }`}
                property1="primary"
                onClick={handleClickNextButton}
              >
                Avanti
              </Button>
              <Button
                className="button-instance"
                property1="secondary"
                onClick={handleClickPreviousButton}
              >
                Indietro
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nationality;
