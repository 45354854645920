import styled from "styled-components";
import { MAIN_COLORS } from "../../../../../../constants/vetrina/Colors";
import { MOBILE_BREAKPOINT } from "../../../../../../constants/vetrina/Dimens";

export const StyledBottomButtons = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: ${MAIN_COLORS.white};
  padding: 1.5rem 1rem;
  width: max(40%, 35rem);
  border-top: 1px solid ${MAIN_COLORS.gray100};
  animation: translateLeft 0.3s;

  .selected {
    background-color: ${MAIN_COLORS.green500};
  }

  .disabled {
    pointer-events: none;
    background-color: ${MAIN_COLORS.gray100};
    transform: scale(1);
    z-index: 10;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    animation: slideUp 0.3s;
  }

  display: grid;
  grid-gap: 1rem;

  [class$="-btn"] {
    display: flex;
    justify-content: center;
  }

  .prox-btn {
    width: 100%;
  }

  .disp-btn {
    width: 100%;

    &-arrow {
      margin-left: 0.5rem;
    }
  }

  @keyframes translateLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;
