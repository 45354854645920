import React from "react";
import { StyledBoard } from "./StyledBoard";
import Card, { ICard } from "../Card/Card";
import { useDroppable } from "@dnd-kit/core";

interface IBoard {
  label: string;
  boardKey: string;
  cards: ICard[];
  color: string;
}

const Board = ({ label, boardKey, cards, color }: IBoard) => {
  const { isOver, setNodeRef } = useDroppable({
    id: boardKey,
  });

  return (
    <StyledBoard $isOver={isOver} $color={color}>
      <div className="board-header">
        <h3 className="board-name">{label}</h3>
        <span className="color-dot"></span>
      </div>
      <div className="board-body" ref={setNodeRef}>
        {cards?.map((card: any, cardKey: number) => {
          let cardBoard = +card.board || 0;

          if (cardBoard !== +boardKey) return null;

          return (
            <Card
              color={color}
              card={card}
              key={cardKey}
              ckey={`${card["codice_fiscale"]}`}
            />
          );
        })}
      </div>
    </StyledBoard>
  );
};

export default Board;
