import styled from "styled-components";

interface IStyledKanban {
  $boardsCount: number;
}

export const StyledKanban = styled.main<IStyledKanban>`
  user-select: none;

  .content {
    margin-bottom: 48px;
    min-height: calc(100vh - 40px);
    overflow-y: unset;
    margin-right: 16px;
    max-width: unset;
    width: fit-content;

    .space-selectors-wrapper {
      display: flex;
      grid-gap: 8px;
      margin-bottom: 24px;

      .space-selector {
        padding: 0.8rem 1.4rem;
        border: none;
        color: var(--white);
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        margin-right: 1%;
        font-size: 0.9rem;
        font-weight: bold;
        width: fit-content;
        transition: none;
        background-color: transparent;
        color: var(--dark-grey);
        border: 1.5px solid var(--grey);
        transition: background-color 0.3s;

        &:hover {
          background-color: #fafafa80;
        }

        &_selected {
          background-color: var(--blue);
          color: #fafafa;
          border: 1.5px solid var(--blue);

          &:hover {
            background-color: var(--blue);
          }
        }
      }
    }
    width: ${({ $boardsCount }) => $boardsCount};
    .boards-wrapper {
      display: grid;
      grid-template-columns: repeat(
        ${({ $boardsCount }) => $boardsCount},
        minmax(400px, 1fr)
      );
      grid-gap: 16px;
    }
  }

  .main-section {
    display: grid;
    grid-template-columns: 330px 200px 150px 125px;
    margin-bottom: 24px;

    .search-bar {
      height: 100%;
    }

    .provincia-dropdown {
      margin-left: 8px;
    }

    .timeframe-wrapper {
      display: flex;
      grid-gap: 8px;
      align-items: center;
      margin-left: 16px;
      margin-right: 16px;

      .timeframe {
        display: flex;
        grid-gap: 2px;
        flex-direction: column;

        &__label {
          font-size: 0.7rem;
          color: #a5a5a5;
          font-weight: bold;
        }

        &__value {
          display: flex;
          font-size: 0.9rem;
          font-weight: bold;
          color: #6e6e6e;
          align-items: center;

          input {
            margin-inline: 8px;
            width: 35px;
            text-align: center;
            font-size: 0.8rem;
            border: 1.5px solid #e0e0e0;
            display: flex;
            align-items: center;
            padding: 3px 4px;
            border-radius: 8px;
            background-color: #ffffff;
            cursor: text;
          }
        }
      }
    }

    .filters-switch-wrapper {
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        font-family: inherit;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: 1.5px solid #e0e0e0;
        cursor: pointer;
        padding-inline: 10px;

        img {
          margin-right: 8px;
          width: 18px;
          height: 18px;
        }

        .close-filters-img {
          width: 14px;
          height: 14px;
          filter: brightness(0);
          margin-left: auto;
        }
      }
    }

    .search-btn {
      margin-left: 0.5rem;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      font-family: inherit;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: 1.5px solid var(--blue);
      background-color: var(--blue);
      color: white;
      cursor: pointer;
      padding-inline: 10px;
    }
  }
`;
