import styled from "styled-components";
import { MAIN_COLORS } from "../../../../constants/vetrina/Colors";
import {
  DIMENS,
  MOBILE_BREAKPOINT,
} from "../../../../constants/vetrina/Dimens";

export const StyledWorkerCard = styled.div`
  border: 1px solid ${MAIN_COLORS.gray100};
  background-color: ${MAIN_COLORS.white};
  border-radius: ${DIMENS.borderRadius1};
  padding: 1rem 1rem 1.5rem 1rem;
  cursor: pointer;

  .selected {
    background-color: ${MAIN_COLORS.yellow500};
  }

  .disabled {
    pointer-events: none;
    background-color: ${MAIN_COLORS.gray100};
    transform: scale(1);
    z-index: 10;
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    margin-block: 1.5rem;
    font-family: "Inter", sans-serif;
    line-height: 1.2rem;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }

  .disp-btn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
    padding: 1rem;

    &-arrow {
      height: 100%;
      margin-left: 1rem;
    }
  }

  .show-more-btn {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    justify-content: center;
  }
`;
