import { WorkerHomeWorkType } from "../../types/vetrina";

export const MAIN_COLORS = {
  blue50: "#F7F9FF",
  blue600: "#0047A9",
  black: "#000624",
  gray100: "#E6E6E6",
  white: "#FFFFFF",
  yellow500: "#FFA436",
  gray900: "#333333",
  green500: "#4A994A",
};

export const workerTagColors = (tag: WorkerHomeWorkType) => {
  switch (tag) {
    case WorkerHomeWorkType.BADANTE:
      return MAIN_COLORS.blue600;

    case WorkerHomeWorkType.COLF:
      return MAIN_COLORS.yellow500;

    case WorkerHomeWorkType.BADANTE_COLF:
      return MAIN_COLORS.blue600;

    case WorkerHomeWorkType.BADANTE_COLF_CONVIVENTE:
      return MAIN_COLORS.yellow500;

    case WorkerHomeWorkType.BABYSITTER:
      return MAIN_COLORS.green500;

    case WorkerHomeWorkType.PULIZIE:
      return MAIN_COLORS.yellow500;

    case WorkerHomeWorkType.ASSISTENZA_DOMICILIARE:
      return MAIN_COLORS.blue600;

    case WorkerHomeWorkType.ASSISTENZA_DOMICILIARE_BADANTE:
      return MAIN_COLORS.yellow500;

    default:
      return MAIN_COLORS.blue600;
  }
};
