import styled from "styled-components";
import { MAIN_COLORS } from "../../../../constants/vetrina/Colors";
import { MOBILE_BREAKPOINT } from "../../../../constants/vetrina/Dimens";

export const StyledNavigationBar = styled.nav`
  height: 4.5rem;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  background-color: ${MAIN_COLORS.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${MAIN_COLORS.gray100};
  z-index: 50;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0.5rem 1rem;
  }

  .logo {
    height: 80%;
    width: fit-content;
    margin-bottom: 12px;
    max-width: 10rem;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      height: 35%;
    }
  }

  .wrapper-buttons {
    display: flex;
    grid-gap: 1rem;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      grid-gap: 0.5rem;

      .btn-selection {
        /* display: none; */

        font-size: 0.8rem;
        z-index: 60;
      }
    }

    .btn-help {
      .text-wrapper {
        margin: none;

        @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
          display: none;
        }
      }

      .icon {
        height: 1rem;
        width: 1rem;

        @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
          display: none;
        }
      }

      @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        font-size: 0.9rem;
      }
    }
  }
`;
