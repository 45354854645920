import React, { useEffect, useState } from "react";
import { StyledWorkerDescription } from "./StyledWorkerDescription";
import { useWindowSize } from "../../../../../../utils/hooks/useResponsive";
import { IWorker } from "../../../../../../types/filterFields";

interface IWorkerDescription {
  worker: IWorker;
}

const DEFAULT_MAX_DESC_LENGTH = 150;

const WorkerDescription = ({ worker }: IWorkerDescription) => {
  const [expanded, setExpanded] = useState(false);
  const [maxDescLength, setMaxDescLength] = useState<number>(
    DEFAULT_MAX_DESC_LENGTH
  );
  const { isMobile, isDesktop } = useWindowSize();

  const toggleExpansion = () => {
    setExpanded((p) => !p);
  };

  useEffect(() => {
    if (isMobile) {
      setMaxDescLength(75);
    } else if (isDesktop) {
      setMaxDescLength(150);
    }
  }, [isMobile]);

  if (!worker.descrizione) return <></>;

  return (
    <StyledWorkerDescription>
      <p className="description">
        {expanded
          ? worker.descrizione
          : `${worker.descrizione.slice(0, maxDescLength)}${
              worker.descrizione.length > maxDescLength ? "..." : ""
            }`}
        {worker.descrizione.length > maxDescLength ? (
          <button className="read-more-btn" onClick={toggleExpansion}>
            {expanded ? "Leggi di meno" : "Leggi di più"}
          </button>
        ) : null}
      </p>
    </StyledWorkerDescription>
  );
};

export default WorkerDescription;
