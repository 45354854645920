export enum UserTypes {
  ADMIN = "admin",
  DATORE = "datore",
  LAVORATORE = "lavoratore",
  OPERATORE = "operatore",
}

export enum UserCollections {
  INTERNAL = "internal_users",
  EXTERNAL = "external_users",
}
