import { useDispatch, useSelector } from "react-redux";
import { StyledVetrina } from "./StyledVetrina";
import { RootState } from "../../store/store";
import { useEffect, useState } from "react";
import { getWorkers } from "../../store/thunks/vetrina";
import WorkerCard from "./components/WorkerCard";
import WorkerDetail from "./components/WorkerDetail/WorkerDetail";
import Button from "./components/Button";
import { useLocation } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Notification from "./components/Notification";
import {
  loadMoreWorkers,
  registerUserToSession,
  setSelection,
} from "../../store/slices/vetrina";
import { ProvinciaItalia } from "../../constants/provincie";
import Dropdown from "../../components/atoms/Dropdown/Dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AVAILABLE_PROVINCIE,
  MAX_VETRINA_SELECT,
} from "../../constants/vetrina/Other";
import { HOME_WORK_TYPES, WorkType } from "../../constants/onboarding";
import { getAuth } from "firebase/auth";
import { app } from "../../lib/firebase/config";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { IFamily } from "../../types/filterFields";
import { checkSession } from "../../utils/auth/session";

const Vetrina = () => {
  const dispatchRedux = useDispatch<any>();
  const location = useLocation();
  const pagination = useSelector(
    (state: RootState) => state.vetrina.paginatedWorkers
  );
  const {
    workers,
    detailedWorker,
    totalWorkersCount,
    user: vetrinaUser,
  } = useSelector((state: RootState) => state.vetrina);

  const queryParams = new URLSearchParams(location.search);

  const tipo_lavoro_domestico = queryParams.get("tipo_lavoro_domestico");
  const tipo_di_rapporto = queryParams.get("tipo_di_rapporto");
  const provincia = queryParams.get("provincia");

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const db = getFirestore(app);
        const docRef = collection(db, "external_users");
        const q = query(docRef, where("email", "==", user.email));
        const querySnapshot = (await getDocs(q)) as any;

        const haveSession = await checkSession({
          codiceFiscale: querySnapshot.docs[0].data().codice_fiscale,
        });

        if (!haveSession) {
          console.log("no session found");
          navigate(
            `/sign-in?from=${encodeURIComponent(window.location.pathname)}`
          );
        } else {
          dispatchRedux(registerUserToSession(querySnapshot.docs[0].data()));
          dispatchRedux(
            setSelection(querySnapshot.docs[0].data().selection ?? [])
          );
        }
      } else {
        console.log("no user found");
        navigate(
          `/sign-in?from=${encodeURIComponent(window.location.pathname)}`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowMore = () => {
    dispatchRedux(loadMoreWorkers(null));
    dispatchRedux(
      getWorkers({
        lastDocId: workers[workers.length - 1]?.codice_fiscale,
        count: pagination,
        tipoLavoroDomestico: tipo_lavoro_domestico?.split(",") ?? [],
        tipoDiRapporto: tipo_di_rapporto?.split(",") ?? [],
        provincia: provincia as ProvinciaItalia,
      })
    );
  };

  const handleSearchFilters = () => {
    dispatchRedux(
      getWorkers({
        lastDocId: workers[workers.length - 1]?.codice_fiscale,
        count: pagination,
        tipoLavoroDomestico: tipo_lavoro_domestico?.split(",") ?? [],
        tipoDiRapporto: tipo_di_rapporto?.split(",") ?? [],
        provincia: provincia as ProvinciaItalia,
      })
    );
  };

  useEffect(() => {
    const curWorkersCount = workers.length;
    const lastDocId = workers[curWorkersCount - 1]?.codice_fiscale;

    if (workers.length > 0) return;

    dispatchRedux(
      getWorkers({
        lastDocId,
        count: pagination,
        tipoLavoroDomestico: tipo_lavoro_domestico?.split(",") ?? [],
        tipoDiRapporto: tipo_di_rapporto?.split(",") ?? [],
        provincia: provincia as ProvinciaItalia,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, tipo_di_rapporto, tipo_lavoro_domestico]);

  const updateSearchParams = (paramName: string, value: string | string[]) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (!value.length) {
      newSearchParams.delete(paramName);
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    } else {
      newSearchParams.set(
        paramName,
        Array.isArray(value) ? value.join(",") : value
      );
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    }
  };

  return (
    <>
      <NavigationBar />
      <StyledVetrina>
        <div className="inner-vetrina">
          <div className="inner-content">
            <div className="list-header">
              <h2 className="heading">
                Scegli 4 lavoratori affidabili tra i candidati proposti, sblocca
                i loro contatti e organizza una prova
              </h2>
              <p className="subheading">
                Aggiungi alla selezione {MAX_VETRINA_SELECT} profili che vuoi
                contattare
              </p>
              <div className="filters-wrapper">
                <div className="dropdowns">
                  <Dropdown
                    className="dropdown"
                    defaultValue={decodeURIComponent(provincia ?? "")}
                    label="Provincia"
                    options={AVAILABLE_PROVINCIE}
                    onClick={(v) => updateSearchParams("provincia", v)}
                  />
                  <Dropdown
                    className="dropdown"
                    defaultValue={decodeURIComponent(
                      tipo_di_rapporto ?? ""
                    ).split(",")}
                    label="Tipo di rapporto"
                    multiple
                    options={HOME_WORK_TYPES}
                    onClick={(v) => updateSearchParams("tipo_di_rapporto", v)}
                  />
                  <Dropdown
                    className="dropdown"
                    multiple
                    label="Tipo di lavoro"
                    defaultValue={decodeURIComponent(
                      tipo_lavoro_domestico ?? ""
                    ).split(",")}
                    options={Object.values(WorkType)}
                    onClick={(v) =>
                      updateSearchParams("tipo_lavoro_domestico", v)
                    }
                  />
                </div>
                <Button
                  className="search-btn"
                  children={"Cerca"}
                  onClick={function (): void {
                    handleSearchFilters();
                  }}
                ></Button>
              </div>
            </div>
            <ul className="workers-list">
              {workers.length > 0 ? (
                workers.map((worker, i) => {
                  return (
                    <li key={i}>
                      <WorkerCard worker={worker} />
                    </li>
                  );
                })
              ) : (
                <p className="no-results-text">
                  Non ci sono lavoratori per la tua selezione
                </p>
              )}
            </ul>
            {workers.length > 0 ? (
              totalWorkersCount > workers.length ? (
                <Button
                  role="secondary"
                  className="load-more-btn"
                  onClick={handleShowMore}
                >
                  Visualizza altri 5 lavoratori
                </Button>
              ) : null
            ) : null}
          </div>
          {detailedWorker ? <WorkerDetail /> : null}
        </div>
        <Notification />
      </StyledVetrina>
    </>
  );
};

export default Vetrina;
