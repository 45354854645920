import styled from "styled-components";

export const StyledPreCheckout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .modal {
    width: min(80vw, 40rem);
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
    padding-inline: 3rem;
    transform: translateY(2.5rem);

    .card-highlight {
      background-color: white;
      border: 1px solid #e5e5e5;
      border-radius: 1rem;
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: fit-content;
      margin-top: -5rem;
      box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.015);
      overflow: hidden;
      margin-bottom: 2rem;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.03);
        transform: scale(1.01);
      }

      img {
        width: 80%;
        object-fit: cover;
        margin-top: -1rem;
      }

      h3 {
        margin-bottom: 0rem;
        font-weight: 900;
        font-size: 2.9rem;
        width: 100%;
        text-align: center;
        margin-top: 0rem;
      }

      p {
        margin-top: 0;
        font-size: 0.9rem;
        font-weight: 600;
        opacity: 0.3;
      }
    }

    .help-text {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .buttons-wrapper {
    display: flex;
    grid-gap: 1rem;
    align-items: center;

    .paypal-button {
      display: flex;
      align-items: center;
      border-radius: 0.5rem;
      overflow: hidden;
    }
  }
`;
