import React, { useEffect } from "react";
import { StyledModalFilters } from "./StyledModalFilters";
import { RootState } from "../../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  IField,
  ITypeChoiceFilterFields,
} from "../../../../../../../types/filterFields";
import {
  addFilter,
  removeFilter,
  removeQueryFilter,
  setModalOpened,
} from "../../../../../../../store/slices/databaseTable";

const ModalFilters = () => {
  const availableFilters = useSelector(
    (state: RootState) => state.databaseTable.availableFilters
  );
  const selectedFilters = useSelector(
    (state: RootState) => state.databaseTable.selectedFilters
  );

  const dispatchRedux = useDispatch();

  const handleClick = (filter: IField, isSelected: boolean) => {
    if (isSelected) {
      dispatchRedux(removeFilter(filter));
      dispatchRedux(removeQueryFilter({ [filter.key]: "" }));
    } else {
      dispatchRedux(addFilter(filter));
    }
  };

  useEffect(() => {
    const exitModal = () =>
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          dispatchRedux(setModalOpened(false));
        }
      });

    exitModal();

    return () => {
      document.removeEventListener("keydown", exitModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledModalFilters>
      <div className="modal-filters">
        <h1 className="title-modal">Filtri</h1>
        <p className="subtitle-modal">
          Scegli i filtri da applicare alla ricerca
        </p>
        <div className="available-filters-wrapper">
          {Object.values(availableFilters).length > 0 &&
            Object.keys(availableFilters).map((key, i) => {
              const isSelected = selectedFilters.hasOwnProperty(key);

              const extractedField = (
                availableFilters as ITypeChoiceFilterFields
              )[key] as IField;

              if (extractedField && extractedField.choices) {
                return (
                  <p
                    onClick={() => handleClick(extractedField, isSelected)}
                    key={i}
                    className={`filter ${isSelected ? "filter__selected" : ""}`}
                  >
                    {extractedField.label}
                  </p>
                );
              }
              return null;
            })}
        </div>
        <button
          onClick={() => dispatchRedux(setModalOpened(false))}
          className="btn-dismiss"
        >
          Close
        </button>
      </div>
    </StyledModalFilters>
  );
};

export default ModalFilters;
