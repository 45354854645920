import React from 'react';
import './style.css';

const ErrorMessageInForm = ( {
  headline = "Errore:",
  message = "Paragraph text",
  paragraphIsVisible = true,
  className="banner-instance",
}  ) => {
  if (message===""){
    return <></>

  }else{
    return (
      <div className={`banner ${className}`}>
        <div className="div">
          <div className="text-frame">
            <div className="headline-text">{headline}</div>
            {paragraphIsVisible && <div className="paragraph-text">{message}</div>}
          </div>
        </div>
      </div>
    );  
   
  }
  
};

export default ErrorMessageInForm;
