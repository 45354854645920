/*const stepOneWorkOptionsList = [
  "Addetto alle pulizie",
  "Addetto alla compagnia",
  "Addetto alla stireria",
  "Addetto riassetto camere e servizio prima colazione",
  "Assistente a persone autosufficienti",
  "Assistente a persona non autosufficiente (formato)",
  "Assistente a persona non autosufficiente (non formato)",
  "Assistente a persona non autosuff. formato senza indennità",
  "Assistente a persone non autosufficienti (non formato) con Ind.",
  "Assistente familiare educatore formato",
  "Autista",
  "Baby Sitter per bambini < di 6 anni",
  "Baby Sitter per bambini > di 6 anni",
  "Cameriere",
  "Collaboratore generico polifunzionale",
  "Cuoco",
  "Custode di abitazione privata",
  "Direttore di casa",
  "Giardiniere",
  "Governante",
  "Istitutore",
  "Maggiordomo",
  "Operaio qualificato",
];*/

const stepOneWorkOptionsList = ["Colf", "Badante", "Babysitter"];
const stepOneWorkOptionsListInOther = [
  "Governante",
  "Autista",
  "Direttore di casa",
  "Maggiordomo",
  "Cuoco",
  "Giardiniere",
];

//const stepTwoModalityWork = ["Non convivente ad ore","Convivente Tempo Parziale","Convivente","Non convivente full time","Assistenza notturna (20.00-08.00)","In Sostituzione per copertura riposi","Presenza notturna (21.00-08.00)"];
//const stepTwoModalityWork = ["Convivente","Non convivente full time","Non convivente ad ore","Assistenza notturna (20.00-08.00)","In Sostituzione per copertura riposi"];
const stepTwoModalityWork = [
  "A ore mattina",
  "A ore pomeriggio",
  "A ore tutto il giorno",
  "Convivente tempo pieno (54 ore)",
  "Convivente tempo parziale",
  "Notturno",
  "Fine settimana o sostituzione riposi",
];

const stepThreeCertification = [
  "OSS",
  "ASA",
  "CERTIFICAZIONE UNI 11766 Patente di qualità",
  "No, non ce l'ho",
  "Altro",
];

const stepFourDocumentiInRegola = [
  "Ho tutti i documenti in regola",
  "Li sto per ottenere",
];
const stepFiveAcceptableConditions = [
  "Assistere un bambino più piccolo di 1 anno",
  "Lavorare nel weekend e festivi",
  "Convivere con animali domestici",
  "Assistere 1 sola persona anziana",
  "Assistere un uomo",
  "Assistere una donna",
  "Assistere 2 persone anziane",
  "Accettare tutte le condizioni",
];
const stepSixSkillsNotHave = [
  "Cucinare",
  "Fare la spesa",
  "Lavanderia",
  "Guidare",
  "Cura della casa",
  "Cura del giardino",
  "Grosse pulizie",
];
const stepSevenCarAvailability = ["Sono automunito", "NON sono automunito"];
const stepEightDrivingLicence = ["Ho la patente", "Non ho la patente"];
const stepNineGender = ["Uomo", "Donna"];

const stepElevenSpokenLanguage = [
  "Italiano",
  "Inglese",
  "Spagnolo",
  "Francese",
  "Russo",
  "Tedesco",
  "Rumeno",
  "Portoghese",
  "Cinese",
  "Arabo",
  "Hindi",
  "Bengalese",
];
const stepTwelveItalianKnowledge = [
  "Non lo parlo",
  "Capisco e Parlo Poco",
  "Capisco e Parlo Bene",
  "Sono Madrelingua",
];
const stepThirteenNaionalities = [
  "Afghana",
  "Albanese",
  "Algerina",
  "Andorrana",
  "Angolana",
  "Antiguana",
  "Argentina",
  "Armena",
  "Australiana",
  "Austriaca",
  "Azero",
  "Bahamense",
  "Bahreinita",
  "Bangladese",
  "Barbadiana",
  "Bielorussa",
  "Belga",
  "Beliziana",
  "Beninese",
  "Bhutanese",
  "Boliviana",
  "Bosniaca",
  "Batswana",
  "Brasiliana",
  "Britannica",
  "Bruneiana",
  "Bulgaro",
  "Burkinabè",
  "Burmense",
  "Burundese",
  "Cambogiana",
  "Camerunense",
  "Canadese",
  "Cabo Verdiana",
  "Centrafricana",
  "Ciadiana",
  "Cilena",
  "Cinese",
  "Cipriota",
  "Cittadino di Hong Kong",
  "Cittadino di Macao",
  "Cittadino di Taiwan",
  "Colombiana",
  "Comorense",
  "Congolese",
  "Nordcoreana",
  "Coreana",
  "Costaricana",
  "Croata",
  "Cubana",
  "Danese",
  "Dominicana",
  "Dominicense",
  "Egiziana",
  "Emiratina",
  "Equadoregna",
  "Eritrea",
  "Estoniana",
  "Etiope",
  "Fijiana",
  "Filippina",
  "Finlandese",
  "Francese",
  "Gabonense",
  "Gambiana",
  "Georgiana",
  "Tedesca",
  "Ghanese",
  "Gibilterrina",
  "Grenadiana",
  "Guatemalteca",
  "Guineense",
  "Guineana",
  "Guyanese",
  "Haitiana",
  "Olandese",
  "Honduregna",
  "Ungherese",
  "Islandese",
  "Indiana",
  "Indonesiana",
  "Iraniana",
  "Irachena",
  "Irlandese",
  "Israeliana",
  "Italiana",
  "Giamaicana",
  "Giapponese",
  "Giordana",
  "Kazaka",
  "Keniana",
  "Kuwaitiana",
  "Kirghisa",
  "Laotiana",
  "Lettone",
  "Libanese",
  "Liberiana",
  "Libica",
  "Liechtensteinese",
  "Lituana",
  "Lussemburghese",
  "Macedone",
  "Malagasy",
  "Malawiana",
  "Malesiana",
  "Maldiviana",
  "Malinese",
  "Maltese",
  "Mariana",
  "Marocchina",
  "Mauriziana",
  "Mauritana",
  "Messicana",
  "Micronesiana",
  "Moldava",
  "Monegasca",
  "Mongola",
  "Montenegrina",
  "Mozambicana",
  "Namibiana",
  "Nauruana",
  "Nepalese",
  "Neozelandese",
  "Nicaraguense",
  "Nigerina",
  "Nigeriana",
  "Norvegese",
  "Omense",
  "Pachistana",
  "Palauana",
  "Palestinese",
  "Panamense",
  "Papuana",
  "Paraguaiana",
  "Peruviana",
  "Filippina",
  "Polacca",
  "Portoghese",
  "Portoricana",
  "Qatariota",
  "Rumena",
  "Russa",
  "Ruandese",
  "Santa Luciana",
  "Salvatrigna",
  "Samoana",
  "Sammarinese",
  "Santomeana",
  "Arabia Saudita",
  "Senegalese",
  "Serba",
  "Seychelle",
  "Sierraleonese",
  "Singaporiana",
  "Siriana",
  "Slovacca",
  "Slovena",
  "Salomone",
  "Somala",
  "Sudafricana",
  "Sudanese",
  "Surinamese",
  "Svedese",
  "Svizzera",
  "Siriana",
  "Tagika",
  "Tanzaniana",
  "Tailandese",
  "Timorense",
  "Togolese",
  "Tongana",
  "Trinidadiana",
  "Tunisina",
  "Turca",
  "Turkmena",
  "Tuvaluana",
  "Ugandese",
  "Ucraina",
  "Emiratina",
  "Inglese",
  "American",
  "Uruguaiana",
  "Uzbeca",
  "Vanuatuana",
  "Venezuelana",
  "Vietnamita",
  "Virginiana",
  "Yemenita",
  "Zambiana",
  "Zimbabwiana",
];

const stepLevelInquadramento = ["A", "AS", "B", "BS", "C", "CS", "D", "DS"];

export {
  stepOneWorkOptionsList,
  stepOneWorkOptionsListInOther,
  stepTwoModalityWork,
  stepThreeCertification,
  stepFourDocumentiInRegola,
  stepFiveAcceptableConditions,
  stepLevelInquadramento,
  stepSixSkillsNotHave,
  stepSevenCarAvailability,
  stepEightDrivingLicence,
  stepNineGender,
  stepElevenSpokenLanguage,
  stepTwelveItalianKnowledge,
  stepThirteenNaionalities,
};
