import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";
import checkboxFlagged from "../../img/static/checkbox-flagged.svg";
import checkboxUnFlagged from "../../img/static/checkbox-unflagged.svg";
import { Link } from "react-router-dom";

const Checkbox = ({
  isChecked: initialChecked = "checked",
  className,
  onChange,
  page = "/privacy-lavoratore",
}) => {
  const [isChecked, setChecked] = useState(initialChecked === "checked");

  const toggleCheckbox = () => {
    const newCheckedValue = !isChecked;
    setChecked(newCheckedValue);
    // Richiamo la funzione di callback passando il nuovo valore di isChecked al componente padre
    if (onChange) {
      onChange(newCheckedValue);
    }
  };

  return (
    <label className={`label ${className}`}>
      <div className="checkbox" onClick={toggleCheckbox}>
        <img
          className={
            ("img", isChecked ? "img-checkbox__unchecked" : "img-checkbox")
          }
          alt="Combined shape"
          src={isChecked ? checkboxFlagged : checkboxUnFlagged}
        />
      </div>
      <div className="text">
        Ho letto{" "}
        <Link to={page} target="_blank" rel="noopener noreferrer">
          l'informativa privacy
        </Link>{" "}
        e presto i consensi in essa indicati
      </div>
    </label>
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.oneOf(["checked", "unchecked"]),
  onChange: PropTypes.func, // Aggiungo la prop onChange di tipo funzione
};

export default Checkbox;
