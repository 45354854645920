// File: /src/pages/databaseAdmin/model-table/components/Editor/Editor.tsx
// Created Date: Monday January 15th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Monday January 15th 2024 10:48:22 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect } from "react";
import { StyledEditor } from "./StyledEditor";
import { RootState } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  editSelectedValues,
  setIsEditorOpened,
  setLoadingEditing,
} from "../../../../../store/slices/databaseTable";
import Button from "../../../../../components/Button";
import EditField from "../../../../../components/atoms/EditField";
import { editSelected } from "../../../../../store/thunks/databaseTable";
import { useLocation } from "react-router-dom";
import { IFamily, IWorker } from "../../../../../types/filterFields";
import {
  CaretDown,
  CaretUp,
  ShieldChevron,
  TagChevron,
} from "@phosphor-icons/react";
import { ArrowArcLeft } from "@phosphor-icons/react/dist/ssr";

const Editor = () => {
  const [isEsperienzeOpened, setIsEsperienzeOpened] = React.useState(false);
  const [isCondizioniOpened, setIsCondizioniOpened] = React.useState(false);
  const [isHardSkillsOpened, setIsHardSkillsOpened] = React.useState(false);

  const fields = useSelector(
    (state: RootState) => state.databaseTable.fieldKeys
  );
  const isEditorOpened = useSelector(
    (state: RootState) => state.databaseTable.isEditorOpened
  );
  const selected = useSelector(
    (state: RootState) => state.databaseTable.selected
  );
  const isLoadingEditing = useSelector(
    (state: RootState) => state.databaseTable.isLoadingEditing
  );

  const location = useLocation();

  const dr = useDispatch<any>();

  const handleClose = (e: React.MouseEvent) => {
    if (isEditorOpened === false) return;
    if (!e.target) return;
    if (
      !(e.target as HTMLElement).classList.contains("modal") &&
      (e.target as HTMLElement).classList.contains("backdrop")
    ) {
      dr(setIsEditorOpened({ opened: false }));
    }
  };

  useEffect(() => {
    if (isLoadingEditing === true) {
      setTimeout(() => {
        dr(setLoadingEditing(false));
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEditing]);

  const handleSave = () => {
    if (!selected) return;
    dr(
      editSelected(
        location.pathname.includes("database-lavoratori")
          ? "lavoratori"
          : location.pathname.includes("database-clienti")
          ? "clienti"
          : ""
      )
    );
  };

  return (
    <StyledEditor
      $opened={isEditorOpened}
      className="backdrop"
      onClick={handleClose}
      $snackbarShown={isLoadingEditing}
    >
      <div className="modal">
        <div className="exit-wrapper">
          <div className="exit-wrapper__main">
            <p>CF: {(selected as IWorker)?.codice_fiscale ?? "--"}</p>

            <div className="buttons-wrapper">
              <Button
                className="exit-btn"
                onClick={() => dr(setIsEditorOpened(false))}
              >
                Close
              </Button>
              <Button onClick={handleSave} className="save-btn">
                Save
              </Button>
            </div>
          </div>
          <div className="exit-wrapper__bottom">
            <p>Nome: {(selected as IWorker)?.nome ?? "--"}</p>
            <p>Cognome: {(selected as IWorker)?.cognome ?? "--"}</p>
          </div>
        </div>
        <div className="fields">
          {selected &&
            Object.keys(fields).map((field, i) => {
              if (fields[field as any]?.experiences) {
                return (
                  <div key={i}>
                    <div
                      onClick={() => setIsEsperienzeOpened((p) => !p)}
                      className={[
                        "dropdown-editor-bar",
                        isEsperienzeOpened ? "opened" : "",
                      ].join(" ")}
                    >
                      <p>Esperienze</p>
                      {isEsperienzeOpened ? <CaretUp /> : <CaretDown />}
                    </div>
                    {isEsperienzeOpened ? (
                      <div className="fields-list-wrapper">
                        {(selected as any)["experiences"].experiences.map(
                          (exp: any, i1: number) => {
                            return Object.entries(exp.values).map(
                              (
                                [stringKey, value]: [
                                  stringKey: any,
                                  value: any
                                ],
                                i2
                              ) => {
                                if (
                                  fields[field as any].experiences[0].values[
                                    stringKey
                                  ]?.editable
                                ) {
                                  const isMultiple =
                                    fields[field as any].experiences[0].values[
                                      stringKey
                                    ]?.multiple;
                                  return (
                                    <div className="field" key={`${i1}${i2}`}>
                                      <EditField
                                        obj={
                                          fields[field as any].experiences[0]
                                            .values[stringKey]
                                        }
                                        objKey={field}
                                        value={value}
                                        multiple={isMultiple}
                                        onChange={(e) =>
                                          dr(
                                            editSelectedValues({
                                              key: `experiences.experiences.${i1}.values.${stringKey}`,
                                              value: e,
                                            })
                                          )
                                        }
                                        placeholder={
                                          fields[field as any].experiences[0]
                                            .values[stringKey]?.label ??
                                          "Type here..."
                                        }
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="field" key={i}>
                                      <label>
                                        {
                                          fields[field as any].experiences[0]
                                            .values[stringKey]?.label
                                        }
                                      </label>
                                      <p>{value}</p>
                                    </div>
                                  );
                                }
                              }
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              }

              if (field === "condizioni_accettabili") {
                return (
                  <>
                    <div
                      onClick={() => setIsCondizioniOpened((p) => !p)}
                      className={[
                        "dropdown-editor-bar",
                        isCondizioniOpened ? "opened" : "",
                      ].join(" ")}
                    >
                      <p>Condizioni Accettabili</p>
                      {isCondizioniOpened ? <CaretUp /> : <CaretDown />}
                    </div>
                    {isCondizioniOpened ? (
                      <div className="fields-list-wrapper">
                        {Object.entries(fields[field as any]).map(
                          ([stringKey, value]) => {
                            if (value.editable) {
                              return (
                                <div className="field" key={i}>
                                  <EditField
                                    obj={value}
                                    objKey={field}
                                    value={
                                      selected?.[
                                        field as keyof typeof selected
                                      ]?.[stringKey] ?? ""
                                    }
                                    multiple={value.multiple}
                                    onChange={(e) =>
                                      dr(
                                        editSelectedValues({
                                          key: `${field}.${stringKey}`,
                                          value: e,
                                        })
                                      )
                                    }
                                    placeholder={value?.label ?? "Type here..."}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div className="field" key={i}>
                                  <label>{value?.label}</label>
                                  <p>
                                    {
                                      selected?.[
                                        field as keyof typeof selected
                                      ][stringKey as any]
                                    }
                                  </p>
                                </div>
                              );
                            }
                          }
                        )}
                      </div>
                    ) : null}
                  </>
                );
              }

              if (field === "hard_skills") {
                return (
                  <>
                    <div
                      onClick={() => setIsHardSkillsOpened((p) => !p)}
                      className={[
                        "dropdown-editor-bar",
                        isHardSkillsOpened ? "opened" : "",
                      ].join(" ")}
                    >
                      <p>Hard Skills</p>
                      {isHardSkillsOpened ? <CaretUp /> : <CaretDown />}
                    </div>
                    {isHardSkillsOpened ? (
                      <div className="fields-list-wrapper">
                        {Object.entries(fields[field as any]).map(
                          ([stringKey, value]) => {
                            if (value.editable) {
                              return (
                                <div className="field" key={i}>
                                  <EditField
                                    obj={value}
                                    objKey={field}
                                    value={
                                      selected?.[
                                        field as keyof typeof selected
                                      ]?.[stringKey] ?? ""
                                    }
                                    multiple={value.multiple}
                                    onChange={(e) =>
                                      dr(
                                        editSelectedValues({
                                          key: `${field}.${stringKey}`,
                                          value: e,
                                        })
                                      )
                                    }
                                    placeholder={value?.label ?? "Type here..."}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div className="field" key={i}>
                                  <label>{value?.label}</label>
                                  <p>
                                    {
                                      selected?.[
                                        field as keyof typeof selected
                                      ][stringKey as any]
                                    }
                                  </p>
                                </div>
                              );
                            }
                          }
                        )}
                      </div>
                    ) : null}
                  </>
                );
              }

              // if (
              //   fields[field as any]?.badante ||
              //   fields[field as any]?.colf ||
              //   fields[field as any]?.baby_sitter
              // ) {
              //   return Object.entries(fields[field as any]).map(
              //     ([stringKey, value]) => {
              //       if (value.editable) {
              //         return (
              //           <div className="field" key={i}>
              //             <EditField
              //               obj={value}
              //               objKey={field}
              //               value={
              //                 selected?.[field as keyof typeof selected]?.[
              //                   stringKey
              //                 ] ?? ""
              //               }
              //               multiple={value.multiple}
              //               onChange={(e) =>
              //                 dr(
              //                   editSelectedValues({
              //                     key: `${field}.${stringKey}`,
              //                     value: e,
              //                   })
              //                 )
              //               }
              //               placeholder={value?.label ?? "Type here..."}
              //             />
              //           </div>
              //         );
              //       } else {
              //         return (
              //           <div className="field" key={i}>
              //             <label>{value?.label}</label>
              //             <p>
              //               {
              //                 selected?.[field as keyof typeof selected][
              //                   stringKey as any
              //                 ]
              //               }
              //             </p>
              //           </div>
              //         );
              //       }
              //     }
              //   );
              // }

              if (!fields[field as keyof typeof fields]) return null;

              const fieldObj = fields[field as keyof typeof fields];
              if (!fieldObj.label || !fieldObj.key) return null;

              if (field === "selezione") {
                const cfiscali = (selected as any)["selezione"]
                  ?.map((s: any) => JSON.parse(s).codice_fiscale)
                  .join(" - ");
                return (
                  <div className="field" key={i}>
                    <label>Selezione</label>
                    <p>{cfiscali}</p>
                  </div>
                );
              }

              if (field === "purchased_contacts") {
                const cfiscali = (selected as any)["purchased_contacts"]
                  ?.map((s: any) => JSON.parse(s).codice_fiscale)
                  .join(" - ");
                return (
                  <div className="field" key={i}>
                    <label>Contatti Acquistati</label>
                    <p>{cfiscali}</p>
                  </div>
                );
              }

              if (fields[field as keyof typeof fields].editable) {
                return (
                  <div className="field" key={i}>
                    <EditField
                      obj={fields[field as keyof typeof fields]}
                      objKey={field}
                      value={selected[field as keyof typeof selected]}
                      multiple={fields[field as keyof typeof selected].multiple}
                      onChange={(e) =>
                        dr(
                          editSelectedValues({
                            key: field,
                            value: e,
                          })
                        )
                      }
                      placeholder={
                        fields[field as keyof typeof fields]?.label ??
                        "Type here..."
                      }
                    />
                  </div>
                );
              } else {
                return (
                  <div className="field" key={i}>
                    <label>{fields[field as keyof typeof fields]?.label}</label>
                    <p>{selected[field as keyof typeof selected]}</p>
                  </div>
                );
              }
            })}
        </div>
      </div>
      <div className="snack-notification">
        <h3>Modifiche applicate correttamente</h3>
      </div>
    </StyledEditor>
  );
};

export default Editor;
