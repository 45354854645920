import cf from "codice-fiscale-js";
import * as CodiceFiscaleUtils from "@marketto/codice-fiscale-utils";

export function isCodiceFiscale(codice) {
  const regex = /^[A-Z]{6}\d{2}[ABCDEHLMPRST]{1}\d{2}[A-Z]{1}\d{3}[A-Z]{1}$/;
  return regex.test(codice);
}

export function stingIsEmpty(text) {
  if (text.length > 0) {
    return false;
  } else {
    return true;
  }
}

export function isEmail(input) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Espressione regolare per la validazione dell'email
  return emailRegex.test(input);
}

export function isPhoneNumber(input) {
  // Controlla se la stringa inizia con un segno più
  if (input.charAt(0) === "+") {
    input = input.slice(1);
  }

  // Controlla se la stringa contiene solo cifre
  for (let i = 0; i < input.length; i++) {
    if (isNaN(input.charAt(i))) {
      return false;
    }
  }

  // Controlla se la stringa ha la lunghezza corretta
  if (input.length < 10 || input.length > 15) {
    return false;
  }

  // La stringa è valida
  return true;
}

// Function to format the date in "dd/mm/yyyy" format
export const formatDate = (date) => {
  if (!date || !(date instanceof Date)) return "";
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export function hasNestedChildren(obj) {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  for (let key in obj) {
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      Object.keys(obj[key]).length > 0
    ) {
      return true;
    }
  }
  return false;
}

export function getStringLength(str) {
  return str.length;
}

export function transformDateStringToFirestoreDate(inputDate) {
  const dateString = inputDate.toISOString();
  const dateParts = dateString.split("T")[0].split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are zero-indexed
  const day = parseInt(dateParts[2], 10);
  const dateObject = new Date(year, month, day);

  // Step 2: Get the Unix timestamp (in seconds) from the Date object
  const unixTimestampInSeconds = Math.floor(dateObject.getTime() / 1000);

  // Step 3: Create the Firestore date object with the required format
  const firestoreDateObject = {
    _seconds: unixTimestampInSeconds,
    _nanoseconds: 0, // You can set this to 0 or any other appropriate value
  };

  return firestoreDateObject;
}

export const haveChildrens = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  for (let key in obj) {
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      Object.keys(obj[key]).length > 0
    ) {
      return true;
    }
  }
  return false;
};

export function findNestedChoices(obj) {
  if (typeof obj !== "object" || obj === null) return null;

  for (const key in obj) {
    if (key !== "choices" && typeof obj[key] === "object") {
      const nestedResult = findNestedChoices(obj[key]);
      if (nestedResult) return nestedResult;
    }
  }

  if (obj.hasOwnProperty("choices")) return obj.choices;

  return null;
}
