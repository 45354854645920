import styled from "styled-components";

export const StyledBrevo = styled.div`
  margin-left: 238px;

  iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
`;
