import { getFilterFields } from "../../../utils/jsonUtils";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  if (!dateString) return "";
  return dateString.split("T")[0];
};

const createColumnFromTypeText = (field) => ({
  Header: field.label,
  accessor: field.key,
  disableSortBy: field.key === "selezione" || field.key === "purchased_contacts",
  filter: "fuzzyText",
  Cell: ({ value }) => {
    try {
      if (field.key === "creation_time") {
        return formatDate(value);
      } else if (field.key === "selezione") {
        const cvalue = value
          .map((s) => JSON.parse(s).codice_fiscale)
          .join(" - ");
        return <p>{cvalue}</p>;
      } else if (field.key === "purchased_contacts") {
        const cvalue = value
          .map((s) => JSON.parse(s).codice_fiscale)
          .join(" - ");
        return <p>{cvalue}</p>;
      } else {
        return <p>{value}</p>;
      }
    } catch (err) {}
  },
});

const createColumnFromTypeChoice = (field) => ({
  Header: field.label,
  accessor: field.key,
  filter: "fuzzyText",
  Cell: ({ value }) => {
    try {
      if (field.key === "creation_time") {
        return formatDate(value);
      }
      return <p>{value.toString()}</p>;
    } catch (err) {
      console.log(err);
    }
  },
});

export const getColumnModelFamilies = async () => {
  const fields = await getFilterFields("families");
  const typeTextFields = [];
  const typeChoiceFields = [];

  for (const [key, value] of Object.entries(fields)) {
    if (value.type === "text") {
      typeTextFields.push(value);
    } else {
      if (
        value.experiences ||
        value.badante ||
        value.colf ||
        value.baby_sitter ||
        (value.key && value.label)
      ) {
        typeChoiceFields.push(value);
      }
    }
  }

  const typeTextColumns = typeTextFields.map(createColumnFromTypeText);
  const typeChoiceColumns = typeChoiceFields.map(createColumnFromTypeChoice);

  return [...typeTextColumns, ...typeChoiceColumns.flat(Infinity)];
};
