import styled from "styled-components";
import {
  MAIN_COLORS,
  workerTagColors,
} from "../../../../../../constants/vetrina/Colors";
import { WorkerHomeWorkType } from "../../../../../../types/vetrina";
import {
  DIMENS,
  MOBILE_BREAKPOINT,
} from "../../../../../../constants/vetrina/Dimens";

interface IStyledWorkerTagProps {
  $tag: WorkerHomeWorkType;
}

export const StyledWorkerTag = styled.div<IStyledWorkerTagProps>`
  padding: 0.25rem 0.75rem;
  width: fit-content;
  background-color: ${({ $tag }) => workerTagColors($tag)};
  color: ${MAIN_COLORS.white};
  font-family: "Inter", sans-serif;
  border-radius: ${DIMENS.borderRadius3};
  line-height: 1.5rem;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    line-height: 1.125rem;
  }
`;
