import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";

import { isCodiceFiscale } from "../../../utils/utils";
import { TypeForm } from "../../../components/TypeForm/index";
import { isStepValid } from "../../../utils/onboarding";

const CodiceFiscale = ({
  onNext,
  formData,
  title,
  onInputChange,
  onPrevious,
}) => {
  const { codiceFiscale } = formData;
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    if (isCodiceFiscale(codiceFiscale)) {
      onNext();
    } else {
      setErrorMessage("Inserisci un codice fiscale valido");
    }
  };

  const handleInputChange = (fieldName, value) => {
    onInputChange(fieldName, value);
  };

  const handleClickPreviousButton = () => {
    // onInputChange("codiceFiscale", "");
    // onInputChange("name", "");
    // onInputChange("surname", "");

    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title} </h1>
          </div>
          <h2 className="subtitle"> </h2>
          <div className="div">
            <TypeForm
              className="type-form-instance"
              label="Codice Fiscale"
              value={codiceFiscale}
              onChange={(value) => handleInputChange("codiceFiscale", value.toUpperCase())}
            />
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={`button-instance ${
                  !isStepValid({
                    mandatoryFields: "codiceFiscale",
                    formData: {
                      codiceFiscale: formData.codiceFiscale,
                    },
                  })
                    ? "button-disabled"
                    : ""
                }`}
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodiceFiscale;
