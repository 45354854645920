export const PREFIXES = [
  {
    prefix: "+39",
    country: "Italy",
  },
  {
    prefix: "+7",
    country: "Abkhazia",
  },
  {
    prefix: "+995",
    country: "Abkhazia",
  },
  {
    prefix: "+93",
    country: "Afghanistan",
  },
  {
    prefix: "+355",
    country: "Albania",
  },
  {
    prefix: "+213",
    country: "Algeria",
  },
  {
    prefix: "+1",
    country: "American Samoa",
  },
  {
    prefix: "+376",
    country: "Andorra",
  },
  {
    prefix: "+244",
    country: "Angola",
  },
  {
    prefix: "+54",
    country: "Argentina",
  },
  {
    prefix: "+374",
    country: "Armenia",
  },
  {
    prefix: "+297",
    country: "Aruba",
  },
  {
    prefix: "+247",
    country: "Ascension Island",
  },
  {
    prefix: "+61",
    country: "Australia",
  },
  {
    prefix: "+672",
    country: "Australian Antarctic Territory",
  },
  {
    prefix: "+43",
    country: "Austria",
  },
  {
    prefix: "+994",
    country: "Azerbaijan",
  },
  {
    prefix: "+973",
    country: "Bahrain",
  },
  {
    prefix: "+880",
    country: "Bangladesh",
  },
  {
    prefix: "+375",
    country: "Belarus",
  },
  {
    prefix: "+32",
    country: "Belgium",
  },
  {
    prefix: "+501",
    country: "Belize",
  },
  {
    prefix: "+229",
    country: "Benin",
  },
  {
    prefix: "+975",
    country: "Bhutan",
  },
  {
    prefix: "+591",
    country: "Bolivia, Plurinational State Of",
  },
  {
    prefix: "+599",
    country: "Bonaire, Saint Eustatius And Saba",
  },
  {
    prefix: "+387",
    country: "Bosnia & Herzegovina",
  },
  {
    prefix: "+267",
    country: "Botswana",
  },
  {
    prefix: "+55",
    country: "Brazil",
  },
  {
    prefix: "+246",
    country: "British Indian Ocean Territory",
  },
  {
    prefix: "+673",
    country: "Brunei Darussalam",
  },
  {
    prefix: "+359",
    country: "Bulgaria",
  },
  {
    prefix: "+226",
    country: "Burkina Faso",
  },
  {
    prefix: "+95",
    country: "Burma",
  },
  {
    prefix: "+257",
    country: "Burundi",
  },
  {
    prefix: "+855",
    country: "Cambodia",
  },
  {
    prefix: "+237",
    country: "Cameroon",
  },
  {
    prefix: "+34",
    country: "Canary Islands",
  },
  {
    prefix: "+238",
    country: "Cabo Verde",
  },
  {
    prefix: "+236",
    country: "Central African Republic",
  },
  {
    prefix: "+235",
    country: "Chad",
  },
  {
    prefix: "+56",
    country: "Chile",
  },
  {
    prefix: "+590",
    country: "Collectivity of Saint Martin",
  },
  {
    prefix: "+57",
    country: "Colombia",
  },
  {
    prefix: "+269",
    country: "Comoros",
  },
  {
    prefix: "+682",
    country: "Cook Islands",
  },
  {
    prefix: "+506",
    country: "Costa Rica",
  },
  {
    prefix: "+385",
    country: "Croatia",
  },
  {
    prefix: "+53",
    country: "Cuba",
  },
  {
    prefix: "+357",
    country: "Cyprus",
  },
  {
    prefix: "+420",
    country: "Czech Republic",
  },
  {
    prefix: "+243",
    country: "Democratic Republic Of Congo",
  },
  {
    prefix: "+45",
    country: "Denmark",
  },
  {
    prefix: "+253",
    country: "Djibouti",
  },
  {
    prefix: "+670",
    country: "East Timor",
  },
  {
    prefix: "+593",
    country: "Ecuador",
  },
  {
    prefix: "+20",
    country: "Egypt",
  },
  {
    prefix: "+503",
    country: "El Salvador",
  },
  {
    prefix: "+44",
    country: "United Kingdom",
  },
  {
    prefix: "+240",
    country: "Equatorial Guinea",
  },
  {
    prefix: "+291",
    country: "Eritrea",
  },
  {
    prefix: "+372",
    country: "Estonia",
  },
  {
    prefix: "+251",
    country: "Ethiopia",
  },
  {
    prefix: "+500",
    country: "Falkland Islands",
  },
  {
    prefix: "+298",
    country: "Faroe Islands",
  },
  {
    prefix: "+691",
    country: "Federated States of Micronesia",
  },
  {
    prefix: "+679",
    country: "Fiji",
  },
  {
    prefix: "+358",
    country: "Finland",
  },
  {
    prefix: "+33",
    country: "France",
  },
  {
    prefix: "+594",
    country: "French Guiana",
  },
  {
    prefix: "+262",
    country: "French Overseas Departments and Territories",
  },
  {
    prefix: "+508",
    country: "French Overseas Departments and Territories",
  },
  {
    prefix: "+596",
    country: "French Overseas Departments and Territories",
  },
  {
    prefix: "+689",
    country: "French Polynesia",
  },
  {
    prefix: "+241",
    country: "Gabon",
  },
  {
    prefix: "+220",
    country: "Gambia",
  },
  {
    prefix: "+49",
    country: "Germany",
  },
  {
    prefix: "+233",
    country: "Ghana",
  },
  {
    prefix: "+350",
    country: "Gibraltar",
  },
  {
    prefix: "+30",
    country: "Greece",
  },
  {
    prefix: "+299",
    country: "Greenland",
  },
  {
    prefix: "+502",
    country: "Guatemala",
  },
  {
    prefix: "+224",
    country: "Guinea",
  },
  {
    prefix: "+245",
    country: "Guinea-bissau",
  },
  {
    prefix: "+592",
    country: "Guyana",
  },
  {
    prefix: "+509",
    country: "Haiti",
  },
  {
    prefix: "+504",
    country: "Honduras",
  },
  {
    prefix: "+852",
    country: "Hong Kong",
  },
  {
    prefix: "+36",
    country: "Hungary",
  },
  {
    prefix: "+354",
    country: "Iceland",
  },
  {
    prefix: "+91",
    country: "India",
  },
  {
    prefix: "+62",
    country: "Indonesia",
  },
  {
    prefix: "+98",
    country: "Iran, Islamic Republic Of",
  },
  {
    prefix: "+964",
    country: "Iraq",
  },
  {
    prefix: "+353",
    country: "Ireland",
  },
  {
    prefix: "+972",
    country: "Israel",
  },
  {
    prefix: "+225",
    country: "Côte d'Ivoire",
  },
  {
    prefix: "+47",
    country: "Svalbard And Jan Mayen",
  },
  {
    prefix: "+81",
    country: "Japan",
  },
  {
    prefix: "+962",
    country: "Jordan",
  },
  {
    prefix: "+254",
    country: "Kenya",
  },
  {
    prefix: "+686",
    country: "Kiribati",
  },
  {
    prefix: "+383",
    country: "Kosovo",
  },
  {
    prefix: "+965",
    country: "Kuwait",
  },
  {
    prefix: "+996",
    country: "Kyrgyzstan",
  },
  {
    prefix: "+856",
    country: "Lao People's Democratic Republic",
  },
  {
    prefix: "+371",
    country: "Latvia",
  },
  {
    prefix: "+961",
    country: "Lebanon",
  },
  {
    prefix: "+266",
    country: "Lesotho",
  },
  {
    prefix: "+231",
    country: "Liberia",
  },
  {
    prefix: "+218",
    country: "Libya",
  },
  {
    prefix: "+423",
    country: "Liechtenstein",
  },
  {
    prefix: "+370",
    country: "Lithuania",
  },
  {
    prefix: "+352",
    country: "Luxembourg",
  },
  {
    prefix: "+853",
    country: "Macao",
  },
  {
    prefix: "+261",
    country: "Madagascar",
  },
  {
    prefix: "+351",
    country: "Madeira",
  },
  {
    prefix: "+265",
    country: "Malawi",
  },
  {
    prefix: "+60",
    country: "Malaysia",
  },
  {
    prefix: "+960",
    country: "Maldives",
  },
  {
    prefix: "+223",
    country: "Mali",
  },
  {
    prefix: "+356",
    country: "Malta",
  },
  {
    prefix: "+692",
    country: "Marshall Islands",
  },
  {
    prefix: "+222",
    country: "Mauritania",
  },
  {
    prefix: "+230",
    country: "Mauritius",
  },
  {
    prefix: "+52",
    country: "Mexico",
  },
  {
    prefix: "+373",
    country: "Moldova",
  },
  {
    prefix: "+377",
    country: "Monaco",
  },
  {
    prefix: "+976",
    country: "Mongolia",
  },
  {
    prefix: "+382",
    country: "Montenegro",
  },
  {
    prefix: "+212",
    country: "Morocco",
  },
  {
    prefix: "+258",
    country: "Mozambique",
  },
  {
    prefix: "+264",
    country: "Namibia",
  },
  {
    prefix: "+674",
    country: "Nauru",
  },
  {
    prefix: "+977",
    country: "Nepal",
  },
  {
    prefix: "+31",
    country: "Netherlands",
  },
  {
    prefix: "+687",
    country: "New Caledonia",
  },
  {
    prefix: "+64",
    country: "New Zealand",
  },
  {
    prefix: "+505",
    country: "Nicaragua",
  },
  {
    prefix: "+227",
    country: "Niger",
  },
  {
    prefix: "+234",
    country: "Nigeria",
  },
  {
    prefix: "+683",
    country: "Niue",
  },
  {
    prefix: "+850",
    country: "Korea, Republic Of",
  },
  {
    prefix: "+90",
    country: "Northern Cyprus",
  },
  {
    prefix: "+968",
    country: "Oman",
  },
  {
    prefix: "+92",
    country: "Pakistan",
  },
  {
    prefix: "+680",
    country: "Palau",
  },
  {
    prefix: "+970",
    country: "Palestine, State of",
  },
  {
    prefix: "+507",
    country: "Panama",
  },
  {
    prefix: "+675",
    country: "Papua New Guinea",
  },
  {
    prefix: "+595",
    country: "Paraguay",
  },
  {
    prefix: "+86",
    country: "People's Republic of China",
  },
  {
    prefix: "+51",
    country: "Peru",
  },
  {
    prefix: "+63",
    country: "Philippines",
  },
  {
    prefix: "+48",
    country: "Poland",
  },
  {
    prefix: "+239",
    country: "Príncipe",
  },
  {
    prefix: "+974",
    country: "Qatar",
  },
  {
    prefix: "+242",
    country: "Republic Of Congo",
  },
  {
    prefix: "+389",
    country: "Macedonia, The Former Yugoslav Republic Of",
  },
  {
    prefix: "+40",
    country: "Romania",
  },
  {
    prefix: "+250",
    country: "Rwanda",
  },
  {
    prefix: "+290",
    country: "Saint Helena and Tristan da Cunha (not Ascenscion)",
  },
  {
    prefix: "+685",
    country: "Samoa",
  },
  {
    prefix: "+378",
    country: "San Marino",
  },
  {
    prefix: "+966",
    country: "Saudi Arabia",
  },
  {
    prefix: "+221",
    country: "Senegal",
  },
  {
    prefix: "+381",
    country: "Serbia",
  },
  {
    prefix: "+248",
    country: "Seychelles",
  },
  {
    prefix: "+232",
    country: "Sierra Leone",
  },
  {
    prefix: "+65",
    country: "Singapore",
  },
  {
    prefix: "+421",
    country: "Slovakia",
  },
  {
    prefix: "+386",
    country: "Slovenia",
  },
  {
    prefix: "+677",
    country: "Solomon Islands",
  },
  {
    prefix: "+252",
    country: "Somalia",
  },
  {
    prefix: "+27",
    country: "South Africa",
  },
  {
    prefix: "+82",
    country: "Korea, Democratic People's Republic Of",
  },
  {
    prefix: "+211",
    country: "South Sudan",
  },
  {
    prefix: "+94",
    country: "Sri Lanka",
  },
  {
    prefix: "+249",
    country: "Sudan",
  },
  {
    prefix: "+597",
    country: "Suriname",
  },
  {
    prefix: "+268",
    country: "Swaziland",
  },
  {
    prefix: "+46",
    country: "Sweden",
  },
  {
    prefix: "+41",
    country: "Switzerland",
  },
  {
    prefix: "+963",
    country: "Syrian Arab Republic",
  },
  {
    prefix: "+886",
    country: "Taiwan",
  },
  {
    prefix: "+992",
    country: "Tajikistan",
  },
  {
    prefix: "+255",
    country: "Tanzania, United Republic Of",
  },
  {
    prefix: "+66",
    country: "Thailand",
  },
  {
    prefix: "+228",
    country: "Togo",
  },
  {
    prefix: "+690",
    country: "Tokelau",
  },
  {
    prefix: "+676",
    country: "Tonga",
  },
  {
    prefix: "+216",
    country: "Tunisia",
  },
  {
    prefix: "+993",
    country: "Turkmenistan",
  },
  {
    prefix: "+688",
    country: "Tuvalu",
  },
  {
    prefix: "+256",
    country: "Uganda",
  },
  {
    prefix: "+380",
    country: "Ukraine",
  },
  {
    prefix: "+971",
    country: "United Arab Emirates",
  },
  {
    prefix: "+598",
    country: "Uruguay",
  },
  {
    prefix: "+998",
    country: "Uzbekistan",
  },
  {
    prefix: "+678",
    country: "Vanuatu",
  },
  {
    prefix: "+58",
    country: "Venezuela, Bolivarian Republic Of",
  },
  {
    prefix: "+84",
    country: "Viet Nam",
  },
  {
    prefix: "+681",
    country: "Wallis And Futuna",
  },
  {
    prefix: "+967",
    country: "Yemen",
  },
  {
    prefix: "+260",
    country: "Zambia",
  },
  {
    prefix: "+263",
    country: "Zimbabwe",
  },
];
