import styled from "styled-components";

export const StyledFirstStep = styled.div`
  .subtitle {
    padding-inline: 36px;
    text-align: center;
    white-space: normal;
    width: -webkit-fill-available;
  }

  .grid-welcome {
    gap: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 0;
      font-size: 1.5rem;
    }

    img {
      height: auto;
      width: 300px;
      object-fit: cover;
    }

    .subtitle {
      margin-top: 12px;
      margin-bottom: 48px;
    }
  }
`;
