import { createSlice, current } from "@reduxjs/toolkit";
import { IWorker } from "../../types/filterFields";
import { editSelected } from "../thunks/databaseTable";
import { KanbanSpaces } from "../../constants/kanban";
import { getKanbanWorkers, updateKanbanBoard } from "../thunks/kanban";

interface IKanbanCard {
  [key: string]: any;
}

export interface IState {
  cards: IWorker[] | IKanbanCard[];
  currentBoard: KanbanSpaces;
  isLoading: boolean;
}

const initialState: IState = {
  cards: [],
  currentBoard: KanbanSpaces.workers,
  isLoading: false,
};

const kanbanSlice = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    updateKanbanCard: (state, action) => {
      const i = state.cards.findIndex(
        (item) =>
          (item as IWorker).codice_fiscale ===
          (action.payload as any).codice_fiscale
      );

      state.cards[i] = JSON.parse(JSON.stringify(action.payload));
    },
  },
  extraReducers(builder) {
    builder.addCase(getKanbanWorkers.fulfilled, (state, action) => {
      const parsedRows = action.payload.rows.map(
        (row: { [key: string]: any }) => {
          const parsedObject: IWorker = {} as IWorker;

          Object.keys(row).forEach((key) => {
            if (key === "experiences") {
              parsedObject.experiences = {
                count: row[key].count,
                experiences: row[key].experiences.map((value: any) => {
                  return JSON.parse(value);
                }),
              };
            } else {
              parsedObject[key] = row[key];
            }
          });
          return parsedObject;
        }
      ) as IWorker[];
      state.cards = parsedRows;
      state.isLoading = false;
    });
    builder.addCase(getKanbanWorkers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getKanbanWorkers.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateKanbanBoard.fulfilled, (state, action) => {
      const index = state.cards.findIndex(
        (c: any) => c.codice_fiscale === action.payload.updated.codice_fiscale
      );
      const updatedObject = {
        ...state.cards[index],
        board: action.payload.updated.board,
        current_status: [
          "Registrato",
          "Profilo Controllato",
          "Chiamato",
          "Colloquiato",
          "In Vetrina",
          "Non Validato",
          "Non più disponibile",
        ][action.payload.updated.board],
      };
      const updatedCards = [...current(state.cards)];
      updatedCards[index] = updatedObject;
      state.cards = updatedCards;
      state.isLoading = false;
    });
    builder.addCase(updateKanbanBoard.pending, (state, action) => {
      // state.isLoading = true;
    });
    builder.addCase(updateKanbanBoard.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default kanbanSlice.reducer;
export const { updateKanbanCard } = kanbanSlice.actions;
