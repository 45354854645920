import React from "react";
import Button from "../../components/Button/index";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import {
  MIN_WIDTH_DESKTOP,
  MIN_WIDTH_TABLET,
  MAX_WIDTH_TABLET,
  MAX_WIDTH_MOBILE,
} from "../../utils/general-varibales";
//import bazeLogo from "../../img/static/bazeLogo.png";
import Logo from "../../components/Logo/Logo";
import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
  const navigate = useNavigate();

  const isDesktop = useMediaQuery({ minWidth: MIN_WIDTH_DESKTOP });
  //const isTablet = useMediaQuery({ minWidth: MIN_WIDTH_TABLET, maxWidth: MAX_WIDTH_TABLET });
  //const isMobile = useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE });

  function handleClickSonoUnLavoratoreButton() {
    navigate("/onboarding-lavoratori", { replace: false });
  }

  function handleClickCercoUnLavoratoreButton() {
    navigate("/sign-up-family", { replace: false });
  }

  return (
    <div className="main-page">
      <div className="log-in-interno">
        <Logo></Logo>
        <div className="login-screen">
          <div className="typeforms-buttons">
            <div className="title-container">
              <h1 className="title">Crea il tuo profilo</h1>
            </div>
            <div className="div">
              <div className="div">
                <div className="buttons">
                  <Button
                    className="button-instance"
                    property1="primary"
                    text="Cerco un lavoro"
                    onClick={handleClickSonoUnLavoratoreButton}
                  />
                  <Button
                    className="button-instance"
                    property1="primary"
                    text="Sono un datore di lavoro"
                    onClick={handleClickCercoUnLavoratoreButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
