import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import { isAcceptableStepFormText } from "../functionsSteps";
import TextField from "../../../components/TextField/Textfield";
import { TypeForm } from "../../../components/TypeForm/index";
import DateForm from "../../../components/DateForm";
import {
  stepOneWorkOptionsList,
  stepTwoModalityWork,
} from "../stepsVariables.js";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import { useSelector } from "react-redux";
import { isStepValid } from "../../../utils/onboarding";
import certificationIcon from "../../../img/static/certificationIcon.svg";
import { IconInterfacesUser } from "../../../components/IconInterfacesUser";
import { WorkType } from "../../../constants/onboarding";

const Experience = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const [workType, setWorkType] = useState();

  const { experiences } = formData;

  const experienceChoices = useSelector(
    (state) => state.onboarding.formOptions.experiences
  );

  // const workType = useSelector((state) => state.onboarding.tipo_lavoro)[0];

  const [errorMessage, setErrorMessage] = useState("");
  const [altraEspOpened, setAltraEspOpened] = useState({ 0: false });

  const handleClickNextButton = () => {
    const datesValid = experiences.every((experience, i) => {
      if (
        new Date(experience.startExperience) >
        new Date(experience.endExperience)
      ) {
        setErrorMessage(
          "La data di inizio non può essere maggiore della data di fine"
        );
        return false;
      }
      if (new Date(experience.startExperience) > new Date()) {
        setErrorMessage(
          "La data di inizio non può essere maggiore della data attuale"
        );
        return false;
      }
      if (new Date(experience.endExperience) > new Date()) {
        setErrorMessage(
          "La data di fine non è consentita perche è maggiore della data attuale"
        );
        return false;
      }
      return true;
    });

    //console.log(workModalitiesList);
    //console.log(formData);
    if (datesValid) {
      onNext();
    }

    /* 
      if (isAcceptableStepFormText(experienceTitle)) {
        onNext();
      } else {
        setErrorMessage("Inserisci una sola risposta");
      }*/
  };

  const handleClickPreviousButton = () => {
    // onInputChange("experiences", [
    //   {
    //     experienceAs: "",
    //     // experienceTitle: "",
    //     startExperience: "",
    //     endExperience: "",
    //     workOptionExperience: [],
    //     workModalityExperience: [],
    //     mansioni: [],
    //   },
    // ]);
    // onInputChange("experienceDescription", "");
    // onInputChange("nationality", "");

    onPrevious();
  };

  const handleSelectChoiceClickWorkOption = (isClicked, text, index) => {
    const updatedExperiences = [...formData.experiences];
    if (text === "Altro") {
      setAltraEspOpened((p) => ({ ...altraEspOpened, [index]: !p[index] }));
    } else if (text === "Badante" || text === "Colf" || text === "Babysitter") {
      setAltraEspOpened({ ...altraEspOpened, [index]: false });
    }

    if (typeof isClicked === "undefined") {
      formData.experiences[index].experienceAs = text;
    }
    if (isClicked) {
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        experienceAs: text,
      };
      setWorkType(text);
    } else {
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        experienceAs: "",
      };
      setWorkType("");
    }

    onInputChange("experiences", updatedExperiences);
  };

  const handleSelectChoiceClickWorkModality = (isClicked, text, i) => {
    const updatedWorkModalityExperience = [
      ...experiences[i].workModalityExperience,
    ];
    if (isClicked) {
      if (!updatedWorkModalityExperience.includes(text)) {
        updatedWorkModalityExperience.push(text);
      }
    } else {
      const index = updatedWorkModalityExperience.indexOf(text);
      if (index !== -1) {
        updatedWorkModalityExperience.splice(index, 1);
      }
    }
    const updatedArray = [...experiences];
    updatedArray[i].workModalityExperience = updatedWorkModalityExperience;
    onInputChange("experiences", updatedArray);
  };

  const handleSelectChoiceMansioni = (isClicked, text, i) => {
    const updatedMansioni = [...experiences[i].mansioni];

    if (isClicked) {
      if (!updatedMansioni.includes(text)) {
        updatedMansioni.push(text);
      }
    } else {
      const index = updatedMansioni.indexOf(text);
      if (index !== -1) {
        updatedMansioni.splice(index, 1);
      }
    }
    if (updatedMansioni.length === 6) {
      updatedMansioni.splice(0, 1);
    }
    const updatedArray = [...experiences];
    updatedArray[i].mansioni = updatedMansioni;
    onInputChange("experiences", updatedArray);
  };

  const handleAddForm = () => {
    onInputChange("experiences", [
      ...experiences,
      {
        experienceTitle: "",
        startExperience: "",
        endExperience: "",
        workOptionExperience: [],
        workModalityExperience: [],
        mansioni: [],
      },
    ]);
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="title-container">
          <h1 className="title title-custom">{title} </h1>
        </div>

        <h2 className="subtitle-custom">{subtitle}</h2>

        {experiences.map((_, i) => (
          <div
            key={i}
            className="typeforms-buttons"
            style={{ overflow: "hidden" }}
          >
            {/* <div className="title-container">
              <h1 className="title">{title}</h1>
        </div>*/}
            <div className="div">
              <h3> Esperienza come </h3>
              <div className="selection-element-horrizonatal">
                {stepOneWorkOptionsList.map((item) => (
                  <SelectChoice
                    key={item}
                    text={item}
                    isClickedProp={
                      experiences[i].experienceAs === item ||
                      (item === "Altro" &&
                        experiences[i].experienceAs !== WorkType.BABY_SITTER &&
                        experiences[i].experienceAs !== WorkType.BADANTE &&
                        experiences[i].experienceAs !== WorkType.COLF &&
                        experiences[i].experienceAs !== "")
                    }
                    onClick={(isClicked, text) =>
                      handleSelectChoiceClickWorkOption(isClicked, text, i)
                    }
                  />
                ))}
              </div>
              <div>
                {altraEspOpened[i] ? (
                  <TypeForm
                    className="type-form-instance"
                    label="Altra esperienza"
                    override={
                      <IconInterfacesUser
                        className="icon-interfaces-user-instance"
                        combinedShape={certificationIcon}
                      />
                    }
                    onChange={
                      (value) =>
                        handleSelectChoiceClickWorkOption(undefined, value, i)
                      //onInputChange("nameCertification", value)
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
              {/* <TypeForm
                className="type-form-instance"
                label="Titolo Esperienza"
                value={experiences[i].experienceTitle}
                onChange={(value) => {
                  const updatedArray = [...experiences];
                  updatedArray[i].experienceTitle = value;
                  onInputChange("experiences", updatedArray);
                }}
              /> */}
              <h3> Mese di inizio </h3>
              <DateForm
                type="Month"
                value={experiences[i].startExperience}
                onChange={(value) => {
                  const updatedArray = [...experiences];
                  updatedArray[i].startExperience = value.target.value;
                  onInputChange("experiences", updatedArray);
                }}
              ></DateForm>
              <div className="wrapper-date-title">
                <h3> Mese di fine </h3>
                <p className="wrapper-date-title__text">
                  <label>(Lascia vuoto se attuale)</label>
                </p>
              </div>
              <DateForm
                value={experiences[i].endExperience}
                type="Month"
                onChange={(value) => {
                  const updatedArray = [...experiences];
                  updatedArray[i].endExperience = value.target.value;
                  onInputChange("experiences", updatedArray);
                }}
              ></DateForm>

              {workType === "Badante" ||
              workType === "Colf" ||
              workType === "Babysitter" ? (
                <>
                  <div className="wrapper-title-subtitle">
                    <h3>Mansioni</h3>
                    <p className="wrapper-title-subtitle__text">
                      (Seleziona al massimo 5 opzioni)
                    </p>
                  </div>
                  <div className="selection-element-horrizonatal">
                    {(workType === "Badante"
                      ? experienceChoices.badante
                      : workType === "Colf"
                      ? experienceChoices.colf
                      : workType === "Babysitter"
                      ? experienceChoices.baby_sitter
                      : []
                    ).map((item) => (
                      <SelectChoice
                        key={item}
                        text={item}
                        isClickedProp={experiences[i].mansioni.includes(item)}
                        onClick={(e, t) => handleSelectChoiceMansioni(e, t, i)}
                      />
                    ))}
                  </div>
                </>
              ) : null}

              <h3> Tipo di Lavoro </h3>
              <div className="selection-element-horrizonatal">
                {stepTwoModalityWork.map((item) => (
                  <SelectChoice
                    key={item}
                    text={item}
                    onClick={(e, t) =>
                      handleSelectChoiceClickWorkModality(e, t, i)
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
        <ErrorMessageInForm className="error-banner" message={errorMessage} />
        <div className="buttons buttons-experiences">
          <div className="primary-buttons-wrapper">
            <Button
              className={[
                "button-instance add-btn",
                formData.experiences.length === 2 ? "button-disabled" : "",
              ].join(" ")}
              property1="primary"
              text="Aggiungi Esperienza"
              onClick={handleAddForm}
            />
            <Button
              className={`button-instance next-btn ${
                !isStepValid({
                  mandatoryFields: [
                    "startExperience",
                    "experienceAs",
                    "workModalityExperience",
                  ],
                  formData: {
                    startExperience: formData.experiences[0].startExperience,
                    experienceAs: formData.experiences[0].experienceAs,
                    workModalityExperience:
                      formData.experiences[0].workModalityExperience,
                  },
                })
                  ? "button-disabled"
                  : ""
              }`}
              property1="primary"
              text="Avanti"
              onClick={handleClickNextButton}
            />
          </div>
          <Button
            className="button-instance back-btn"
            property1="secondary"
            text="Indietro"
            onClick={handleClickPreviousButton}
          />
        </div>
      </div>
    </div>
  );
};

export default Experience;
