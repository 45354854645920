import React from "react";
import "./style.css";

import lock from "../../img/static/lock.svg";

export const IconInterfacesLock = ({ className }) => {
  return (
    <div className={`icon-interfaces-lock ${className}`}>
      <img className="shape" alt="Shape" src={lock} />
    </div>
  );
};
