import styled from "styled-components";

export const StyledVerificationEmail = styled.div`
  position: relative;
  height: 100vh;

  .form-wrapper {
    position: absolute;
    inset: 0;
    height: fit-content;
    width: 30rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffffaf;
    box-shadow: 0 0 8px 8px #00000002;
    padding: 48px;
    border-radius: 16px;
    border: 1px solid #e0e0e0;
    max-width: 600px;

    h3 {
      font-size: 2rem;
      color: var(--orange);
      font-family: "Inter", sans-serif;
      margin-bottom: 8px;
      margin-top: 0;
      text-align: center;
    }

    input[type="text"],
    input[type="password"] {
      margin-bottom: 16px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 12px;
    }
  }
`;
