import styled from "styled-components";

export const StyledSearchBar = styled.label`
  border: 1.5px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;

  .search-icon {
    width: 28px;
    height: 28px;
    margin-right: 4px;
  }

  input[type="text"] {
    border: none;
    outline: none;
    font-family: inherit;
    height: 100%;
    width: 100%;
  }

  .close-search-icon {
    width: 18px;
    height: 18px;
  }
`;
