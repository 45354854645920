export enum ProvinciaItalia {
  AGRIGENTO = "Agrigento",
  ALESSANDRIA = "Alessandria",
  ANCONA = "Ancona",
  AOSTA = "Aosta",
  AREZZO = "Arezzo",
  ASCOLIPICENO = "Ascoli Piceno",
  ASTI = "Asti",
  AVELLINO = "Avellino",
  BARI = "Bari",
  BARLETTAANDRIATRANI = "Barletta-Andria-Trani",
  BELLUNO = "Belluno",
  BENEVENTO = "Benevento",
  BERGAMO = "Bergamo",
  BIELLA = "Biella",
  BOLOGNA = "Bologna",
  BOLZANO = "Bolzano",
  BRESCIA = "Brescia",
  BRINDISI = "Brindisi",
  CAGLIARI = "Cagliari",
  CALTANISSETTA = "Caltanissetta",
  CAMPOBASSO = "Campobasso",
  CARBONIAIGLESIAS = "Carbonia-Iglesias",
  CASERTA = "Caserta",
  CATANIA = "Catania",
  CATANZARO = "Catanzaro",
  CHIETI = "Chieti",
  COMO = "Como",
  COSENZA = "Cosenza",
  CREMONA = "Cremona",
  CROTONE = "Crotone",
  CUNEO = "Cuneo",
  ENNA = "Enna",
  FERMO = "Fermo",
  FERRARA = "Ferrara",
  FIRENZE = "Firenze",
  FOGGIA = "Foggia",
  FORLICESANA = "Forlì-Cesena",
  FROSINONE = "Frosinone",
  GENOVA = "Genova",
  GORIZIA = "Gorizia",
  GROSSETO = "Grosseto",
  IMPERIA = "Imperia",
  ISERNIA = "Isernia",
  LASPEZIA = "La Spezia",
  LAQUILA = "L'Aquila",
  LATINA = "Latina",
  LECCE = "Lecce",
  LECCO = "Lecco",
  LIVORNO = "Livorno",
  LODI = "Lodi",
  LUCCA = "Lucca",
  MACERATA = "Macerata",
  MANTOVA = "Mantova",
  MASSACARRARA = "Massa-Carrara",
  MATERA = "Matera",
  MEDIOCAMPIDANO = "Medio Campidano",
  MESSINA = "Messina",
  MILANO = "Milano",
  MODENA = "Modena",
  MONZAEBRIANZA = "Monza e della Brianza",
  NAPOLI = "Napoli",
  NOVARA = "Novara",
  NUORO = "Nuoro",
  OGLIASTRA = "Ogliastra",
  OLBIA_TEMPIO = "Olbia-Tempio",
  ORISTANO = "Oristano",
  PADOVA = "Padova",
  PALERMO = "Palermo",
  PARMA = "Parma",
  PAVIA = "Pavia",
  PERUGIA = "Perugia",
  PESAROEURBINO = "Pesaro e Urbino",
  PESCARA = "Pescara",
  PIACENZA = "Piacenza",
  PISA = "Pisa",
  PISTOIA = "Pistoia",
  PORDENONE = "Pordenone",
  POTENZA = "Potenza",
  PRATO = "Prato",
  RAGUSA = "Ragusa",
  RAVENNA = "Ravenna",
  REGGIOCALABRIA = "Reggio Calabria",
  REGGIOEMILIA = "Reggio Emilia",
  RIETI = "Rieti",
  RIMINI = "Rimini",
  ROMA = "Roma",
  ROVIGO = "Rovigo",
  SALERNO = "Salerno",
  SASSARI = "Sassari",
  SAVONA = "Savona",
  SIENA = "Siena",
  SIRACUSA = "Siracusa",
  SONDRIO = "Sondrio",
  TARANTO = "Taranto",
  TERAMO = "Teramo",
  TERNI = "Terni",
  TORINO = "Torino",
  TRAPANI = "Trapani",
  TRENTO = "Trento",
  TREVISO = "Treviso",
  TRIESTE = "Trieste",
  UDINE = "Udine",
  VARESE = "Varese",
  VENEZIA = "Venezia",
  VERBANOCUSIOOSSOLA = "Verbano-Cusio-Ossola",
  VERCELLI = "Vercelli",
  VERONA = "Verona",
  VIBOVALENTIA = "Vibo Valentia",
  VICENZA = "Vicenza",
  VITERBO = "Viterbo",
}

export const provincieItaliaList: ProvinciaItalia[] =
  Object.values(ProvinciaItalia);
