import React, { useEffect, useState } from "react";
import MenuItemContainer from "./MenuItemContainer/index.js";
import "./style.css";

import HomeIcon from "../../img/static/homeIcon.svg";
import BrevoIcon from "../../img/static/brevoIcon.png";
import DashBoardIcon from "../../img/static/dashboardIcon.svg";
import bazeLogo from "../../assets/baze-logo.svg";
import DBIcon from "../../img/static/dbIcon.svg";
import kanbanIcon from "../../img/static/kanban.png";
import LogoMenu from "../../img/static/logo_Baze_Menu.png";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo/Logo.jsx";
import { getAuth } from "firebase/auth";
import { app } from "../../lib/firebase/config.ts";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { UserTypes } from "../../types/auth.ts";

const Menu = ({ initialItemSelected = "Home" }) => {
  const [userType, setUserType] = useState();
  const [selectedItem, setSelectedItem] = useState(initialItemSelected); // Inizialmente selezioniamo "Home"

  const navigate = useNavigate();

  // const auth = getAuth(app);

  // useEffect(() => {
  //   auth.onAuthStateChanged(async (user) => {
  //     const db = getFirestore(app);
  //     const docRef = collection(db, "internal_users");
  //     const q = query(docRef, where("email", "==", user.email));
  //     const querySnapshot = await getDocs(q);
  //     setUserType(querySnapshot.docs[0].data().user_type);
  //   });
  // }, [auth]);

  const handleMenuItemClick = (text) => {
    setSelectedItem(text); // Imposta l'elemento selezionato quando viene cliccato

    if (text === "Kanban") {
      navigate("/kanban");
    }

    if (text === "Brevo") {
      navigate("/brevo");
    }

    if (text === "Database") {
      navigate("/database-lavoratori", { replace: true }); // Move to Datastore
    }

    if (text === "Home") {
      navigate("/home", { replace: true }); // Move to Home page
    }
  };

  return (
    <div className="menu">
      <div className="menu-container">
        <div className="logo-wrapper">
          {/* <img className="logo" alt="Logo" src={LogoMenu} /> */}
          <Logo />
        </div>
        <MenuItemContainer
          className={`menu-item-container-instance ${
            selectedItem === "Home" ? "selected" : "default"
          }`}
          property1={selectedItem === "Home" ? "selected" : "default"}
          text="Home"
          icon={HomeIcon}
          onItemClick={handleMenuItemClick}
        />
        <MenuItemContainer
          className={`menu-item-container-instance ${
            selectedItem === "Database" ? "selected" : "default"
          }`}
          property1={selectedItem === "Database" ? "selected" : "default"}
          text="Database"
          icon={DBIcon}
          onItemClick={handleMenuItemClick}
        />
        <MenuItemContainer
          className={`menu-item-container-instance ${
            selectedItem === "Kanban" ? "selected" : "default"
          }`}
          property1={selectedItem === "Kanban" ? "selected" : "default"}
          icon={kanbanIcon}
          text="Kanban"
          onItemClick={handleMenuItemClick}
        />
        <MenuItemContainer
          className={`menu-item-container-instance ${
            selectedItem === "Brevo" ? "selected" : "default"
          }`}
          property1={selectedItem === "Brevo" ? "selected" : "default"}
          icon={BrevoIcon}
          text="Brevo"
          onItemClick={handleMenuItemClick}
        />
        <div>
          <p className="poweredby-text">
            Powered by <img src={bazeLogo} alt="" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
