import PropTypes from "prop-types";
import React, { useRef } from "react";
import { IconInterfacesUser } from "../IconInterfacesUser";
import userIcon from "../../img/static/user-icon.svg";
import "./style.css";

export const TextField = ({
  label = "Campo input",
  className,
  value = "",
  onChange,
  maxLength = 0,
}) => {
  const inputRef = useRef(null);

  const handleInputChange = () => {
    const newValue = inputRef.current.value;
    onChange(newValue);
  };

  const displayValue = value !== "" ? value : label;

  return (
    <div className="text-field">
      <textarea
        maxLength={maxLength === 0 ? 1000000 : maxLength}
        type="text"
        className="campo-text-area "
        placeholder={displayValue}
        ref={inputRef}
        value={value}
        onChange={handleInputChange}
        rows={8}
        cols={40}
      />
      {maxLength !== 0 ? (
        <div className="text-limit-wrapper">
          <p>
            {value.length}/{maxLength}
          </p>
        </div>
      ) : null}
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
};

export default TextField;
