export enum WorkType {
  BADANTE = "Badante",
  COLF = "Colf",
  BABY_SITTER = "Babysitter",
}

export const HOME_WORK_TYPES = [
  "A ore mattina",
  "A ore pomeriggio",
  "A ore tutto il giorno",
  "Convivente tempo pieno (54 ore)",
  "Convivente tempo parziale",
  "Notturno",
  "Fine settimana o sostituzione riposi",
];
