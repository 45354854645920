import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../../../../constants/vetrina/Dimens";

export const StyledWorkerCardHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }

  .card-header {
    display: flex;

    &-content {
      margin-left: 1rem;

      @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        margin-left: 1rem;
      }

      .name {
        font-size: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;

        @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
          font-size: 1rem;
        }
      }

      .position {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        line-height: 1.5rem;
        margin-top: 0.5rem;

        @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
          font-size: 0.75rem;
          line-height: 1.125rem;
        }
      }

      .experience {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        line-height: 1.5rem;
        margin-top: 0.5rem;

        @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
          font-size: 0.75rem;
          line-height: 1.125rem;
        }
      }

      .description-text {
        margin-right: 2rem;

        @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
          margin-right: 0;
        }
      }
    }
  }

  .tags-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
  }

  .card-tags {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    margin-top: 2rem;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-top: 1.5rem;
    }
  }
`;
