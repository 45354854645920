import React, { useState } from "react";
import { StyledAddressStep } from "./StyledAddressStep";
import TypeForm from "../../../../components/TypeForm";
import Logo from "../../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../../components/ErrorMessageInForm";
import Button from "../../../../components/Button";
import {
  isAcceptableStepWith2TextForm,
  isSectionValid,
} from "../../functionsSteps";
import addressIcon from "../../../../assets/address.png";
import { isStepValid } from "../../../../utils/onboarding";
import Dropdown from "../../../../components/atoms/Dropdown/Dropdown";
import { provincieItaliaList } from "../../../../constants/provincie";

const AddressStep = ({
  onNext,
  formData,
  title,
  onInputChange,
  onPrevious,
}: any) => {
  const { address, province, city, postalCode } = formData;
  const [errorMessage, setErrorMessage] = useState("");

  const mandatoryFields = ["province", "city", "address"];

  const handleClickNextButton = () => {
    if (isSectionValid(province, city, address)) {
      onNext();
    } else {
      setErrorMessage("Inserisci i dati richiesti");
    }
  };

  const handleInputChange = (fieldName: string, value: any) => {
    onInputChange(fieldName, value);
  };

  const handleClickPreviousButton = () => {
    // onInputChange("address", "");
    // onInputChange("province", "");
    // onInputChange("city", "");
    // onInputChange("postalCode", "");

    onPrevious();
  };

  return (
    <StyledAddressStep>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="onboarding-screen">
          <div className="typeforms-buttons">
            <div className="title-container">
              <h1 className="title">{title} </h1>
            </div>
            <h2 className="subtitle"> </h2>
            <div className="div">
              <Dropdown
                defaultValue={province ?? "Seleziona un opzione"}
                options={provincieItaliaList}
                label="Provincia* (Obbligatorio)"
                placeholder="Seleziona una provincia"
                onClick={(value: string | string[]) =>
                  handleInputChange("province", value as string)
                }
              />
              <TypeForm
                icon={addressIcon}
                className="type-form-instance"
                label="Indirizzo* (Obbligatorio)"
                value={address}
                onChange={(value: string) =>
                  handleInputChange("address", value)
                }
              />
              <TypeForm
                icon={addressIcon}
                className="type-form-instance"
                label="Codice postale"
                value={postalCode}
                onChange={(value: string) =>
                  handleInputChange("postalCode", value)
                }
              />
              <TypeForm
                icon={addressIcon}
                className="type-form-instance"
                label="Comune* (Obbligatorio)"
                value={city}
                onChange={(value: string) => handleInputChange("city", value)}
              />
              <ErrorMessageInForm message={errorMessage} />
              <div className="buttons">
                <Button
                  className={`button-instance ${
                    !isStepValid({
                      mandatoryFields,
                      formData: {
                        province: formData.province,
                        city: formData.city,
                        address: formData.address,
                      },
                    })
                      ? "button-disabled"
                      : ""
                  }`}
                  property1="primary"
                  onClick={handleClickNextButton}
                >
                  Avanti
                </Button>
                <Button
                  className="button-instance"
                  property1="secondary"
                  onClick={handleClickPreviousButton}
                >
                  Indietro
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledAddressStep>
  );
};

export default AddressStep;
