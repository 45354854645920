import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./style.css";

const SelectChoice = ({
  property1,
  className = "",
  text = "Sample Text",
  onClick,
  isClickedProp = false,
}) => {
  const [isClicked, setIsClicked] = useState(isClickedProp ?? false);

  const handleClick = () => {
    setIsClicked(!isClicked);
    onClick(!isClicked, text); // Chiamiamo la funzione di callback con i valori correnti di isClicked e text
  };

  useEffect(() => {
    if (typeof isClickedProp === "undefined") return;
    setIsClicked(isClickedProp);
  }, [isClickedProp]);

  return (
    <div
      className={`select-choice ${property1} ${className} ${
        isClicked ? "clicked" : ""
      }`}
      onClick={handleClick}
    >
      <div className="sample-text">{text}</div>
    </div>
  );
};

SelectChoice.propTypes = {
  property1: PropTypes.oneOf(["hover", "selected", "unselected"]),
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired, // Aggiungiamo la prop onClick che è una funzione obbligatoria
};

export default SelectChoice;
