import React from "react";
import { StyledLocationBox } from "./StyledLocationBox";
import { IWorker } from "../../../../../../types/filterFields";

const LocationBox = ({ worker }: { worker: IWorker }) => {
  return (
    <StyledLocationBox>
      <div className="main-wrapper">
        <div className="row-wrapper">
          <h3>🏘️ Provincia</h3>
          <p>{worker.provincia}</p>
        </div>
        {worker.comune ? (
          <div className="row-wrapper">
            <h3>📍 Comune</h3>
            <p>{worker.comune ?? "--"}</p>
          </div>
        ) : null}
        {worker.codice_postale ? (
          <div className="row-wrapper">
            <h3>📫 Codice Postale</h3>
            <p>{worker.codice_postale ?? "--"}</p>
          </div>
        ) : null}
      </div>
      {worker.edit_date || worker.creation_time ? (
        <p className="edit-date-label">
          Aggiornato il{" "}
          {worker.edit_date?.split("T")[0] ??
            worker.creation_time?.split("T")[0] ??
            null}
        </p>
      ) : null}
    </StyledLocationBox>
  );
};

export default LocationBox;
