import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import { stepEightDrivingLicence } from "../stepsVariables";
import { isAcceptableStepOnlyOne } from "../functionsSteps";
import { isStepValid } from "../../../utils/onboarding";

const DrivingLicense = ({
  onNext,
  formData,
  onPrevious,
  onInputChange,
  title,
  subtitle,
}) => {
  const { drivingLicense } = formData;
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    //console.log(acceptableConditions);
    if (drivingLicense.length) {
      onNext();
      //console.log(formData)
    } else {
      setErrorMessage("Inserisci una risposta in questa domanda");
    }
  };

  const handleSelectChoiceClick = (isClicked, text) => {
    //console.log("isClicked:", isClicked);
    //console.log("text:", text);

    // Copia l'array drivingLicense in updatedDrivingLicense
    // const updatedDrivingLicense = [...drivingLicense];

    // if (isClicked) {
    //   // Aggiungiamo l'elemento text alla lista se isClicked è true e l'elemento non è già presente
    //   if (!updatedDrivingLicense.includes(text)) {
    //     updatedDrivingLicense.push(text);
    //   }
    // } else {
    //   // Rimuoviamo l'elemento text dalla lista se isClicked è false e l'elemento è presente
    //   const index = updatedDrivingLicense.indexOf(text);
    //   if (index !== -1) {
    //     updatedDrivingLicense.splice(index, 1);
    //   }
    // }

    // Aggiorna drivingLicense con l'array modificato
    onInputChange("drivingLicense", text);
  };

  const handleClickPreviousButton = () => {
    // onInputChange("acceptableConditions", []);
    // onInputChange("carAvailability", []);

    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>

          <h2 className="subtitle">{subtitle}</h2>

          <div className="div">
            <div className="selection-element-horrizonatal">
              {stepEightDrivingLicence.map((item) => (
                <SelectChoice
                  key={item}
                  text={item}
                  onClick={handleSelectChoiceClick}
                  isClickedProp={drivingLicense.includes(item)}
                />
              ))}
            </div>
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={
                  ("button-instance",
                  !isStepValid({
                    mandatoryFields: "drivingLicense",
                    formData: {
                      drivingLicense: formData.drivingLicense,
                    },
                  })
                    ? "button-disabled"
                    : "")
                }
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrivingLicense;
