import {
  Auth,
  MultiFactorError,
  MultiFactorSession,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  UserCredential,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
} from "firebase/auth";

import { app } from "../../lib/firebase/config";

export const startSignUpWith2FA = async ({
  phoneNumber,
  buttonId,
}: {
  phoneNumber: string;
  buttonId: string;
}): Promise<any> => {
  try {
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (!user) throw new Error("User not found");

    const multifactorUser = multiFactor(user);
    const multifactorUserSession = await multifactorUser.getSession();

    let verificationId;

    const recaptchaVerifier = new RecaptchaVerifier(
      buttonId,
      {
        size: "invisible",
        callback: async function (response: Response) {
          // console.log(response);
        },
      },
      auth
    );

    //@ts-ignore
    window.recaptchaVerifier = recaptchaVerifier;

    //@ts-ignore
    if (!window.recaptchaVerifier) {
      recaptchaVerifier.render();
    }

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multifactorUserSession,
    };

    // Send SMS verification code.
    verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );

    return { auth, user, multifactorUser, verificationId };
  } catch (err) {
    console.log(err);
    return { auth: null, user: null };
  }
};

export const endSignUpWith2FA = async ({
  auth,
  user,
  multifactorUser,
  verificationId,
  verificationCode,
}: {
  auth: Auth;
  user: any;
  multifactorUser: any;
  verificationId: string;
  verificationCode: string;
}): Promise<any> => {
  try {
    const phoneAuthCredential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );

    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(phoneAuthCredential);

    await multifactorUser.enroll(
      multiFactorAssertion,
      "Il tuo numero di telefono"
    );

    return { auth, user };
  } catch (err) {
    console.log(err);
    return { auth: null, user: null };
  }
};

export const startSignInWith2FA = async ({
  error,
  buttonId,
}: {
  error: MultiFactorError;
  buttonId: string;
}): Promise<{ error: any; resolver: any; verificationId: any }> => {
  try {
    const auth = getAuth(app);
    const resolver = getMultiFactorResolver(auth, error);

    const recaptchaVerifier = new RecaptchaVerifier(
      buttonId,
      {
        size: "invisible",
        callback: async function (response: Response) {
          // console.log(response);
        },
      },
      auth
    );

    //@ts-ignore
    window.recaptchaVerifier = recaptchaVerifier;

    //@ts-ignore
    if (!window.recaptchaVerifier) {
      recaptchaVerifier.render();
    }

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);

    // Send SMS verification code
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );

    return { error, resolver, verificationId };
  } catch (err) {
    return err as any;
  }
};

export const endSignInWith2FA = async ({
  error,
  verificationId,
  verificationCode,
}: {
  error: MultiFactorError;
  verificationId: string;
  verificationCode: string;
}): Promise<UserCredential | null> => {
  try {
    const auth = getAuth(app);
    const resolver = getMultiFactorResolver(auth, error);

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    // Complete sign-in.
    return resolver.resolveSignIn(multiFactorAssertion);
  } catch (err) {
    return err as any;
  }
};
