import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../../../../constants/vetrina/Dimens";
import { MAIN_COLORS } from "../../../../../../constants/vetrina/Colors";

export const StyledWorkerDescription = styled.div`
  .description {
    margin-top: 1.5rem;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 1.5rem;

    /* @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 0.75rem;
      line-height: 1.125rem;
    } */

    .read-more-btn {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      font-family: inherit;
      color: ${MAIN_COLORS.blue600};
      font-weight: 600;
      line-height: 1.5rem;
      cursor: pointer;
      margin-left: 0.3rem;

      /* @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        font-size: 0.75rem;
        line-height: 1.125rem;
      } */
    }
  }
`;
