import React, { useEffect, useState } from "react";
import { StyledFilters } from "./StyledFilters";
import SearchBar from "../../../../../components/atoms/SearchBar";
import filtersIcon from "../../../../../assets/filters.png";
import closeIcon from "../../../../../assets/x-exit.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import ModalFilters from "./components/ModalFilters";
import {
  addQueryFilter,
  addTextSearch,
  filterData,
  removeQueryFilter,
  resetFilters,
  resetQueryFilters,
  setFilter,
  setModalOpened,
} from "../../../../../store/slices/databaseTable";
import {
  IField,
  ITypeChoiceFilterFields,
} from "../../../../../types/filterFields";
import Spacer from "../../../../../components/Spacer";
import {
  searchFamilies,
  searchWorkers,
} from "../../../../../store/thunks/databaseTable";
import { useLocation } from "react-router-dom";
import { findNestedChoices } from "../../../../../utils/utils";
import Dropdown from "../../../../../components/atoms/Dropdown/Dropdown";

const Filters = () => {
  const availableFilters = useSelector(
    (state: RootState) => state.databaseTable.availableFilters
  );

  const selectedFilters = useSelector(
    (state: RootState) => state.databaseTable.selectedFilters
  );
  const modalOpened = useSelector(
    (state: RootState) => state.databaseTable.modalOpened
  );
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const dispatchRedux = useDispatch<any>();

  const location = useLocation();

  const handleSearchBar = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatchRedux(addTextSearch(e.target.value));
  };

  const handleSearch = (_: React.MouseEvent<HTMLButtonElement>) => {
    if (location.pathname.includes("database-datori-lavoro")) {
      dispatchRedux(searchFamilies());
    } else if (location.pathname.includes("database-lavoratori")) {
      dispatchRedux(searchWorkers());
    }
  };

  if (
    Object.keys(availableFilters).every((key) => {
      if (
        !(availableFilters[key as keyof typeof availableFilters] as IField)
          .choices
      )
        return true;
      const filter = availableFilters[
        key as keyof typeof availableFilters
      ] as IField;
      return filter.choices.length === 0;
    })
  ) {
    return (
      <StyledFilters>
        <div className="main-section">
          <div className="search-text-wrapper">
            <SearchBar
              onCancel={() => dispatchRedux(resetQueryFilters())}
              onChange={handleSearchBar}
              className="search-bar"
            />
          </div>
          <Dropdown
            options={["true", "false"]}
            onClick={(placement) =>
              dispatchRedux(addQueryFilter({ placement_user: placement }))
            }
            placeholder="Placement User"
          />
          <button onClick={handleSearch} className="search-btn">
            Search
          </button>
        </div>
      </StyledFilters>
    );
  }

  const handleFilters = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: IField
  ) => {
    const value = e.target.value;
    if (value === "Select") {
      dispatchRedux(removeQueryFilter({ [field.key]: value }));
    } else {
      dispatchRedux(addQueryFilter({ [field.key]: value }));
    }
  };

  return (
    <StyledFilters>
      {modalOpened ? <ModalFilters /> : null}
      <div className="main-section">
        <div className="search-text-wrapper">
          <SearchBar
            onCancel={() => dispatchRedux(resetQueryFilters())}
            onChange={handleSearchBar}
            className="search-bar"
          />
        </div>
        <div className="filters-switch-wrapper">
          <button
            className="filters-switch"
            onClick={() => {
              setFiltersOpen((p) => {
                if (p) {
                  dispatchRedux(resetQueryFilters());
                }
                return !p;
              });
            }}
          >
            <img src={filtersIcon} alt="" /> Filters
            {filtersOpen ? (
              <img className="close-filters-img" src={closeIcon} alt="" />
            ) : null}
          </button>
        </div>
        <button onClick={handleSearch} className="search-btn">
          Search
        </button>
      </div>
      <div
        className={`filters-section ${
          filtersOpen ? "filters-section__opened" : "filters-section__closed"
        }`}
      >
        {Object.keys(availableFilters).length > 0
          ? Object.keys(selectedFilters).map((filter, i) => {
              const field = (availableFilters as ITypeChoiceFilterFields)[
                filter as keyof ITypeChoiceFilterFields
              ];

              const choices = field.choices;
              const label = field.label;

              if (choices && choices.length > 0) {
                return (
                  <div key={i}>
                    <p className="label">{label}</p>
                    <select
                      multiple={false}
                      onChange={(e) => handleFilters(e, field)}
                    >
                      <option>Select</option>
                      {choices.map((c, i) => (
                        <option value={c} key={i}>
                          {c}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              }
              return <></>;
            })
          : null}
        {Object.keys(selectedFilters).length <
        Object.keys(availableFilters).length ? (
          <div className="add-filter-btn">
            <button onClick={() => dispatchRedux(setModalOpened(true))}>
              Add filter
            </button>
          </div>
        ) : null}
      </div>
    </StyledFilters>
  );
};

export default Filters;
