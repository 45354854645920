import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import Checkbox from "../../../components/Checkbox";
import Logo from "../../../components/Logo/Logo";
import { doc, setDoc, getDoc } from "firebase/firestore";
import db from "../../../index";
import { transformDictToDB } from "../functionsSteps.js";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../store/slices/databaseTable";

const FinalStep = ({ onNext, formData, title, onInputChange, onPrevious }) => {
  const { codiceFiscale } = formData;
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [isChecked, setIsChecked] = useState("unchecked");

  const navigate = useNavigate();

  const functions = getFunctions();
  const regWorker = httpsCallable(functions, "signupworker");

  const dr = useDispatch();

  const handleClickNextButton = async () => {
    try {
      dr(setIsLoading(true));
      if (isChecked === "checked") {
        // Get the worker if exists to get old data and save it in the new worker profile
        const docRef = doc(db, "external_users", codiceFiscale);
        const worker = await getDoc(docRef);
        let workerPersistentData = {};
        if (worker.data()) {
          workerPersistentData.note = worker.data()["note"]
          workerPersistentData.referenze = worker.data()["referenze"]
          workerPersistentData.documenti_in_regola = worker.data()["documenti_in_regola"]
        }

        await setDoc(
          doc(db, "external_users", codiceFiscale),
          transformDictToDB(formData, workerPersistentData),
          { merge: true }
        );
        const res = await regWorker({
          email: formData.email,
          cellulare: formData.telephoneNumber.split(" ").join(""),
          name: formData.name,
          surname: formData.surname,
          citta: formData.city,
          tipolavoro: formData.workOptionsList.join(", "),
          formData: formData,
        });

        if (!res.data.success && res.data.message) {
          // setErrorMessage(res.data.message);
        } else {
          setIsConfirmed(true);
        }
        dr(setIsLoading(false));
        navigate("/success", { replace: true });
      } else {
        setErrorMessage("Dai il consenso all'informativa");
      }
      dr(setIsLoading(false));
    } catch (error) {
      console.error("Errore durante l'esecuzione di setDoc:", error);
      const now = new Date();

      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      const formattedKey = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

      const docRef = doc(db, "error_logs", `${formattedKey}-${codiceFiscale}`);

      await setDoc(docRef, {
        error: error.message,
        errorStacl: error.stack,
        data: formData,
      });

      dr(setIsLoading(false));
    }
  };

  /*
    const handleInputChange = (fieldName, value) => {
      onInputChange(fieldName, value);
    };*/

  const handleCheckboxChange = (isChecked) => {
    setIsChecked(isChecked ? "checked" : "unchecked");
  };

  const handleClickPreviousButton = () => {
    //TODO: gestione elimina vecchia selezione
    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">
              {isConfirmed ? (
                <>Grazie per la registrazione</>
              ) : (
                <>Ultimo step</>
              )}
            </h1>
          </div>
          <h2 className="subtitle">
            {" "}
            {isConfirmed ? <>Ti conttatteremo al più presto</> : <></>}{" "}
          </h2>
          <div className="div">
            {isConfirmed ? (
              <></>
            ) : (
              <Checkbox
                isChecked={isChecked}
                onChange={handleCheckboxChange}
              ></Checkbox>
            )}
            {isConfirmed ? (
              <></>
            ) : (
              <ErrorMessageInForm message={errorMessage} />
            )}
            {isConfirmed ? (
              <></>
            ) : (
              <div className="buttons">
                <Button
                  className="button-instance"
                  property1="primary"
                  text="Conferma"
                  onClick={handleClickNextButton}
                />
                <Button
                  className="button-instance"
                  property1="secondary"
                  text="Indietro"
                  onClick={handleClickPreviousButton}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalStep;
