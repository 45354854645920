import { createSlice, current } from "@reduxjs/toolkit";
import { IFamily, IWorker } from "../../types/filterFields";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getWorkers } from "../thunks/vetrina";
import {
  INITIAL_WORKERS_COUNT,
  WORKERS_PER_PAGE,
} from "../../constants/vetrina/Other";
import { app } from "../../lib/firebase/config";

const firestore = getFirestore(app);

interface IWorkersState {
  workers: IWorker[];
  totalWorkersCount: number;
  detailedWorker: IWorker | null;
  isLoadingWorkers: boolean;
  paginatedWorkers: number;
  selectedWorkers: IWorker[];
  user: IFamily | null;
}

const initialState: IWorkersState = {
  paginatedWorkers: INITIAL_WORKERS_COUNT,
  workers: [] as IWorker[],
  totalWorkersCount: 0,
  detailedWorker: null,
  isLoadingWorkers: false,
  selectedWorkers: [],
  user: null,
};

const vetrinaSlice = createSlice({
  name: "vetrinaSlice",
  initialState,
  reducers: {
    setDetailedWorker(state, action) {
      state.detailedWorker = action.payload;
    },
    setDetailedWorkerToNext(state) {
      if (!state.detailedWorker) return;
      const index = state.workers.findIndex(
        (worker) =>
          worker.codice_fiscale === state.detailedWorker?.codice_fiscale
      );
      if (index === -1) return;
      if (index === state.workers.length - 1) return;
      state.detailedWorker = state.workers[index + 1];
    },
    setDetailedWorkerToPrev(state) {
      if (!state.detailedWorker) return;
      const index = state.workers.findIndex(
        (worker) =>
          worker.codice_fiscale === state.detailedWorker?.codice_fiscale
      );
      if (index === -1) return;
      if (index === 0) return;
      if (index > 1) {
        document
          .getElementById(
            `worker-card-${state.workers[index - 2]?.codice_fiscale}`
          )
          ?.scrollIntoView({ behavior: "smooth" });
      } else {
        document
          .querySelector(".subheading")
          ?.scrollIntoView({ behavior: "smooth" });
      }
      state.detailedWorker = state.workers[index - 1];
    },
    loadMoreWorkers(state, _) {
      state.paginatedWorkers = state.paginatedWorkers + WORKERS_PER_PAGE;
    },
    addSelectedWorker(state, action) {
      state.selectedWorkers.push(action.payload.worker);
      const docRef = doc(
        firestore,
        "external_users",
        action.payload.user?.codice_fiscale || state.user?.codice_fiscale
      );
      updateDoc(docRef, {
        ...action.payload.user,
        selection: state.selectedWorkers,
      });
    },
    setSelection(state, action) {
      state.selectedWorkers = action.payload;
    },
    removeSelectedWorker(state, action) {
      state.selectedWorkers = state.selectedWorkers.filter(
        (worker) =>
          worker.codice_fiscale !== action.payload.worker.codice_fiscale
      );
      const docRef = doc(
        firestore,
        "external_users",
        action.payload.user?.codice_fiscale || state.user?.codice_fiscale
      );
      updateDoc(docRef, {
        selection: state.selectedWorkers,
      });
    },
    clearSelectedWorkers(state) {
      state.selectedWorkers = [];
    },
    registerUserToSession(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getWorkers.fulfilled, (state, action) => {
      state.isLoadingWorkers = false;

      if (action.payload === undefined) return;
      /**
       * workaround per gestire interfaccia con diverse possibilità
       * cambiare struttura dei dati prima possibile per evitare rotture
       */
      //@ts-ignore
      state.workers = action.payload.workers;
      state.totalWorkersCount = action.payload.totalCount || 0;

      if (state.detailedWorker) {
        const index = state.workers.findIndex(
          (worker) =>
            worker.codice_fiscale === state.detailedWorker?.codice_fiscale
        );
        if (index === -1) return;
        if (index === state.workers.length - 1) return;
        state.detailedWorker = state.workers[index + 1];
        document
          .getElementById(`worker-card-${state.workers[index].codice_fiscale}`)
          ?.scrollIntoView({ behavior: "smooth" });
      }
    });
    builder.addCase(getWorkers.rejected, (state, action) => {
      state.isLoadingWorkers = false;
    });
    builder.addCase(getWorkers.pending, (state, action) => {
      state.isLoadingWorkers = true;
    });
  },
});

export const {
  setDetailedWorker,
  setDetailedWorkerToNext,
  setDetailedWorkerToPrev,
  addSelectedWorker,
  removeSelectedWorker,
  clearSelectedWorkers,
  loadMoreWorkers,
  registerUserToSession,
  setSelection,
} = vetrinaSlice.actions;
export default vetrinaSlice.reducer;
