import React, { useState } from "react";
import { StyledAboutFreeTextStep } from "./StyledAboutFreeTextStep";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import ErrorMessageInForm from "../../../../components/ErrorMessageInForm";
import Button from "../../../../components/Button";
import SelectChoice from "../../../../components/SelectChoice/SelectChoise";
import Logo from "../../../../components/Logo/Logo";
import TextField from "../../../../components/TextField/Textfield";

const AboutFreeTextStep = ({
  title,
  formData,
  onNext,
  onInputChange,
  onPrevious,
  placeholder,
}: any) => {
  const { describeSelfFreeText } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    // if (isAcceptableStepMultipleOptions(describeSelfFreeText)) {
    onNext();
    // } else {
    //   setErrorMessage("Inserisci almeno una lingua che sai parlare");
    // }
  };

  const handleClickPreviousButton = () => {
    //TODO: gestione elimina vecchia selezione

    // onInputChange("describeSelfFreeText", []);

    onPrevious();
  };

  const handleSelectChoiceClick = (isClicked: boolean, text: string) => {
    const updateddescribeSelfFreeText = [...describeSelfFreeText];
    if (isClicked) {
      if (!updateddescribeSelfFreeText.includes(text)) {
        updateddescribeSelfFreeText.push(text);
      }
    } else {
      const index = updateddescribeSelfFreeText.indexOf(text);
      if (index !== -1) {
        updateddescribeSelfFreeText.splice(index, 1);
      }
    }
    onInputChange("describeSelfFreeText", [...updateddescribeSelfFreeText]);
  };

  return (
    <StyledAboutFreeTextStep>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="onboarding-screen">
          <div className="typeforms-buttons">
            <div className="title-container">
              <h1 className="title">{title}</h1>
            </div>
            {/* <h2 className="subtitle">{subtitle} </h2> */}
            <div className="div">
              <TextField
                maxLength={500}
                className=""
                label={
                  placeholder !== undefined
                    ? placeholder
                    : "Descriviti brevemente"
                }
                value={describeSelfFreeText}
                onChange={(value) =>
                  onInputChange("describeSelfFreeText", value)
                }
              />
              <ErrorMessageInForm message={errorMessage} />
              <div className="buttons">
                <Button
                  className="button-instance"
                  property1="primary"
                  onClick={handleClickNextButton}
                >
                  Avanti
                </Button>
                <Button
                  className="button-instance"
                  property1="secondary"
                  onClick={handleClickPreviousButton}
                >
                  Indietro
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledAboutFreeTextStep>
  );
};

export default AboutFreeTextStep;
