import { createAsyncThunk } from "@reduxjs/toolkit";
import { IState } from "../slices/databaseTable";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../lib/firebase/functions";
import { RootState } from "../store";
import { IFamily, IWorker } from "../../types/filterFields";
import { updateKanbanCard } from "../slices/kanban";

export const getWorkers = createAsyncThunk(
  "databaseTable/getWorkers",
  async (
    {
      currentPage: page,
      order,
      orderField,
    }: {
      currentPage: number;
      order: "asc" | "desc";
      orderField: string;
    },
    thunkAPI
  ): Promise<{ count: number; rows: IWorker[]; totalCount?: number }> => {
    const state = thunkAPI.getState() as RootState;
    const filters = state.databaseTable.queryFilters;

    const getWorkers = httpsCallable(functions, "getworkers");

    const response = await getWorkers({
      page,
      order,
      orderField,
      filters,
    });

    return response.data as { count: number; rows: IWorker[] };
  }
);

export const getFamilies = createAsyncThunk(
  "databaseTable/getFamilies",
  async ({
    currentPage: page,
    order,
    orderField,
  }: {
    currentPage: number;
    order: string;
    orderField: string;
  }): Promise<{ count: number; rows: IFamily[] }> => {
    const getFamilies = httpsCallable(functions, "getfamilies");

    const response = await getFamilies({
      page,
      order,
      orderField,
    });

    return response.data as { count: number; rows: IFamily[] };
  }
);

export const searchWorkers = createAsyncThunk(
  "databaseTable/searchWorkers",
  async (_, thunkAPI): Promise<{ count: number; rows: IWorker[] }> => {
    const state = thunkAPI.getState() as RootState;
    const filters = state.databaseTable.queryFilters;

    const searchWorkersFunction = httpsCallable(functions, "searchworkers");

    const response = await searchWorkersFunction({
      filters,
    });

    return response.data as { count: number; rows: IWorker[] };
  }
);

export const searchFamilies = createAsyncThunk(
  "databaseTable/searchFamilies",
  async (_, thunkAPI): Promise<IFamily[]> => {
    const state = thunkAPI.getState() as RootState;
    const filters = state.databaseTable.queryFilters;
    const searchWorkersFunction = httpsCallable(functions, "searchfamilies");

    const response = await searchWorkersFunction({
      filters,
    });

    return response.data as IFamily[];
  }
);

export const editSelected = createAsyncThunk(
  "databaseTable/editSelected",
  async (type: string, thunkAPI): Promise<IWorker | IFamily> => {
    try {
      const state = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch;
      const selected = state.databaseTable.selected;
      const editSelectedFunction = httpsCallable(functions, "editselected");

      if (selected) {
        const serializedObject: any = { ...selected };
        // Object.entries(selected).forEach(([key, value]) => {
        //   if (key === "experiences") {
        //   }
        // });
        serializedObject["codice_fiscale"] = selected["codice_fiscale"];
        const res = await editSelectedFunction({
          selected: serializedObject,
          type,
        });
        dispatch(updateKanbanCard(selected));
        return res.data as IWorker | IFamily;
      }

      return {} as IWorker | IFamily;
    } catch (e) {
      console.log(e);
      return {} as IWorker | IFamily;
    }
  }
);
