import React, { useState } from "react";
import { accordionStyles } from "./styles";

const Accordion = ({ label, children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={accordionStyles.container} className="accordion-container">
      <button
        style={accordionStyles.button}
        className="accordion-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {label}
        <div style={accordionStyles.chevronWrapper}>
          <span
            className={`chevron ${isOpen ? "open" : ""}`}
            style={accordionStyles.chevron}
          ></span>
        </div>
      </button>
      <div
        style={isOpen ? accordionStyles.content : accordionStyles.contentHidden}
        className={`accordion-content ${isOpen ? "" : "hidden"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
