import styled from "styled-components";
import { MAIN_COLORS } from "../../../../../../constants/vetrina/Colors";

interface IStyledDropdownList {
  $isLast: boolean;
}

export const StyledDropdownList = styled.li<IStyledDropdownList>`
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 0.05rem solid
    ${({ $isLast }) => ($isLast ? "transparent" : MAIN_COLORS.black)};

  p {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .title {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  .description {
    font-size: 0.9rem;
  }
`;
