import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/index";
import SignUpFamilyPage from "./pages/sign-up-family/index";
import SignUpPage from "./pages/sign-up/index";
import SignInPage from "./pages/sign-in/index";
import SignInAdminPage from "./pages/sign-in-admin/index";
import OnboardingPage from "./pages/onboarding/index";
import ForgotPassword from "./pages/forgetPassword/index";
import DatabasePageFamilies from "./pages/databaseAdmin/databasePageFamilies";
import DatabasePageWorkers from "./pages/databaseAdmin/databasePageWorkers";
import PrivacyWorkerPage from "./pages/privacyWorker/index";
import PrivacyFamilyPage from "./pages/privacyFamily";
import { Provider } from "react-redux";
import store from "./store/store";
import Loader from "./components/Loader";
import Success from "./pages/onboarding/success";
import Kanban from "./pages/kanban";
import Vetrina from "./pages/vetrina";
import Selezione from "./pages/vetrina/selezione";
import PreCheckout from "./pages/vetrina/PreCheckout";
import ArrivingSoon from "./pages/vetrina/ArrivingSoon";
import Impl2FA from "./pages/r/impl2fa";
import Notify from "./pages/Notify";
import Brevo from "./pages/brevo";
import VerificationEmail from "./pages/VerificationEmail";
import { useEffect } from "react";

const FOOTER_NOT_VISIBLE_PATHS = ["/home", "/database", "/kanban", "/brevo"];

function App() {
  useEffect(() => {
    (function (w, d) {
      var wlp = w.location.pathname;
      if (
        wlp === "/home" ||
        wlp.startsWith("/database") ||
        wlp === "/kanban" ||
        wlp === "/brevo"
      ) {
        var e = d.querySelector("footer");
        if (!e) return;
        e.style.display = "none";
        return;
      }
      var loader = function () {
        var s = d.createElement("script"),
          tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
      };
      if (w.addEventListener) {
        w.addEventListener("load", loader, false);
      } else if (w.attachEvent) {
        w.attachEvent("onload", loader);
      } else {
        w.onload = loader;
      }
    })(window, document);
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<SignUpPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/brevo" element={<Brevo />} />
        <Route path="/kanban" element={<Kanban />} />
        <Route path="/privacy-lavoratore" element={<PrivacyWorkerPage />} />
        <Route path="/privacy-famiglia" element={<PrivacyFamilyPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/sign-up-family" element={<SignUpFamilyPage />} />
        <Route path="/sign-in-admin" element={<SignInAdminPage />} />
        <Route path="/onboarding-lavoratori" element={<OnboardingPage />} />
        <Route path="/password-dimenticata" element={<ForgotPassword />} />
        <Route
          path="/database-datori-lavoro"
          element={<DatabasePageFamilies />}
        />
        <Route path="/database-lavoratori" element={<DatabasePageWorkers />} />
        <Route path="/success" element={<Success />} />
        <Route path="/vetrina" element={<Vetrina />} />
        <Route path="/vetrina/selezione" element={<Selezione />} />
        <Route path="/vetrina/pre-checkout" element={<PreCheckout />} />
        <Route path="/arriving-soon" element={<ArrivingSoon />} />
        <Route path="/r/impl-2fa" element={<Impl2FA />} />
        <Route path="/notify" element={<Notify />} />
        <Route path="/verification-email" element={<VerificationEmail />} />
      </Routes>
      <Loader />
      {!FOOTER_NOT_VISIBLE_PATHS.includes(window.location.pathname) ? (
        <footer>
          <a
            href="https://www.iubenda.com/privacy-policy/68932341"
            className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
          <a
            href="https://www.iubenda.com/privacy-policy/68932341/cookie-policy"
            className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
            title="Cookie Policy "
          >
            Cookie Policy
          </a>
        </footer>
      ) : null}
    </div>
  );
}

/*
      <Route path='/sign-up' element={<SignUpPage/>} />
      <Route path='/sign-in' element={<SignInPage/>} />
*/

export default App;
