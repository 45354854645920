import styled from "styled-components";

export const StyledImpl2FA = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;

  .main-wrapper {
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    padding: 2rem;
    background-color: #fff;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog-title {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.5rem;
      width: 90%;
      margin-top: 0;
    }

    .dialog-description {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      font-size: 0.9rem;
      opacity: 0.5;
      text-align: center;
      width: 80%;
    }

    input[type="number"] {
      width: 100%;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .error-message {
      color: red;
      font-size: 0.8rem;
      margin-top: 0.5rem;
      margin-bottom: 0;
      text-align: center;
      width: 100%;
      font-weight: bold;
    }

    .main-btn {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 2rem;
    }
  }
`;
