import PropTypes from "prop-types";
import React from "react";
import "./style.css";

const Button = ({
  property1 = "primary",
  className = "",
  text = undefined,
  onClick,
  children,
  id = "",
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      id={id.length > 0 ? id : undefined}
      className={`button ${property1} ${className}`}
      onClick={handleClick}
    >
      <span className="button-primary">
        {property1 === "primary" && (
          <>{typeof text == "undefined" ? children : text}</>
        )}
        {property1 === "secondary" && (
          <>{typeof text == "undefined" ? children : text}</>
        )}
      </span>
    </button>
  );
};

Button.propTypes = {
  property1: PropTypes.oneOf(["primary", "secondary"]),
  text: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default Button;
