import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import {
  stepOneWorkOptionsList,
  stepOneWorkOptionsListInOther,
} from "../stepsVariables";
import { isAcceptableStepMultipleOptions } from "../functionsSteps";
import Logo from "../../../components/Logo/Logo";
import { useDispatch } from "react-redux";
import { setWorkType } from "../../../store/slices/onboarding";
import { isStepValid } from "../../../utils/onboarding";

const Step1 = ({
  onNext,
  onPrevious,
  formData,
  onInputChange,
  title,
  subtitle,
}) => {
  const { workOptionsList } = formData;
  const [errorMessage, setErrorMessage] = useState("");

  const dr = useDispatch();

  const handleClickNextButton = () => {
    //console.log(workOptionsList);
    if (isAcceptableStepMultipleOptions(workOptionsList)) {
      onNext();
    } else {
      setErrorMessage("Inserisci almeno un tipo di lavoro che stai cercando");
    }
  };

  const handleSelectChoiceClick = (isClicked, text) => {
    let updatedWorkOptionsList = [...workOptionsList];
    if (isClicked) {
      // Aggiungiamo l'elemento text alla lista se isClicked è true e l'elemento non è già presente
      if (!updatedWorkOptionsList.includes(text)) {
        updatedWorkOptionsList.push(text);
      }
    } else {
      // Rimuoviamo l'elemento text dalla lista se isClicked è false e l'elemento è presente
      const index = updatedWorkOptionsList.indexOf(text);
      if (index !== -1) {
        updatedWorkOptionsList.splice(index, 1);
      }
    }
    dr(setWorkType(updatedWorkOptionsList));
    onInputChange("workOptionsList", [...updatedWorkOptionsList]);
  };

  const handleClickPreviousButton = () => {
    onInputChange("documentAccordingToLaw", ""); // Pulisco i dati dello step precedente
    //onInputChange("dateCertification",""); // Pulisco i dati dello step 3
    onInputChange("workOptionsList", []); // Pulisco i dati dello step attuale

    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      {/*<img className="logo" alt="Logo" src={logo} style={isDesktop?{marginTop:"5%",marginBottom:"5%"}:{marginTop:"15%",marginBottom:"15%",width:"80%",height:"auto"}} />*/}
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>
          <h2 className="subtitle">{subtitle}</h2>
          <>
            <div className="selection-element-horrizonatal">
              {stepOneWorkOptionsList.map((item) => (
                <SelectChoice
                  isClickedProp={workOptionsList.includes(item)}
                  key={item}
                  text={item}
                  onClick={handleSelectChoiceClick}
                />
              ))}
              {!workOptionsList.includes(["Badante", "Colf", "Babysitter"]) ? (
                stepOneWorkOptionsListInOther.map((item) => (
                  <SelectChoice
                    isClickedProp={workOptionsList.includes(item)}
                    key={item}
                    text={item}
                    onClick={handleSelectChoiceClick}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={
                  ("button-instance",
                  !isStepValid({
                    mandatoryFields: "workOptionsList",
                    formData: {
                      workOptionsList: formData.workOptionsList,
                    },
                  })
                    ? "button-disabled"
                    : "")
                }
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Step1;
