import styled from "styled-components";

export const StyledModalFilters = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.2s ease-in-out;

  .modal-filters {
    width: clamp(300px, 50%, 600px);
    background-color: #fff;
    border-radius: 8px;
    padding: 32px;
    border: 1.5px solid #e0e0e0;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;

    .title-modal {
      margin-top: 0px;
      margin-bottom: 2px;
      font-weight: 800;
    }

    .subtitle-modal {
      margin-top: 0;
      margin-bottom: 24px;
    }

    .available-filters-wrapper {
      display: flex;
      flex-wrap: wrap;

      p,
      .filter {
        margin: 4px;
        color: #094692;
        background-color: #fff;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        border: 1.5px solid #094692;
        font-weight: 600;

        &__selected {
          background-color: #094692;
          color: #fff;
        }
      }
    }

    .btn-dismiss {
      margin-top: 24px;
      margin-left: auto;
      padding: 10px 20px;
      border-radius: 8px;
      font-size: 14px;
      font-family: inherit;
      font-weight: 700;
      background-color: #094692;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
