import { ProvinciaItalia } from "../provincie";

export const INITIAL_WORKERS_COUNT = 10;
export const WORKERS_PER_PAGE = 5;
export const MAX_VETRINA_SELECT = 4;
export const AVAILABLE_PROVINCIE = [
  ProvinciaItalia.ROMA,
  ProvinciaItalia.MILANO,
  ProvinciaItalia.TREVISO,
];
