import React, { useEffect } from "react";
import Menu from "../../components/Menu";
import { StyledBrevo } from "./styles";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { app } from "../../lib/firebase/config";
import {
  registerUserToSession,
  setSelection,
} from "../../store/slices/vetrina";
import { UserTypes } from "../../types/auth";

const Brevo = () => {
  //   const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const iframe = document.querySelector(
      'iframe[src="https://app.brevo.com"]'
    ) as HTMLIFrameElement;

    if (!iframe) return;

    const inputEmail =
      iframe.contentWindow?.document.querySelector("#emailInput");
  }, []);

  const navigate = useNavigate();
  const dispatchRedux = useDispatch<any>();
  useEffect(() => {
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const db = getFirestore(app);
        const docRef = collection(db, "internal_users");
        const q = query(docRef, where("email", "==", user.email));
        const querySnapshot = (await getDocs(q)) as any;
        if (
          querySnapshot.docs?.length === 0 ||
          querySnapshot.docs[0].data()?.user_type !== UserTypes.ADMIN
        ) {
          navigate(
            `/sign-in-admin?from=${encodeURIComponent(
              window.location.pathname
            )}`
          );
        } else {
          dispatchRedux(registerUserToSession(querySnapshot.docs[0].data()));
          dispatchRedux(
            setSelection(querySnapshot.docs[0].data().selection ?? [])
          );
        }
      } else {
        navigate(
          `/sign-in-admin?from=${encodeURIComponent(window.location.pathname)}`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Menu initialItemSelected="Brevo"></Menu>
      <StyledBrevo>
        <div className="brevo-main">
          <iframe src="https://app.brevo.com" title="brevo"></iframe>
        </div>
      </StyledBrevo>
    </>
  );
};

export default Brevo;
