import { IWorker } from "./filterFields";

export interface IResponseWorkers {
  workers: IWorker[];
  totalCount: number;
}

export interface IGetWorkerPhotos {
  error?: boolean;
  propic: string;
}

export enum WorkerHomeWorkType {
  BADANTE = "Badante",
  COLF = "Colf",
  BADANTE_COLF = "Badante/Colf",
  BADANTE_COLF_CONVIVENTE = "Badante/Colf convivente",
  BABYSITTER = "Babysitter",
  PULIZIE = "Pulizie",
  ASSISTENZA_DOMICILIARE = "Assistenza domiciliare",
  ASSISTENZA_DOMICILIARE_BADANTE = "Assistenza domiciliare / Badante",
}

export interface Contatta {
  url: string;
  label: string;
}

export interface IEsperienzaLavorativa {
  esperienza_come: string;
  inizio_esperienza: string;
  fine_esperienza: string;
  modalita_lavoro_esperienza: string[];
  mansioni: string[];
}

export interface ITestPsicoAttitudinale {
  question: string;
  answer: string;
}
