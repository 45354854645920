import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  MIN_WIDTH_DESKTOP,
  MIN_WIDTH_TABLET,
  MAX_WIDTH_TABLET,
  MAX_WIDTH_MOBILE,
} from "../../utils/general-varibales";

import logo from "../../assets/payoff-colori.png";

const Logo = () => {
  const isDesktop = useMediaQuery({ minWidth: MIN_WIDTH_DESKTOP });

  return (
    <img
      className="logo"
      alt="Logo"
      src={logo}
      style={
        isDesktop
          ? {
              marginTop: "5%",
              marginBottom: "5%",
              width: "auto",
              height: "40px",
            }
          : {
              marginTop: "15%",
              marginBottom: "15%",
              width: "80%",
              height: "auto",
            }
      }
    />
  );
};

export default Logo;
