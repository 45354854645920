import React from "react";
import { StyledArrivingSoon } from "./StyledArrivingSoon";
import Logo from "../../../components/Logo/Logo";

const ArrivingSoon = () => {
  return (
    <StyledArrivingSoon>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="success-wrapper">
          <h2>Grazie!</h2>
          <p>
            Al momento non siamo disponibili nella tua zona, ti contatteremo al
            più presto.
          </p>
        </div>
      </div>
    </StyledArrivingSoon>
  );
};

export default ArrivingSoon;
