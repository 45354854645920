import styled from "styled-components";

interface IStyledCard {
  $barColor: string;
  $isDragging: boolean;
}

export const StyledCard = styled.div<IStyledCard>`
  background-color: #fff;
  margin: 8px;
  border-radius: 6px;
  padding: 16px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid #e6e6e6;
  overflow: hidden;
  position: relative;
  z-index: ${({ $isDragging }) => ($isDragging ? 21 : 20)};

  .color-bar {
    position: absolute;
    height: 100%;
    width: 4px;
    left: 0;
    background-color: ${({ $barColor }) => $barColor};
    top: 0;
  }

  .elements-wrapper {
    display: grid;

    .card-item {
      display: flex;
      align-items: center;
      grid-gap: 1rem;

      p {
        margin: 0;
      }

      p:first-of-type {
        font-size: 0.7rem;
        font-weight: bold;
        color: #6e6e6e;
        min-width: 70px;
      }

      p:last-of-type {
        font-size: 0.9rem;
      }

      &__cf {
        grid-row: 1;
        margin-top: 0;
        margin-bottom: 0.7rem;
        color: #b5b5b5;
        font-weight: bold;
        display: flex;
        grid-gap: 4px;
        align-items: center;
      }

      &__ed {
        margin-bottom: 0;
        font-size: 0.7rem;
        color: #c5c5c5;
        font-weight: bold;
        display: flex;
        grid-gap: 4px;
        align-items: center;
      }

      &__ct {
        margin-bottom: 0;
        font-size: 0.7rem;
        color: #c5c5c5;
        font-weight: bold;
        display: flex;
        grid-gap: 4px;
        align-items: center;
        margin-top: 0.2rem;
      }
    }
  }
`;
