import React from "react";
import { StyledCard } from "./StyledCard";
import { useDraggable } from "@dnd-kit/core";
import { Clock, Note, User } from "@phosphor-icons/react";
import { CSS } from "@dnd-kit/utilities";
import { useDispatch } from "react-redux";
import { setIsEditorOpened } from "../../../../store/slices/databaseTable";

export interface ICard {
  card: any;
  ckey: string;
  color: string;
}

const visibleFields = [
  "nome",
  "cognome",
  "codice_fiscale",
  "email",
  "telefono",
  "provincia",
  "edit_date",
  "citta",
];

const Card = ({ card, ckey, color }: ICard) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: ckey,
    });

  const dr = useDispatch();

  return (
    <StyledCard
      onClick={() =>
        dr(
          setIsEditorOpened({
            opened: true,
            object: card,
          })
        )
      }
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      $barColor={color}
      $isDragging={isDragging}
      style={{ transform: CSS.Translate.toString(transform) }}
    >
      <div className="color-bar"></div>
      <div className="elements-wrapper">
        {card.codice_fiscale && (
          <p key="codice_fiscale" className="card-item__cf">
            <User weight="bold" />
            {card.codice_fiscale}
          </p>
        )}

        {Object.keys(card)
          .filter(
            (ck) =>
              visibleFields.includes(ck) &&
              !["codice_fiscale", "edit_date"].includes(ck)
          )
          .map((cardItemKey) => (
            <div className="card-item" key={cardItemKey}>
              <p className="card-item__label">
                {cardItemKey.replace("_", " ").toLocaleUpperCase()}
              </p>
              <p className="card-item__value">{card[cardItemKey] ?? "--"}</p>
            </div>
          ))}

        <p key="edit_date" className="card-item__ed">
          <Note />
          {card.edit_date ?? "Unedited"}
        </p>
        <p key="creation_time" className="card-item__ct">
          <Clock />
          {card.creation_time?.value?.split("T")[0] ??
            card.creation_time?.split("T")[0] ??
            "Unknown"}
        </p>
      </div>
    </StyledCard>
  );
};

export default Card;
