import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";

import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import { isAcceptableStepWith2TextForm } from "../functionsSteps";
import { TypeForm } from "../../../components/TypeForm/index";
import { isEmail, isPhoneNumber } from "../../../utils/utils";
import phoneIcon from "../../../img/static/phoneIcon.svg";
import emailIcon from "../../../img/static/mailIcon.svg";
import { IconInterfacesUser } from "../../../components/IconInterfacesUser";
import { PREFIXES } from "../../../constants/phonePrefixes/prefixes";
import { isStepValid } from "../../../utils/onboarding";

const EmailAndPhone = ({
  onNext,
  formData,
  title,
  onInputChange,
  onPrevious,
}) => {
  const { email, telephoneNumber } = formData;
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState(PREFIXES[0].prefix);

  const handleClickNextButton = async () => {
    if (isAcceptableStepWith2TextForm(email, telephoneNumber)) {
      if (isEmail(email)) {
        if (isPhoneNumber(telephoneNumber)) {
          try {
            onNext();
          } catch (error) {
            // Il codice qui di seguito sarà eseguito solo se si verifica un errore durante l'esecuzione di setDoc.
            console.error("Errore durante l'esecuzione di setDoc:", error);
            // Puoi gestire l'errore qui come desideri, ad esempio visualizzando un messaggio all'utente o eseguendo un'azione alternativa.
          }
        } else {
          setErrorMessage("Inserisci un numer di telefono valido");
        }
      } else {
        setErrorMessage("Inserisci un indirizzo email valido");
      }
    } else {
      setErrorMessage("Inserisci email e numero di telefono");
    }
  };

  const handleInputChange = (fieldName, value) => {
    onInputChange(fieldName, value);
  };

  const handleClickPreviousButton = () => {
    //TODO: gestione elimina vecchia selezione
    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title} </h1>
          </div>
          <h2 className="subtitle"> </h2>
          <div className="div">
            <TypeForm
              className="type-form-instance"
              label="Email"
              value={email}
              override={
                <IconInterfacesUser
                  className="icon-interfaces-user-instance"
                  combinedShape={emailIcon}
                />
              }
              onChange={(value) => handleInputChange("email", value.toLowerCase())}
            />
            <TypeForm
              className="type-form-instance"
              label="Numero telefono"
              type="number"
              prefix={
                <div className="wrapper-select-prefix">
                  <p>{selectedPrefix}</p>
                  <select
                    className="select-number-prefix"
                    onChange={(e) => {
                      setSelectedPrefix(e.target.value.trim());
                    }}
                  >
                    {PREFIXES.map((prefix) => (
                      <option key={prefix.prefix} value={`${prefix.prefix} `}>
                        {prefix.prefix} {prefix.country}
                      </option>
                    ))}
                  </select>
                </div>
              }
              override={
                <IconInterfacesUser
                  className="icon-interfaces-user-instance"
                  combinedShape={phoneIcon}
                />
              }
              onChange={(value) => {
                const updatedValue = `${selectedPrefix.trim()}${value.trim()}`;
                handleInputChange("telephoneNumber", updatedValue);
              }}
            />
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={
                  ("button-instance",
                  !isStepValid({
                    mandatoryFields: ["email", "telephoneNumber"],
                    formData: {
                      email: formData.email,
                      telephoneNumber: formData.telephoneNumber,
                    },
                  })
                    ? "button-disabled"
                    : "")
                }
                property1="primary"
                text="Continua"
                onClick={handleClickNextButton}
              />
              <Button
                className="button-instance"
                property1="secondary"
                text="Indietro"
                onClick={handleClickPreviousButton}
              />
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailAndPhone;
