import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../../constants/vetrina/Dimens";

export const StyledNotification = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  padding: 1rem 1.5rem;
  width: min(95vw, 35rem);
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 400;

  h3 {
    margin-right: 1rem;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 0.9rem;
    }
  }

  .btn-text {
    margin: 0;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      img {
        display: none;
      }
    }
  }
`;
