import React from "react";
import { StyledNavigationBar } from "./StyledNavigationBar";
import logo from "../../../../assets/payoff-colori.png";
import Button from "../Button";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { Lifebuoy } from "@phosphor-icons/react";

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const selectedWorkersCount = useSelector(
    (state: RootState) => state.vetrina.selectedWorkers?.length
  );

  return (
    <StyledNavigationBar>
      <img className="logo" src={logo} alt="" />
      <div className="wrapper-buttons">
        <Button
          className="btn-selection"
          onClick={() =>
            location.pathname.includes("selezione")
              ? navigate("/vetrina")
              : navigate("/vetrina/selezione")
          }
        >
          {location.pathname.includes("selezione")
            ? "Torna alla Vetrina"
            : `La mia Selezione (${selectedWorkersCount})`}
        </Button>
        <Button
          role="secondary"
          className="btn-help"
          onClick={() =>
            (window.location.href = "mailto:cercalatuacolf@assindatcolf.it")
          }
        >
          <span className="text-wrapper">Supporto Tecnico</span>
          <Lifebuoy className="icon" />
        </Button>
      </div>
    </StyledNavigationBar>
  );
};

export default NavigationBar;
