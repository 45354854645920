import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../lib/firebase/functions";
import { IResponseWorkers } from "../../types/vetrina";
import { ProvinciaItalia } from "../../constants/provincie";

interface IGetWorkers {
  lastDocId?: string;
  count: number;
  tipoLavoroDomestico: string[];
  tipoDiRapporto: string[];
  provincia: ProvinciaItalia;
}

export const getWorkers = createAsyncThunk(
  "vetrina/getWorkers",
  async ({
    lastDocId,
    count,
    tipoLavoroDomestico,
    tipoDiRapporto,
    provincia,
  }: IGetWorkers) => {
    try {
      const getWorkersData = httpsCallable(functions, "getworkersdata");
      const response = await getWorkersData({
        lastDocId,
        count,
        tipo_lavoro_domestico: tipoLavoroDomestico,
        tipo_di_rapporto: tipoDiRapporto,
        provincia: provincia,
      });
      return response.data as IResponseWorkers;
    } catch (e) {
      console.log((e as Error).message);
    }
  }
);
