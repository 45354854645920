import styled from "styled-components";
import { MAIN_COLORS } from "../../../constants/vetrina/Colors";
import { MOBILE_BREAKPOINT } from "../../../constants/vetrina/Dimens";

export const StyledSelezione = styled.main`
  .inner-vetrina {
    min-height: 100vh;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;

    .inner-content {
      max-width: 50rem;
      padding-inline: 1.5rem;

      .list-header {
        padding-block: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .heading {
          font-size: 2rem;
          line-height: 2.4rem;
          font-family: "Lato", sans-serif;
          color: ${MAIN_COLORS.black};
          margin-bottom: 0rem;

          @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
            font-size: 1.5rem;
            line-height: 1.8rem;
          }
        }

        .subheading {
          font-size: 1.5rem;
          font-family: "Inter", sans-serif;
          font-size: 1rem;
          line-height: 1.5rem;
          margin-top: 1rem;
          font-weight: 300;
          color: ${MAIN_COLORS.black};
        }
      }

      .workers-list {
        display: grid;
        grid-gap: 1rem;
        list-style: none;
        padding-left: 0;
        margin-bottom: 5rem;
      }
    }
  }

  .load-more-btn {
    width: 100%;
    justify-content: center;
    padding-block: 1rem;
    border: 1.5px solid ${MAIN_COLORS.black};
    color: ${MAIN_COLORS.black};
  }

  .help-block {
    background-color: ${MAIN_COLORS.blue600};
    padding-block: 2.5rem;
    margin-top: 7.5rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      padding-inline: 1.5rem;
    }

    &__inner {
      max-width: 50rem;
      padding: 1.5rem;

      .help-pic {
        height: 4.5rem;
        width: 4.5rem;
        margin-bottom: 1rem;
      }

      .help-title {
        color: ${MAIN_COLORS.white};
        font-size: 1.5rem;
        font-family: "Lato", sans-serif;
        font-weight: 800;
        line-height: 1.8rem;
        margin-bottom: 1rem;
      }

      .desc {
        color: ${MAIN_COLORS.white};
        font-family: "inter", sans-serif;
        font-weight: 300;
      }

      .call-btn {
        width: 100%;
        margin-top: 2.5rem;
        justify-content: center;
        font-size: 1.125rem;
        padding: 1rem;

        img {
          margin-left: 1rem;
        }
      }
    }
  }
`;
