import styled from "styled-components";
import { MAIN_COLORS } from "../../../../../../constants/vetrina/Colors";
import { MOBILE_BREAKPOINT } from "../../../../../../constants/vetrina/Dimens";
import { transitionCubic1 } from "../../../../../../constants/vetrina/Anims";

export const StyledDesktopWorkerDetail = styled.div`
  background-color: ${MAIN_COLORS.white};
  width: max(40%, 35rem);
  position: absolute;
  right: 0;
  height: 100vh;
  border-left: 1px solid ${MAIN_COLORS.gray100};
  padding-inline: 1rem;
  overflow-y: scroll;
  animation: translateLeft 0.3s;
  overflow-y: scroll;
  padding-bottom: 12rem;

  .field {
    margin: 1rem;
  }

  .sidebar-header {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      ${transitionCubic1}

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .secondary-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    color: ${MAIN_COLORS.black};
    cursor: pointer;

    img {
      margin-left: 0.625rem;
      height: 0.7rem;
      width: 0.7rem;
    }
  }

  .details {
    margin-top: 1.5rem;

    p {
      font-family: "Inter", sans-serif;
      font-size: 1rem;
    }
  }

  .dropdown {
    list-style: none;
    height: auto;
    max-height: 0rem;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    ${transitionCubic1}
  }

  .opened {
    max-height: 1000rem;
    transform: scaleY(1);
    opacity: 1;
  }

  .skills {
    margin-block: 1.5rem;

    ul {
      list-style: none;
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.33rem;

      li {
        p {
          font-family: "Inter", sans-serif;
          font-weight: 400;
        }
      }
    }

    .btn-more-skills {
      margin-top: 1rem;
      border: none;
      background-color: transparent;
      text-decoration: underline;
      font-family: inherit;
      color: ${MAIN_COLORS.blue600};
      font-weight: 600;
      line-height: 1.5rem;
      cursor: pointer;
      margin-left: 0.3rem;

      @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }

  @keyframes translateLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
`;
