import React from "react";
import { StyledNotification } from "./StyledNotification";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { MAX_VETRINA_SELECT } from "../../../../constants/vetrina/Other";
import arrow from "../../../../assets/right-arrow.svg";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const selectedWorkers = useSelector(
    (state: RootState) => state.vetrina.selectedWorkers
  );

  const navigate = useNavigate();

  return (
    <StyledNotification>
      <h3>
        {MAX_VETRINA_SELECT === selectedWorkers?.length
          ? "Procedi o cambia la selezione"
          : `Seleziona ancora ${
              MAX_VETRINA_SELECT - selectedWorkers?.length
            } lavoratori`}
      </h3>
      <Button
        disabled={selectedWorkers?.length < MAX_VETRINA_SELECT}
        children={
          <p className="btn-text">
            Continua <img src={arrow} alt="" />
          </p>
        }
        onClick={function (): void {
          navigate("/vetrina/pre-checkout");
        }}
      />
    </StyledNotification>
  );
};

export default Notification;
