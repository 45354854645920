import React from "react";
import { StyledLoader } from "./StyledLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Loader = () => {
  const isLoading = useSelector(
    (state: RootState) => state.databaseTable.isLoading
  );
  const isLoadingKanban = useSelector(
    (state: RootState) => state.kanban.isLoading
  );
  const isLoadingVetrina = useSelector(
    (state: RootState) => state.vetrina.isLoadingWorkers
  );
  const isLoadingMain = useSelector((state: RootState) => state.main.isLoading);

  if (!isLoading && !isLoadingKanban && !isLoadingVetrina && !isLoadingMain)
    return <></>;

  return (
    <StyledLoader>
      <div className="load-2">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </StyledLoader>
  );
};

export default Loader;
