import { IWorker } from "../../../../../types/filterFields";

export const workerDetails = (worker: IWorker): string[] => {
  const details: string[] = [];
  const { referenze, conoscenzaDellItaliano } = worker;

  /**
   *  prendo solo le keys che includono "testAttitudinale" e le metto
   *  nell'oggetto testAttitudinali
   */
  const testAttitudinali = {};
  for (const key in worker) {
    if (
      Object.prototype.hasOwnProperty.call(worker, key) &&
      key.includes("testAttitudinale")
    ) {
      //@ts-ignore
      testAttitudinali[key] = worker[key as keyof IWorker];
    }
  }

  // if (referenze?.length > 0) {
  //   details.push(`📞 ${referenze.length} referenze verificabili`);
  // }

  // if (referenze) {
  //   details.push(`📞 ${referenze}`);
  // }

  if (conoscenzaDellItaliano > 3) {
    details.push("🇮🇹 Parla molto bene Italiano");
  }

  /**
   *  se ci sono piu test attitudinali, aggiungo il numero di test attitudinali
   *  altrimenti se ce ne è solo uno, aggiungo quello
   */
  if (Object.keys(testAttitudinali).length > 0) {
    details.push("🔮 Test psico-attitudinale disponibile");
  }

  return details;
};
