import React, { useEffect, useState } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { useDispatch, useSelector } from "react-redux";

import {
  innerDivStyle,
  outerContainerStyle,
  outerDivStyle,
  scrollbarContainerStyle,
  scrollbarStyle,
  styles,
  tableContainerStyle,
  thumbStyle,
} from "./style";
import SelectColumnFilter from "./filter/select-column-filter/SelectColumnFilter";
import DefaultColumnFilter from "./filter/default-column-filter/DefaultColumnFilter";
import fuzzyTextFilterFn from "./filter/serach-bar-filter/FuzzyTextFilterFn";
import { setIsEditorOpened } from "../../store/slices/databaseTable";

import { Resizable, ResizableBox } from "react-resizable";

export default function Table({
  columns,
  order,
  setOrder,
  setOrderField,
  orderField,
}) {
  const data = useSelector((state) => state.databaseTable.filteredData);
  const [filterInput, setFilterInput] = useState("");
  const dr = useDispatch();

  const [isResizing, setIsResizing] = useState(false);

  const [mousePos, setMousePos] = useState({ xa: 0, yb: 0, ya: 0, xb: 0 });

  const [isDragging, setIsDragging] = useState(false);

  const [cursorPosition, setCursorPosition] = useState(null);

  const handleMouseDown = (e) => {
    if (isResizing) return;
    setIsDragging(true);
    setCursorPosition(e.clientX);
    setMousePos((p) => ({ ...p, xa: e.clientX, ya: e.clientY }));
  };

  const handleMouseMove = (e) => {
    if (isResizing) return;
    if (isDragging) {
      const tableContainer = document.querySelector(".table-container");
      tableContainer.scrollLeft =
        tableContainer.scrollLeft + (cursorPosition - e.clientX);
      setCursorPosition(e.clientX);
    }
  };

  const handleMouseUp = (e) => {
    setIsDragging(false);
    setCursorPosition(null);
    setMousePos((p) => ({ ...p, xb: e.clientX, yb: e.clientY }));
  };

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("show.name", value);
    setFilterInput(value);
  };

  const filterTypes = {
    fuzzyText: fuzzyTextFilterFn,
    includes: (rows, id, filterValue) =>
      rows.filter((row) => row.values[id] === filterValue),
    //... altre definizioni di filtro se necessario
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: filterTypes,
      manualSortBy: true,
    },
    useFilters, //Use the useFilters hook
    useSortBy // Use the useSortBy hook
  );

  useEffect(() => {
    if (!sortBy.length) return;
    if (
      JSON.stringify(sortBy[0].desc) !== JSON.stringify(order) ||
      JSON.stringify(sortBy[0].id) !== JSON.stringify(orderField)
    ) {
      setOrder(sortBy[0].desc);
      setOrderField(sortBy[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, order]);

  const handleOpenEditor = (object) => {
    if (typeof object === "undefined") return;
    dr(setIsEditorOpened({ opened: true, object: object.original }));
  };

  return (
    <>
      <style>{scrollbarStyle}</style>
      <div style={outerContainerStyle}>
        {/* <div
          style={scrollbarContainerStyle}
          onScroll={(e) => {
            document.querySelector(".table-container").scrollLeft =
              e.target.scrollLeft;
          }}
        >
          <div style={{ width: "max-content", minWidth: "4000px" }}></div>
        </div> */}
        <div
          className="table-container"
          style={{ ...tableContainerStyle, ...styles.stickyTable }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <table {...getTableProps()} style={styles.table}>
            <thead style={styles.stickyTableHeader}>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  style={styles.header}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={styles.cell}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      {/* <div>{column.canFilter && column.filter ? column.render('Filter') : null}</div> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          onClick={() => {
                            if (mousePos.xa !== mousePos.xb) return;
                            if (mousePos.yb !== mousePos.ya) return;
                            handleOpenEditor(row);
                          }}
                          style={styles.cell}
                          className={`row-db ${
                            cell.column.seac ? "cella-seac" : ""
                          }`}
                        >
                          <ResizableBox
                            onResizeStart={(e) => {
                              setIsResizing(true);
                              setIsDragging(false);
                            }}
                            onResizeStop={(e) => {
                              setIsResizing(false);
                            }}
                            width={200}
                            height={40}
                            minConstraints={[100, 40]}
                            maxConstraints={[1000, 500]}
                          >
                            {cell.render("Cell")}
                          </ResizableBox>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
