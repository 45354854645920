import styled from "styled-components";
import { MAIN_COLORS } from "../../../../../../constants/vetrina/Colors";
import { MOBILE_BREAKPOINT } from "../../../../../../constants/vetrina/Dimens";

export const StyledLocationBox = styled.div`
  .main-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    border: 2px solid #e5e5e5;
    padding: 1rem 1.5rem;
    width: fit-content;
    border-radius: 0.5rem;
    height: fit-content;
    margin: 1rem;
    margin-bottom: 0;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-inline: 0;
      width: 100%;
    }

    .row-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        margin-right: 1rem;
        margin-block: 0;
        font-size: 0.9rem;
        white-space: nowrap;
      }

      p {
        margin-block: 0;
        font-size: 0.9rem;
        white-space: nowrap;
      }
    }
  }

  .edit-date-label {
    font-size: 0.8rem;
    margin-block: 0;
    margin-top: 0.5rem;
    color: ${MAIN_COLORS.gray900};
    opacity: 0.6;
    font-weight: bold;
    width: fit-content;
    margin-left: 1rem;
  }
`;
